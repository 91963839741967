import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createPaymentIntent,
  getCoachById,
  getCustomPricingPackage,
} from "../../api/api";
import { calculateSessionRateWithAdditionalAthletes } from "../../utils/shared";
import { useAuth } from "../../screens/App/App";
import BookASession from "../BookASession/BookASession";

function useQuery(search) {
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const BookSessionPage = (props) => {
  const { location } = props;
  const query = useQuery(location.search);
  const { user } = useAuth();
  const { id } = useParams();
  const [coach, setCoach] = useState(null);
  const [numSessions, setNumSessions] = useState(5);
  const [numAthletes, setNumAthletes] = useState(1);
  const [paymentID, setPaymentID] = useState("");
  const [customPricingPackage, setCustomPricingPackage] = useState(undefined);
  const [sessionRateWithAdditionalAthletes, setSessionRateWithAdditionalAthletes] = useState(null);

  useEffect(() => {
    getCoachById(id)
      .then((response) => {
        setCoach(response);

        const customPricingPackage = query.get("package");
        if (customPricingPackage) {
          getCustomPricingPackage(response.id, customPricingPackage)
            .then((response) => {
              setCustomPricingPackage(response);
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, [id, query]);

  useEffect(() => {
    if (props.location.state) {
      const state = props.location.state;

      if (state.numSessions) {
        setNumSessions(state.numSessions);
      }

      if (state.numAthletes) {
        setNumAthletes(state.numAthletes);
      }
    }
  }, [props.location.state]);

  useEffect(() => {
    if (!coach) {
      return;
    }

    const newSessionRateWithAdditionalAthletes =
      calculateSessionRateWithAdditionalAthletes({
        additionalAthletesRateString: coach.additionalAthletesRate,
        sessionRateString: coach.sessionRate,
        numAthletes,
      });

    setSessionRateWithAdditionalAthletes(newSessionRateWithAdditionalAthletes);  // Updated state
  }, [coach, numAthletes]);

  useEffect(() => {
    if (
      !coach ||
      !user ||
      !user.firebaseUser ||
      (query.get("package") && customPricingPackage === undefined)
    ) {
      return;
    }

    const data = {
      athlete: user.firebaseUser.uid,
      coach: coach.firebaseID,
      total:
        customPricingPackage?.price ??
        numSessions * sessionRateWithAdditionalAthletes,
    };

    user.firebaseUser.getIdToken().then(function (idToken) {
      createPaymentIntent(idToken, data)
        .then((response) => {
          setPaymentID(response["paymentID"]);
        })
        .catch((e) => console.log(e));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coach, customPricingPackage, sessionRateWithAdditionalAthletes]);

  //TODO: coach not found treatment
  return (
    <div>
      {coach ? (
        <BookASession
          coach={coach}
          numAthletes={numAthletes}
          numSessions={numSessions}
          paymentID={paymentID}
          customPricingPackage={customPricingPackage}
          sessionRateWithAdditionalAthletes={sessionRateWithAdditionalAthletes}
        />
      ) : null}
    </div>
  );
};

export default BookSessionPage;
