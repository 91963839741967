import React, {useState} from 'react';
import './SearchForCoaches.scss';
import '../ReadyToTrainForm/ReadyToTrainForm.css';
import ReadyToTrainForm from '../ReadyToTrainForm/ReadyToTrainForm';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AULogo from '../HomeNav/AULogoSmall.png';
import Button from "@material-ui/core/Button";


const SearchForCoaches = props => {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <div className="findCoachButton">
      <Button className="buttonPrimary" onClick={e => setShowDialog(true)}>Search for Coaches</Button>
      <Dialog onClose={() => setShowDialog(false)} open={showDialog} maxWidth="lg">
        <DialogContent dividers>
          <div className="CoachAccountImageContainer">
            <img className="searchForCoachesLogo" src={AULogo} alt="Athletes Untapped"></img>
          </div>
          <ReadyToTrainForm buttonText={"Search"} readyToTrain={"Find Your Private Coach"} history/>
        </DialogContent>
      </Dialog>
    </div>

  );
};

export default SearchForCoaches;