import React, { useEffect, useState } from "react";
import './Landing.scss';
import BottomNav from '../BottomNav/BottomNav';
import { ArrowDownward, FormatQuote } from "@material-ui/icons";
import Carousel from "react-material-ui-carousel";
import { CLOUDFRONT_UPLOADS } from "../../constants/hosts";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import MetaTags from 'react-meta-tags';
import { useHistory } from "react-router";
// import SportsNav from "../SportsNav/SportsNav";


const Landing = (props) => {
    const history = useHistory();
    const isIos = window.navigator.userAgent.toLowerCase().includes('ipad') || window.navigator.userAgent.toLowerCase().includes('iphone');
    const [showFounderInfo, setShowFounderInfo] = useState(false);

    useEffect(() => {
        if (props.location.state && props.location.state.showHelp === true) {
            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 10);
        }
    }, [props.location.state]);


    return (
        <div>
            <MetaTags>
                <title>Private Sports Coaching Services | Local Athletic Coaching | Athletes Untapped</title>
                <meta name="description"
                    content="Athletes Untapped offers private sports coaching services. Find a coach to help guide you or your child in their chosen sport. We provide private coaching services for basketball, football, baseball, & more! Call now!" />
                <meta property="og:description"
                    content="Athletes Untapped offers private sports coaching services. Find a coach to help guide you or your child in their chosen sport. We provide private coaching services for basketball, football, baseball, & more! Call now!" />
                <meta property="og:title" content="Find a Local Private Sports Coach for 1on1 Training" />
                <meta property="og:image" content="https://d3kvhmcyaeni3o.cloudfront.net/static/143_Athletes.jpg" />
            </MetaTags>
            <div className="landingContainer">
                <div className="topBox">
                    <h1>Train 1on1 with a Vetted Private Sports Coach</h1>
                    <div className="buttonContainer">
                        <Button className="button left buttonPrimary" onClick={() => history.push('/coaches')}>Find a
                            Coach</Button>
                        <div className="separator"></div>
                        <Button className="button right buttonPrimary" onClick={() => history.push('/apply-to-coach')}>Apply to
                            coach</Button>
                    </div>
                </div>
                <div className="asSeenContainer">
                    <div className="innerContainer">
                        <h2>Trusted &amp; Covered By</h2>
                        <img alt="journal" src={CLOUDFRONT_UPLOADS + "static/home-athlete-ent.jpg"} />
                        <img alt="journal" src={CLOUDFRONT_UPLOADS + "static/home-bus-journal.jpg"} />
                        <img alt="journal" src={CLOUDFRONT_UPLOADS + "static/home-daily-local.png"} />
                        <img alt="journal" src={CLOUDFRONT_UPLOADS + "static/home-philly-inq.png"} />
                        <img alt="journal" src={CLOUDFRONT_UPLOADS + "static/home-playeasy.png"} />
                        <img alt="journal" src={CLOUDFRONT_UPLOADS + "static/home-SBJ.png"} />
                        <img alt="journal" src={CLOUDFRONT_UPLOADS + "static/home-venturefizz.png"} />
                        <img alt="journal" src={CLOUDFRONT_UPLOADS + "static/home-sidehusl.png"} />
                        <img alt="journal" src={CLOUDFRONT_UPLOADS + "static/home-ncsa.png"} />
                        <img alt="journal" src={CLOUDFRONT_UPLOADS + "static/home-technicsl.ly.jpg"} />
                    </div>
                </div>
                {/* <h2>3 easy steps to achieve your athletic goals</h2>
                <div className="stepsBox" style={{display: "flex", maxWidth: "98%"}}>
                <div className="stepouter">
                    <div className="step">
                        <div className="numberTitle">
                            <div className="number">1</div>
                            Identify the right coach for you
                        </div>
                        Browse our list of private sports coaches and decide which coach will help you achieve your
                        goals. Message the local coach through our platform to determine when and where to begin private
                        sports training.
                    </div>
                </div>
                <div className="stepouter">
                    <div className="step">
                        <div className="numberTitle">
                            <div className="number">2</div>
                            Book sessions and get to work
                        </div>
                        Once you book your first private sports lesson, meet your local coach in-person and begin
                        training. Guarantee your privacy and payment security by booking all future sessions through our
                        secure platform.
                    </div>
                </div>
                <div className="stepouter">
                    <div className="step">
                        <div className="numberTitle">
                            <div className="number">3</div>
                            Analyze your game at home
                        </div>
                        Post short videos from your training sessions to your account. This will allow your private
                        coach to provide analytical feedback and pinpoint the areas of your game that need extra
                        attention. Our local trainers will make sure you are playing your best & feeling confident for
                        travel soccer, AAU basketball, or whatever sport you play!
                    </div>
                </div>
                </div> */}
                <div className="stepsBox">
                    <h3>3 easy steps to achieve your athletic goals</h3>
                    <div className="step">
                        <div className="numberTitle">
                            <div className="number">1</div>
                            Identify the right coach for you
                        </div>
                        Browse our list of private sports coaches and decide which coach will help you achieve your
                        goals. Message the local coach through our platform to determine when and where to begin private
                        sports training.
                    </div>
                    <div className="arrowContainer">
                        <ArrowDownward className="arrow" />
                    </div>
                    <div className="step">
                        <div className="numberTitle">
                            <div className="number">2</div>
                            Book sessions and get to work
                        </div>
                        Once you book your first private sports lesson, meet your local coach in-person and begin
                        training. Guarantee your privacy and payment security by booking all future sessions through our
                        secure platform.
                    </div>
                    <div className="arrowContainer">
                        <ArrowDownward className="arrow" />
                    </div>
                    <div className="step">
                        <div className="numberTitle">
                            <div className="number">3</div>
                            Analyze your game at home
                        </div>
                        Post short videos from your training sessions to your account. This will allow your private
                        coach to provide analytical feedback and pinpoint the areas of your game that need extra
                        attention. Our local trainers will make sure you are playing your best & feeling confident for
                        travel soccer, AAU basketball, or whatever sport you play!
                    </div>
                </div>
                <div className="resultsBox">
                    <h6>Results from the AU Community</h6>
                    <Carousel
                        indicators={false}
                        autoPlay={false}
                    >
                        <div className="result">
                            <div className="imgWrapper">
                                {/*eslint-disable-next-line*/}
                                <img src={CLOUDFRONT_UPLOADS + "static/result-dave.jpg"}
                                    alt="Parent and daughter posing for photo after 1on1 private coaching lesson for basketball to help improve her skills" />
                            </div>
                            <div className="resultRight">
                                <div className="resultTitle">Dave, basketball dad</div>
                                "Training with a private coach helped my daughter go from 5th grade benchwarmer to 6th
                                grade starter. AU made it incredibly easy to find the right coach and their video
                                feedback platform was a gamechanger. If you want to give your child an advantage on and
                                off the court, trust AU!"
                            </div>
                        </div>
                        <div className="result">
                            <div className="imgWrapper">
                                <img src={CLOUDFRONT_UPLOADS + "static/result-taryn.jpg"}
                                    alt="College athlete training during a 1on1 private sports lesson to prepare for the upcoming travel soccer season" />
                            </div>
                            <div className="resultRight">
                                <div className="resultTitle">Taryn, soccer player</div>
                                "It's always been my goal to play college soccer. My AU coach was a D1 standout and
                                she's taught me how hard I have to work if I want to play at the next level. Every time
                                I get a college recruiting letter now, I always text my AU coach and say I appreciate
                                all that she's done for
                                me!"
                            </div>
                        </div>
                        <div className="result">
                            <div className="imgWrapper">
                                <img src={CLOUDFRONT_UPLOADS + "static/result-tjohn.jpg"}
                                    alt="Former collegiate and professional basketball player training a young athlete in basketball to help him excel in high school sports" />
                            </div>
                            <div className="resultRight">
                                <div className="resultTitle">T John, basketball coach</div>
                                "AU treats their coaches like family. They help me find young athletes to train and
                                their community support team is top-notch. You can tell they understand the industry and
                                what coaches need to be successful, no matter if you're already established or just
                                starting out."
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div className="founderMessageBox">
                    <h5>A message from our founder</h5>
                    <div className="message">
                        <FormatQuote className="quote left" />
                        <FormatQuote className="quote right" />
                        As former collegiate and professional athletes, our team understands the benefits of training
                        with a private coach. It develops discipline, boosts confidence, and provides an undeniable
                        advantage - whether you are just learning the game or looking to earn a college
                        scholarship.  &nbsp;
                        <strong>Our mission is to provide young athletes with better opportunities to succeed.</strong>
                    </div>
                    <div className="right">
                        <img alt=" Private basketball coach posing"
                            src={CLOUDFRONT_UPLOADS + "static/266_Athletes.jpg"} />
                        <br /><br />
                        <span className="link text-large" onClick={() => setShowFounderInfo(true)}>Learn More</span>
                    </div>
                </div>
                <div className="helpBox">
                    <div className="questionCircle">?</div>
                    <div className="content text-large text-thin">
                        <h6>Need help finding the right coach?</h6>
                        Our support team will be there for you every step of the way.
                        <br />
                        <a className="link"
                            href="mailto:community@athletesuntapped.com">community@athletesuntapped.com</a>
                    </div>
                    <Button className="buttonPrimary"
                        onClick={() => window.location = "mailto:community@athletesuntapped.com"}>Email Us</Button>
                </div>
                <div className="sportBox">
                    <div className="sportBoxHeader">
                        <h3>Find A Private Coach Near You:</h3>
                    </div>
                    <div class="sportLinks">
                        <a href="https://findcoaches.athletesuntapped.com/baseball/">Baseball</a>
                        <a href="https://findcoaches.athletesuntapped.com/basketball/">Basketball</a>
                        <a href="https://findcoaches.athletesuntapped.com/field-hockey/">Field Hockey</a>
                        <a href="https://findcoaches.athletesuntapped.com/football/">Football</a>
                        <a href="https://findcoaches.athletesuntapped.com/golf/">Golf</a>
                        <a href="https://findcoaches.athletesuntapped.com/ice-hockey/">Ice Hockey</a>
                        <a href="https://findcoaches.athletesuntapped.com/lacrosse/">Lacrosse</a>
                        <a href="https://findcoaches.athletesuntapped.com/mental-performance/">Mental Performance</a>
                        <a href="https://findcoaches.athletesuntapped.com/pickleball/">Pickleball</a>
                        <a href="https://findcoaches.athletesuntapped.com/soccer/">Soccer</a>
                        <a href="https://findcoaches.athletesuntapped.com/softball/">Softball</a>
                        <a href="https://findcoaches.athletesuntapped.com/strength-and-speed/">Strength and Speed</a>
                        <a href="https://findcoaches.athletesuntapped.com/swimming/">Swimming</a>
                        <a href="https://findcoaches.athletesuntapped.com/tennis/">Tennis</a>
                        <a href="https://findcoaches.athletesuntapped.com/track-and-field/">Track and Field</a>
                        <a href="https://findcoaches.athletesuntapped.com/volleyball/">Volleyball</a>
                    </div>
                </div>
            </div>
            <Dialog onClose={() => setShowFounderInfo(false)} open={showFounderInfo}>
                <DialogContent className="founderDialog">
                    <h3>Gene Williams</h3>
                    <img alt=" Private basketball coach posing" src={CLOUDFRONT_UPLOADS + "static/266_Athletes.jpg"} />
                    <p>
                        I feel so strongly about our mission because I know what sports are capable of doing for young
                        people. A lot of great coaches helped me become a college basketball player at Johns Hopkins
                        University, and from there I continued my career overseas in Ireland. Once my playing days were
                        over, I started private coaching and found a passion for helping tomorrow's athletes grow on and
                        off the court.
                    </p>
                    <p>
                        During my coaching years, I realized there are limited services to help private trainers grow
                        their business. That's why my goal with AU is simple: help athletes find qualified coaches and
                        use technology to strengthen those relationships.
                    </p>
                </DialogContent>
            </Dialog>

            {/* <SportsNav /> */}
            <BottomNav />
            {isIos ? <div className="appLinkBar" onClick={() => window.location = "https://apps.apple.com/us/app/athletes-untapped-1v1-lessons/id6447133479"}>Get the App</div> : null}
        </div>

    );

};

export default Landing;