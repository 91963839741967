import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import ReactDOM from "react-dom";
import App from "./screens/App/App";
import * as serviceWorker from "./serviceWorker";

const key = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(`${key}`);

// Wrap entire application in Firebase context so every
// component can interact with Firebase
// Also wrap with Stripe
ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
