import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Colors from "../../constants/colors";
import * as firebase from "firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import './VerifyEmail.css';

const VerifyEmail = props => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (result == null) {
      firebase.auth().checkActionCode(props.actionCode)
        .then(res => {
          console.log('check result', res);
          return firebase.auth().applyActionCode(props.actionCode)
        })
        .then(res => {
          console.log('result', res);
          setResult(true);
        }, error => {
          console.log('error', error);
          setResult(false);
        })
    }
  });

  return (
    <div className="verifyEmail">
      <div className="verifyEmailInner">
        <div className="logoWrapper">
        </div>
        {result === true ?
          <div>
            <h4>Your email address has been verified!</h4>
            <Link to="/login">
              <button style={{background: Colors.secondary}} type="submit">Login</button>
            </Link>
          </div> : null}
        {result === false ?
          <div>
            <h2 className="error">You email address has not been verified.</h2>
            <p className="text-large">Your verification link may have expired. Please login and resend your email verification link from the dashboard.</p>
            <Link to="/login">
              <button style={{background: Colors.secondary}} type="submit">Login</button>
            </Link>
          </div> : null}
        {result === null ? <CircularProgress/> : null}
      </div>
    </div>
  )
}

export default VerifyEmail