export const createUser = (vals) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(vals),
  };
  return fetch("/api/v1/join", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the catch block to hit
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const createEvent = (token, event, status, creator) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(event),
  };
  return fetch("/api/v1/create/event/" + status + "/" + creator, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the catch block to hit
        return Promise.reject(error);
      }
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createEvents = (token, events, status, creator) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(events),
  };
  return fetch(
    "/api/v1/create/events/" + status + "/" + creator,
    requestOptions
  )
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the catch block to hit
        return Promise.reject(error);
      }
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createRepeatingEvent = (token, event) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(event),
  };
  return fetch("/api/v1/create/event/repeating", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the catch block to hit
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const updateEvent = (token, eventID, updates, updater) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(updates),
  };
  return fetch(
    "/api/v1/update/event/" + eventID + "/" + updater,
    requestOptions
  )
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the catch block to hit
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const completeEvent = (token, eventID, updates, completer) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(updates),
  };
  return fetch(
    "/api/v1/complete/event/" + eventID + "/" + completer,
    requestOptions
  )
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the catch block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getEvents = (token, id, date) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `/api/v1/events?id=${id}&date=${date}`;

  return fetch(url, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAthleteEvents = (token, id, date) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `/api/v1/athlete/events?id=${id}&date=${date}`;

  return fetch(url, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getRepeatingEvents = (token, id, type) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `/api/v1/events/repeating?id=${id}&type=${type}`;

  return fetch(url, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCompletedEvents = (token, id, type) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `/api/v1/events/completed?id=${id}&type=${type}`;

  return fetch(url, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createMessage = (token, message) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(message),
  };

  return fetch("/api/v1/create/message", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the catch block to hit
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const createEmailSignup = (
  email,
  zip,
  firstName,
  message,
  phone
) => {
  const body = {
    email: email,
    zip: zip,
    firstName: firstName,
    message: message,
    phone: phone,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  return fetch("/api/v1/email_signups", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the catch block to hit
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const getCoachClients = (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return fetch("/api/v1/get/coach/clients/" + id, requestOptions)
    .then(async (response) => {
      const data = await response.json();
      console.log("data",data)
      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCoachZips = (id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch("/api/v1/coach_zips", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const stripeGetCoaches = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      //"Authorization":'Bearer ' + token
    },
  };
  return fetch("/api/v1/stripe/pay/coaches", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAthleteCoaches = (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return fetch("/api/v1/get/athlete/coaches/" + id, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCoachById = (id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetch("/api/v1/get/coaches/" + id, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getMessages = (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return fetch("/api/v1/messages/" + id, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getProfile = (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return fetch("/api/v1/profile/" + id, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateProfile = (token, vals, id) => {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(vals),
  };
  return fetch("/api/v1/profile/" + id, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteUser = (token, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return fetch("/api/v1/account/delete/" + id, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateVideoComments = (token, id, comment) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(comment),
  };
  return fetch("/api/v1/update/video/comments/" + id, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCoaches = (zipCode, radius, sport, page, limit) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  let url = sport
    ? `/api/v1/coaches?zip=${zipCode}&radius=${radius}&sport=${sport}&page=${page ? page : 0}&limit=${limit}`
    : `/api/v1/coaches?zip=${zipCode}&radius=${radius}&page=${page}&limit=${limit}`;

  return fetch(url, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const SendPasswordResetSuccessEmail = (email) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  };

  return fetch("/api/v1/user/send_password_reset_success", requestOptions)
    .then(async (response) => {
      // Check for error response
      if (!response.ok) {
        const data = await response.json();
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return "";
    })
    .catch((error) => {
      throw error;
    });
};

export const createPaymentIntent = (token, items) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(items),
  };

  return fetch("/api/v1/create/payment/intent", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const modifyPaymentIntent = (token, data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };

  return fetch("/api/v1/modify/payment/intent", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const confirmPaymentIntent = (token, confirmation) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(confirmation),
  };

  return fetch("/api/v1/confirm/payment/intent", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const saveVideoMetadata = (token, items) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(items),
  };

  return fetch("/api/v1/save/video/metadata", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createCustomer = (token, items) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(items),
  };

  return fetch("/api/v1/create/customer", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createPaymentMethod = (token, items) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(items),
  };

  return fetch("/api/v1/create/payment/method", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPaymentMethods = (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetch("/api/v1/get/payment/methods/" + id, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getConnectedAccount = (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetch("/api/v1/get/stripe/connected/account/" + id, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const awsPresignedPost = (firebaseToken, uploadData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + firebaseToken,
    },
    body: JSON.stringify(uploadData),
  };

  return fetch("/api/v1/aws/presigned/post", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateProfileImage = (firebaseToken, uploadData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + firebaseToken,
    },
    body: JSON.stringify(uploadData),
  };

  return fetch("/api/v1/profile/image", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createActionImagePresignedUrl = (firebaseToken, uploadData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + firebaseToken,
    },
    body: JSON.stringify(uploadData),
  };

  return fetch("/api/v1/profile/action-images", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getActionImages = (user_id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch("/api/v1/profile/" + user_id + "/action-images/", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteActionImage = (firebaseToken, id) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + firebaseToken,
    },
  };

  return fetch("/api/v1/action-images/" + id, requestOptions)
    .then(async (response) => {
      // Check for error response
      if (!response.ok) {
        const data = await response.json();
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const awsUploadFile = (url, file) => {
  const fileData = new FormData();
  fileData.append("video", file);
  const uploadURL = new URL(url);
  const requestOptions = {
    method: "PUT",
    headers: {
      Authorization:
        "AWS " +
        uploadURL.searchParams.get("AWSAccessKeyId") +
        ":" +
        uploadURL.searchParams.get("Signature"),
    },
    body: fileData,
  };

  return fetch(url, requestOptions)
    .then(async (response) => {
      const data = await response;

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const awsPresignedGet = (firebaseToken, data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + firebaseToken,
    },
    body: JSON.stringify(data),
  };

  return fetch("/api/v1/aws/presigned/get", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createCheckrCandidate = (firebaseToken, candidateData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + firebaseToken,
    },
    body: JSON.stringify(candidateData),
  };

  return fetch("/api/v1/create/checkr/candidate", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateCheckrCandidate = (firebaseToken, updateData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + firebaseToken,
    },
    body: JSON.stringify(updateData),
  };

  return fetch("/api/v1/update/checkr/candidate", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getFinancialSummary = (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetch("/api/v1/get/financial-summary/" + id, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getIncome = (token, id, year) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = "/api/v1/get/income/" + id;
  if (year) {
    url += "?year=" + year;
  }
  return fetch(url, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createReview = (firebaseToken, data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + firebaseToken,
    },
    body: JSON.stringify(data),
  };

  return fetch("/api/v1/reviews", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getReviews = (user_id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch("/api/v1/reviews/" + user_id, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getReviewAvailability = (user_id, author_id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(
    "/api/v1/reviews/availability/" + user_id + "/" + author_id,
    requestOptions
  )
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPendingReviews = (firebaseToken, author_id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + firebaseToken,
    },
  };

  return fetch("/api/v1/reviews/pending/" + author_id, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export function getTimezones() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch("/api/v1/timezones", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function createCustomPricingPackage(token, body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };

  return fetch("/api/v1/custom-pricing-packages", requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCustomPricingPackage(coach_uid, custom_pricing_package_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `/api/v1/coaches/${coach_uid}/custom-pricing-packages/${custom_pricing_package_id}`,
    requestOptions
  )
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the "catch" block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
}

export const deleteTrainingLocation = (token, userId, locationId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetch(`/api/v1/profile/${userId}/training-location/${locationId}`, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // Check for error response
      if (!response.ok) {
        // Get error message from body or default to response status
        const error = (data && data.message) || response.status;

        // Rejecting the promise will force the catch block to hit
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      throw error;
    });
};