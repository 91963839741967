import React from 'react';
import './CoachProfileBackground.css';
import Button from "@material-ui/core/Button";


const CoachProfileBackground = (props) => {
  return (
    <div>
      <h3>Athletic Background</h3>
      <div className="backgroundBlock">
        <div className="label">Summary of Career and Accomplishments</div>
        <div className="content">
          {!props.public ?
            <textarea
              value={props.states["profileField1"]}
              onChange={e => {
                props.stateFunctions["profileField1"](e.target.value);
                props.setUploadVals({
                  ...props.uploadVals,
                  "profileField1": e.target.value
                });
              }
              }/> : props.states['profileField1']}
        </div>
      </div>
      <div className="backgroundBlock">
        <div className="label">Who is the best coach you ever had and why?</div>
        <div className="content">
          {!props.public ?
            <textarea
              value={props.states["profileField2"]}
              onChange={e => {
                props.stateFunctions["profileField2"](e.target.value);
                props.setUploadVals({
                  ...props.uploadVals,
                  "profileField2": e.target.value
                });
              }
              }/> : props.states['profileField2']}
        </div>
      </div>
      <div className="backgroundBlock">
        <div className="label">What should athletes and parents know about you?</div>
        <div className="content">
          {!props.public ?
            <textarea
              value={props.states["profileField3"]}
              onChange={e => {
                props.stateFunctions["profileField3"](e.target.value);
                props.setUploadVals({
                  ...props.uploadVals,
                  "profileField3": e.target.value
                });
              }
              }/> : props.states['profileField3']}
        </div>
      </div>
      {!props.public ?
        <div className="coachProfileBackgroundSave">
          <Button className="buttonPrimary" onClick={e => props.submit(e)}>Save</Button>
        </div> : null}
      <h3>Coaching Background</h3>
      <div className="backgroundBlock">
        <div className="label">Summary of Coaching Background</div>
        <div className="content">
          {!props.public ?
            <textarea
              value={props.states["profileField4"]}
              onChange={e => {
                props.stateFunctions["profileField4"](e.target.value);
                props.setUploadVals({
                  ...props.uploadVals,
                  "profileField4": e.target.value
                });
              }
              }/> : props.states['profileField4']}
        </div>
      </div>
      <div className="backgroundBlock">
        <div className="label">How can you help someone improve their game?</div>
        <div className="content">
          {!props.public ?
            <textarea
              value={props.states["profileField5"]}
              onChange={e => {
                props.stateFunctions["profileField5"](e.target.value);
                props.setUploadVals({
                  ...props.uploadVals,
                  "profileField5": e.target.value
                });
              }
              }/> : props.states['profileField5']}
        </div>
      </div>
      <div className="backgroundBlock">
        <div className="label">What does a typical training session look like?</div>
        <div className="content">
          {!props.public ?
            <textarea
              value={props.states["profileField6"]}
              onChange={e => {
                props.stateFunctions["profileField6"](e.target.value);
                props.setUploadVals({
                  ...props.uploadVals,
                  "profileField6": e.target.value
                });
              }
              }/> : props.states['profileField6']}
        </div>
      </div>
      {!props.public ?
        <div className="coachProfileBackgroundSave">
          <Button className="buttonPrimary" onClick={e => props.submit(e)}>Save</Button>
        </div> : null}
    </div>
  );
}

export default CoachProfileBackground;