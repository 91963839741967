import React, { useContext, useEffect, useState } from "react";
import * as firebase from "firebase/app";
import { useHistory, useLocation, withRouter, Link } from "react-router-dom";
import { createUser } from "src/api/api";
import TextField from "@material-ui/core/TextField";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import "./ApplyToCoachForm.scss";
import logo from "src/components/HomeNav/AULogoSmall.png";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { green } from "@material-ui/core/colors";
import BottomNav from "src/components/BottomNav/BottomNav";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiPhoneNumber from "material-ui-phone-number";
import { AuthContext } from "../App/App";
import { Checkbox } from "@material-ui/core";
import { sports } from "src/constants/sports";

import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const spinnerTheme = createTheme({
  palette: {
    primary: {
      main: "#006400",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validatePhone(phone) {
  return !phone || phone.length === 0 || phone.length === 14;
}

function validateZip(zip) {
  const re = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return re.test(String(zip).toLowerCase());
}

function validatePassword(password) {
  var re = /\d/g; // Must have at least 1 number
  return String(password).length >= 6 && re.test(String(password));
}

const textFieldColor = "green";
const textFieldColorNormal = "lightslategray";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(3),
      "& .MuiAutocomplete-root, & .MuiInputBase-root": {
        backgroundColor: "white",
      },

      "& label.Mui-focused": {
        color: textFieldColor,
      },
      "& label": {},
      "& .MuiInput-underline:after": {
        borderBottomColor: textFieldColor,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: textFieldColorNormal,
          borderRadius: "0",
          borderWidth: "1.2px",
        },
        "&:hover fieldset": {
          borderColor: textFieldColor,
          borderWidth: "2px",
        },
        "&.Mui-focused fieldset": {
          borderColor: textFieldColor,
          borderWidth: "2px",
        },
      },
    },
  },

  rootSmall: {
    "& .MuiSelect-root": {},
    "& .MuiTextField-root": {
      margin: theme.spacing(3),
      "& .MuiAutocomplete-root, & .MuiInputBase-root": {
        backgroundColor: "white",
      },
      "& label.Mui-focused": {
        color: textFieldColor,
      },
      "& label": {},
      "& .MuiInput-underline:after": {
        borderBottomColor: textFieldColor,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: textFieldColorNormal,
          borderRadius: "0",
          borderWidth: "1.2px",
        },
        "&:hover fieldset": {
          borderColor: textFieldColor,
          borderWidth: "2px",
        },
        "&.Mui-focused fieldset": {
          borderColor: textFieldColor,
          borderWidth: "2px",
        },
      },
    },
  },

  formControl: {
    margin: theme.spacing(3),
    minWidth: "30ch",
  },
  formControl2: {
    marginLeft: theme.spacing(3),
    minWidth: "30ch",
    fontSize: "small",
  },
  p: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(1),
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
  },
  label: {},
}));

const GreenCheckbox = withStyles({
  root: {
    color: "lightslategray",
    "&$checked": {
      color: green[800],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ApplyToCoachForm = (props) => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (user) {
    if (params.returnTo) {
      window.location = params.returnTo;
    } else {
      history.push("/dashboard");
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoaded, setIsLoaded] = useState(true);
  const [error, setErrors] = useState("");
  const [errorSnackBar, setErrorSnackBar] = useState(false);

  let whetherAthlete = false;

  const [athleteView, setAthleteView] = useState(whetherAthlete);

  // General function for submitting both new coaches and new athletes to backend
  const submit = (vals) => {
    // Put the loading screen up
    setIsLoaded(false);
    // Create the user then sign in with Firebase as the newly created user
    createUser(vals)
      .then((res) => {
        return firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      })
      .then(() => {
        return firebase
          .auth()
          .signInWithEmailAndPassword(vals.email, vals.password);
      })
      .catch((e) => {
        setErrors(e);
        setErrorSnackBar(true);
        // Only set isLoaded if there is an error to display. Otherwise, keep the loading
        // screen up until App.js switches the page
        setIsLoaded(true);
      });
  };

  // Switch between coach and athlete view
  const switchView = () => {
    setAthleteView(!athleteView);
  };

  if (isLoaded) {
      return (
        <div>
          <div className="greenBoxSignup">
            <CoachForm
              submit={submit}
              switchView={switchView}
            />
            <Snackbar
              open={errorSnackBar}
              onClose={(e) => setErrorSnackBar(false)}
            >
              <Alert onClose={(e) => setErrorSnackBar(false)} severity="error">
                {error}
              </Alert>
            </Snackbar>
          </div>
          <BottomNav />
        </div>
      );
  } else {
    return (
      <div
        style={{ marginTop: "5rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
      >

        <ThemeProvider theme={spinnerTheme}>
          <CircularProgress />
        </ThemeProvider>
      </div>
    );
  }
};

const CoachForm = (props) => {
  //const recaptcha = useRef(null); // reCaptcha useRef
  const routerHistory = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sport, setSport] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [basketballPositions, setBasketballPositions] = useState({
    forward: false,
    guard: false,
    center: false,
  });
  const [soccerPositions, setSoccerPositions] = useState({
    forward: false,
    midfield: false,
    defense: false,
    goalie: false,
  });
  const [password2, setPassword2] = useState("");
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [tooManyExp, setTooManyExp] = useState(false);
  const [errorSnackBar, setErrorSnackBar] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState("");
  const [coachingExperiences, setCoachingExperiences] = useState([
    {
      team: "",
      level: "",
      customLevel: null,
      business: null,
      from: "",
      to: "",
      current: false,
    },
  ]);
  const [playingExperiences, setPlayingExperiences] = useState([
    {
      team: "",
      level: "",
      customLevel: null,
      from: "",
      to: "",
      current: false,
    },
  ]);
  const [hasNoCoachingExp, setHasNoCoachingExp] = useState(false);
  const [hasNoPlayingExp, setHasNoPlayingExp] = useState(false);
  const [hasSeenCoachAgreement, setHasSeenCoachAgreement] = useState(false);
  const [showCoachingAgreement, setShowCoachingAgreement] = useState(false);
  const [coachAgreement, setCoachAgreement] = useState(false);
  
  const location = useLocation();

  useEffect(() => {
    if(location.state?.zipCode) {
      setZipCode(location.state.zipCode);
    }
  }, [location.state]); 


  var years = [];
  for (var i = parseInt(new Date().getFullYear()); i > 1950; i--) {
    years.push(String(i));
  }

  const [availableYears] = useState(years);

  const vals = {
    firstName: firstName,
    lastName: lastName,
    sport: sport,
    zipCode: zipCode,
    email: email,
    emailConfirm: emailConfirm,
    phone: phone,
    password: password,
    profileType: "coach",
    playingExp: playingExperiences,
    coachingExp: coachingExperiences,
    password2: password2,
    terms: terms,
    privacy: privacy,
    coachAgreement: coachAgreement,
    referralCode: referralCode,
  };

  const submitCoach = (e) => {
    // Make sure that form runs our logic and not whatever default logic exists for forms
    // in HTML/JavaScript
    e.preventDefault();

    //TODO: do client-side validation on user's input (i.e. ensure all required fields are present)
    if (!vals["firstName"] || vals["firstName"] === "") {
      setErrorSnackBarMessage("Please enter a first name.");
      setErrorSnackBar(true);
      return;
    }
    if (!vals["lastName"] || vals["lastName"] === "") {
      setErrorSnackBarMessage("Please enter a last name.");
      setErrorSnackBar(true);
      return;
    }
    if (
      !vals["email"] ||
      vals["email"] === "" ||
      !validateEmail(vals["email"])
    ) {
      setErrorSnackBarMessage("Please enter a valid email.");
      setErrorSnackBar(true);
      return;
    }
    if (!vals["emailConfirm"] || vals["email"] !== vals["emailConfirm"]) {
      setErrorSnackBarMessage("Emails entered do not match");
      setErrorSnackBar(true);
      return;
    }
    if (!validatePhone(vals["phone"])) {
      setErrorSnackBarMessage("Please enter a valid phone number.");
      setErrorSnackBar(true);
      return;
    }
    if (
      !vals["zipCode"] ||
      vals["zipCode"] === "" ||
      !validateZip(vals["zipCode"])
    ) {
      setErrorSnackBarMessage("Please enter a valid zip code.");
      setErrorSnackBar(true);
      return;
    }
    if (!vals["sport"] || vals["sport"].length === 0) {
      setErrorSnackBarMessage("Please select at least one sport.");
      setErrorSnackBar(true);
      return;
    }

    if (vals["sport"] === "basketball") {
      if (
        !basketballPositions["forward"] &&
        !basketballPositions["guard"] &&
        !basketballPositions["center"]
      ) {
        setErrorSnackBarMessage(
          "Please select at least one basketball position you can coach."
        );
        setErrorSnackBar(true);
        return;
      }
    }

    if (vals["sport"] === "soccer") {
      if (
        !soccerPositions["forward"] &&
        !soccerPositions["midfield"] &&
        !soccerPositions["defense"] &&
        !soccerPositions["goalie"]
      ) {
        setErrorSnackBarMessage(
          "Please select at least one soccer position you can coach."
        );
        setErrorSnackBar(true);
        return;
      }
    }

    if (vals["sport"] === "basketball") {
      vals["positions"] = basketballPositions;
    } else if (vals["sport"] === "soccer") {
      vals["positions"] = soccerPositions;
    }

    if (!vals["password"] || !validatePassword(vals["password"])) {
      setErrorSnackBarMessage(
        "Please enter a password of length 6 or more and with at least 1 number."
      );
      setErrorSnackBar(true);
      setPassword("");
      setPassword2("");
      return;
    }

    if (vals["password"] !== vals["password2"]) {
      setErrorSnackBarMessage(
        "Please make sure your password confirmation matches your original."
      );
      setErrorSnackBar(true);
      setPassword("");
      setPassword2("");
      return;
    }

    delete vals["password2"];

    if (hasNoPlayingExp && hasNoCoachingExp) {
      setErrorSnackBarMessage(
        "Please enter at least one playing experience or coaching experience for the sport you selected."
      );
      setErrorSnackBar(true);
      return;
    }

    if (!hasNoPlayingExp) {
      const playExpCheckResult = checkPlayingExp(vals["playingExp"]);
      if (playExpCheckResult !== "No error") {
        setErrorSnackBarMessage(playExpCheckResult);
        setErrorSnackBar(true);
        return;
      }
    }

    if (!hasNoCoachingExp) {
      const coachExpCheckResult = checkCoachingExp(vals["coachingExp"]);
      if (coachExpCheckResult !== "No error") {
        setErrorSnackBarMessage(coachExpCheckResult);
        setErrorSnackBar(true);
        return;
      }
    }

    if (!vals["terms"]) {
      setErrorSnackBarMessage("Terms of Service box is not checked.");
      setErrorSnackBar(true);
      return;
    }
    if (!vals["privacy"]) {
      setErrorSnackBarMessage("Privacy Policy box is not checked.");
      setErrorSnackBar(true);
      return;
    }
    if (!vals["coachAgreement"]) {
      setErrorSnackBarMessage(
        "Please read and agree to the Coaching Agreement.  You must open the Coaching Agreement link to proceed."
      );
      setErrorSnackBar(true);
      return;
    }
    if (hasNoCoachingExp) {
      delete vals["coachingExp"];
    }
    if (hasNoPlayingExp) {
      delete vals["playingExp"];
    }
    /*
        let captchaValueCoach = recaptcha.current?.getValue()
        if (!captchaValueCoach) {
          setErrorSnackBarMessage("Please verify the reCAPTCHA!");
          setErrorSnackBar(true);
          return;
        }*/

    // Submit the coach using the submit function passed in the props
    props.submit(vals);
  };

  const styleClasses = useStyles();

  const openCoachAgreement = () => {
    setShowCoachingAgreement(true);
    setHasSeenCoachAgreement(true);
  };
  const basketballPositionsHandler = (e) => {
    if (sport.indexOf("soccer") === -1) {
      setSoccerPositions({
        forward: false,
        midfield: false,
        defense: false,
        goalie: false,
      });
    }
    setBasketballPositions({
      ...basketballPositions,
      [e.target.name]: e.target.checked,
    });
  };

  const soccerPositionsHandler = (e) => {
    if (sport.indexOf("basketball") === -1) {
      setBasketballPositions({ forward: false, guard: false, center: false });
    }
    setSoccerPositions({
      ...soccerPositions,
      [e.target.name]: e.target.checked,
    });
  };

  const coachingExperienceHandler = (index, key, val) => {
    const coachExpCopy = coachingExperiences.slice();
    coachExpCopy[index][key] = val;
    setCoachingExperiences(coachExpCopy);
    if (val !== "other") {
      coachingExperiences[index]["customLevel"] = null;
    }
    if (val !== "personal") {
      coachingExperiences[index]["business"] = null;
    }
  };

  const addCoachingExperience = (e) => {
    e.preventDefault();
    if (coachingExperiences.length >= 5) {
      setTooManyExp(true);
      return;
    }
    const coachExpCopy = coachingExperiences.slice();
    coachExpCopy.push({
      team: "",
      level: "",
      customLevel: null,
      business: null,
      from: "",
      to: "",
      current: false,
    });

    setCoachingExperiences(coachExpCopy);
  };

  const removeCoachingExperience = (e, index) => {
    e.preventDefault();
    var coachExpCopy = [];
    for (var i = 0; i < coachingExperiences.length; i++) {
      if (i !== index) {
        coachExpCopy.push(coachingExperiences[i]);
      }
    }
    setCoachingExperiences(coachExpCopy);
  };

  const checkCoachingExp = (experiences) => {
    if (experiences[0]["level"] === "" || experiences[0]["from"] === "") {
      return "You must have at least one coaching experience filled completely.";
    }
    if (experiences[0]["current"] === false && experiences[0]["to"] === "") {
      return "You must specify the end date of a coaching experience, or specify that it is current.";
    }

    if (
      experiences[0]["from"] !== "" &&
      experiences[0]["to"] !== "" &&
      parseInt(experiences[0]["to"]) < parseInt(experiences[0]["from"])
    ) {
      return "End date of a coaching experience cannot be before the start date!";
    }

    if (
      (experiences[0]["level"] === "head" ||
        experiences[0]["level"] === "assistant") &&
      experiences[0]["team"] === ""
    ) {
      return "Head and assistant coaching experiences must have an associated team name.";
    }

    if (experiences.length > 1) {
      for (let i = 1; i < experiences.length; i++) {
        if (experiences[i]["level"] === "" || experiences[i]["from"] === "") {
          return "If you add additional coaching experiences they must be filled fully.";
        }
        if (
          experiences[i]["current"] === false &&
          experiences[i]["to"] === ""
        ) {
          return "You must either specify the end date of a coaching experience or specify that it is current.";
        }

        if (
          experiences[i]["from"] !== "" &&
          experiences[i]["to"] !== "" &&
          parseInt(experiences[i]["to"]) < parseInt(experiences[i]["from"])
        ) {
          return "End date of a coaching experience cannot be before the start date!";
        }

        if (
          (experiences[i]["level"] === "head" ||
            experiences[i]["level"] === "assistant") &&
          experiences[i]["team"] === ""
        ) {
          return "Head and assistant coaching experiences must have an associated team name.";
        }
      }
    }

    return "No error";
  };

  const playingExperienceHandler = (index, key, val) => {
    const playingExpCopy = playingExperiences.slice();
    playingExpCopy[index][key] = val;
    setPlayingExperiences(playingExpCopy);
    if (val !== "other") {
      playingExperiences[index]["customLevel"] = null;
    }
  };

  const addPlayingExperience = (e) => {
    e.preventDefault();
    if (playingExperiences.length >= 5) {
      setTooManyExp(true);
      return;
    }
    const playingExpCopy = playingExperiences.slice();
    playingExpCopy.push({
      team: "",
      level: "",
      customLevel: null,
      from: "",
      to: "",
      current: false,
    });
    setPlayingExperiences(playingExpCopy);
  };

  const removePlayingExperience = (e, index) => {
    e.preventDefault();
    var playingExpCopy = [];
    for (let i = 0; i < playingExperiences.length; i++) {
      if (i !== index) {
        playingExpCopy.push(playingExperiences[i]);
      }
    }
    setPlayingExperiences(playingExpCopy);
  };

  const checkPlayingExp = (experiences) => {
    if (
      experiences[0]["team"] === "" ||
      experiences[0]["level"] === "" ||
      experiences[0]["from"] === ""
    ) {
      return "You must have at least one playing experience filled completely.";
    }
    if (experiences[0]["current"] === false && experiences[0]["to"] === "") {
      return "You must specify the end date of a playing experience, or specify that it is current.";
    }

    if (
      experiences[0]["from"] !== "" &&
      experiences[0]["to"] !== "" &&
      parseInt(experiences[0]["to"]) < parseInt(experiences[0]["from"])
    ) {
      return "End date of a playing experience cannot be before the start date!";
    }

    if (experiences.length > 1) {
      for (let i = 1; i < experiences.length; i++) {
        if (
          experiences[i]["team"] === "" ||
          experiences[i]["level"] === "" ||
          experiences[i]["from"] === ""
        ) {
          return "If you add additional playing experiences they must be filled fully.";
        }
        if (
          experiences[i]["current"] === false &&
          experiences[i]["to"] === ""
        ) {
          return "You must either specify the end date of a playing experience or specify that it is current.";
        }

        if (
          experiences[i]["from"] !== "" &&
          experiences[i]["to"] !== "" &&
          parseInt(experiences[i]["to"]) < parseInt(experiences[i]["from"])
        ) {
          return "End date of a playing experience cannot be before the start date!";
        }
      }
    }

    return "No error";
  };

  return (
    <div className="joinContainer">
      <div
        style={{ textAlign: "center", marginLeft: "24px", marginRight: "24px" }}
      >
        <img src={logo} className="AULogoSignUp" alt="Athletes Untapped" />
        <p className="pSignUpHeader">
          Apply to Become a Private Coach with AU!
        </p>
        <div className="switchViewJoinPage">
          <p>{"Not Looking to Coach?"}</p>
          <Link to="/join">
          <p>Sign Up as a Parent or Athlete Instead.</p>
          </Link>
        </div>
        <div className="switchViewJoinPage">
          <p>{"Fields with an asterisk (*) are required."}</p>
          <p></p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <hr
            style={{
              border: "1px solid lightgray",
              width: "45%",
              marginBottom: "1.5rem",
            }}
          ></hr>
        </div>
      </div>
      <Snackbar open={errorSnackBar} onClose={(e) => setErrorSnackBar(false)}>
        <Alert onClose={(e) => setErrorSnackBar(false)} severity="error">
          {errorSnackBarMessage}
        </Alert>
      </Snackbar>
      <div
        className="fieldContainer removeFlexMobile"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <form
          className={styleClasses.rootSmall}
          onSubmit={(e) => submitCoach(e)}
        >
          <div
            className="twoFieldContainer"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <TextField
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              name="firstName"
              type="firstName"
              label="First Name*"
              variant="outlined"
              inputProps={{ style: { minWidth: "30ch", boxShadow: "none" } }}
            />
            <TextField
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              name="lastName"
              type="lastName"
              label="Last Name*"
              variant="outlined"
              inputProps={{ style: { minWidth: "30ch", boxShadow: "none" } }}
            />
          </div>
          <div className="twoFieldContainer">
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              type="email"
              helperText="We'll email you confirmations and receipts for training sessions."
              label="Email*"
              variant="outlined"
              inputProps={{ style: { minWidth: "67.8ch", boxShadow: "none" } }}
            />
          </div>
          <div
            className="twoFieldContainer"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <TextField
              value={emailConfirm}
              onChange={(e) => setEmailConfirm(e.target.value)}
              name="emailConfirm"
              type="email"
              label="Confirm Email*"
              variant="outlined"
              inputProps={{ style: { minWidth: "67.8ch", boxShadow: "none" } }}
            />
          </div>
          <div>
            <MuiPhoneNumber
              autoFormat={true}
              disableCountryCode={true}
              defaultCountry={"us"}
              onlyCountries={["us"]}
              value={phone}
              onChange={(val) => setPhone(val)}
              name="phone"
              label="Phone"
              variant="outlined"
              helperText={
                "We'll send you reminders about upcoming training sessions - helpful for busy schedules! You can always opt out."
              }
              inputProps={{ style: { minWidth: "67.8ch", boxShadow: "none" } }}
            />
          </div>
          <div>
            <TextField
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              name="zipCode"
              type="zipCode"
              label="Zip Code*"
              variant="outlined"
              inputProps={{ style: { minWidth: "30ch", boxShadow: "none" } }}
            />
          </div>
          {/* signup sports checklist */}
          <div>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={sports}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => setSport(newValue.map(option => option.value))}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    <span style={{ fontWeight: 100, fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}>
                      {option.label}
                    </span>
                  </li>
                );
              }}
              style={{ width: 400 }}
              renderInput={(params) => (
                <TextField {...params}
                  name="sport"
                  type="sport"
                  label="Sport"
                  variant="outlined"
                  placeholder="Favorites"
                />
              )}
            />
          </div>


          {sport.indexOf("basketball") > -1 ? (
            <div
              className="removeFlexMobile"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "start",
              }}
            >
              <div className="coachJoinPositions">
                <p>Basketball Positions* </p>
                <p
                  onClick={(e) =>
                    setBasketballPositions({
                      forward: true,
                      guard: true,
                      center: true,
                    })
                  }
                >
                  (Check All That Apply)
                </p>
              </div>

              <FormControl
                style={{ display: "flex" }}
                component="fieldset"
                className={styleClasses.formControl}
              >
                <FormGroup>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={basketballPositions.forward}
                          onChange={basketballPositionsHandler}
                          name="forward"
                        />
                      }
                      label="Forward"
                      classes={{ label: styleClasses.label }}
                    />
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={basketballPositions.guard}
                          onChange={basketballPositionsHandler}
                          name="guard"
                        />
                      }
                      label="Guard"
                      classes={{ label: styleClasses.label }}
                    />
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={basketballPositions.center}
                          onChange={basketballPositionsHandler}
                          name="center"
                        />
                      }
                      label="Center"
                      classes={{ label: styleClasses.label }}
                    />
                  </div>
                </FormGroup>
              </FormControl>
            </div>
          ) : null}
          {sport.indexOf("soccer") > -1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "start",
              }}
            >
              <div className="coachJoinPositions">
                <p>Soccer Positions* </p>
                <p
                  onClick={(e) =>
                    setSoccerPositions({
                      forward: true,
                      midfield: true,
                      defense: true,
                      goalie: true,
                    })
                  }
                >
                  (Check All That Apply)
                </p>
              </div>
              <FormControl
                style={{ display: "flex" }}
                component="fieldset"
                className={styleClasses.formControl}
              >
                <FormGroup>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={soccerPositions.forward}
                          onChange={soccerPositionsHandler}
                          name="forward"
                        />
                      }
                      label="Forward"
                      classes={{ label: styleClasses.label }}
                    />
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={soccerPositions.midfield}
                          onChange={soccerPositionsHandler}
                          name="midfield"
                        />
                      }
                      label="Midfield"
                      classes={{ label: styleClasses.label }}
                    />
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={soccerPositions.defense}
                          onChange={soccerPositionsHandler}
                          name="defense"
                        />
                      }
                      label="Defense"
                      classes={{ label: styleClasses.label }}
                    />
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={soccerPositions.goalie}
                          onChange={soccerPositionsHandler}
                          name="goalie"
                        />
                      }
                      label="Goalie"
                      classes={{ label: styleClasses.label }}
                    />
                  </div>
                </FormGroup>
              </FormControl>
            </div>
          ) : null}

          <div>
            <TextField
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              name="referralCode"
              type="referralCode"
              label="Referral Code"
              variant="outlined"
              inputProps={{ style: { minWidth: "30ch", boxShadow: "none" } }}
            />
          </div>
          <div>
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              value={password}
              type="password"
              label="Create Password*"
              variant="outlined"
              inputProps={{ style: { minWidth: "67.8ch", boxShadow: "none" } }}
            />
          </div>
          <div>
            <TextField
              onChange={(e) => setPassword2(e.target.value)}
              name="password2"
              value={password2}
              type="password"
              label="Re-Enter Password*"
              variant="outlined"
              inputProps={{ style: { minWidth: "67.8ch", boxShadow: "none" } }}
            />
          </div>
          <h3>Playing Experience</h3>
          <h6 className="text-thin">
            This information is requested by the AU team for review and auditing
            purposes.
            <br />
            It will not save to your account.
          </h6>
          <FormControl
            component="fieldset"
            className={styleClasses.formControl}
          >
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={hasNoPlayingExp}
                  classes={{ checked: styleClasses.checkbox }}
                  onChange={(e) => setHasNoPlayingExp(e.target.checked)}
                  name="noPlayingExp"
                />
              }
              label="I have no playing experience"
              classes={{ label: styleClasses.label }}
            />
          </FormControl>
          {!hasNoPlayingExp
            ? playingExperiences.map((experience, index) => (
              <div
                className="removeFlexMobile"
                style={{ display: "flex", flexDirection: "column" }}
                key={index}
              >
                <div className="coachExpHeader">
                  <p className={styleClasses.p}>
                    Playing Experience{" "}
                    {index === 0 ? index + 1 + "*" : index + 1}
                  </p>
                  {index !== 0 ? (
                    <p onClick={(e) => removePlayingExperience(e, index)}>
                      Remove
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
                <div
                  className="removeFlexMobile"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    name="playingExpLevel"
                    label="Level"
                    select
                    value={playingExperiences[index]["level"]}
                    onChange={(e) =>
                      playingExperienceHandler(index, "level", e.target.value)
                    }
                    variant="outlined"
                    SelectProps={{
                      style: { minWidth: "17ch", boxShadow: "none" },
                    }}
                  >
                    <MenuItem value={"highschool"}>High School</MenuItem>
                    <MenuItem value={"college"}>College</MenuItem>
                    <MenuItem value={"professional"}>Professional</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                  </TextField>
                  {playingExperiences[index]["level"] === "other" ? (
                    <TextField
                      name="playingExpCustomLevel"
                      label="Level"
                      type="text"
                      value={playingExperiences[index]["customLevel"]}
                      onChange={(e) =>
                        playingExperienceHandler(
                          index,
                          "customLevel",
                          e.target.value
                        )
                      }
                      variant="outlined"
                      inputProps={{
                        style: { minWidth: "17ch", boxShadow: "none" },
                      }}
                    />
                  ) : null}
                  <TextField
                    name="playingExpTeam"
                    label="Team Name"
                    type="text"
                    value={playingExperiences[index]["team"]}
                    onChange={(e) =>
                      playingExperienceHandler(index, "team", e.target.value)
                    }
                    variant="outlined"
                    inputProps={{
                      style: { minWidth: "17ch", boxShadow: "none" },
                    }}
                  />

                  <TextField
                    label="From"
                    select
                    value={playingExperiences[index]["from"]}
                    onChange={(e) =>
                      playingExperienceHandler(index, "from", e.target.value)
                    }
                    variant="outlined"
                    SelectProps={{
                      style: { minWidth: "9ch", boxShadow: "none" },
                    }}
                  >
                    {availableYears.map((year, index) => (
                      <MenuItem key={index} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </TextField>
                  {!playingExperiences[index]["current"] ? (
                    <TextField
                      label="To"
                      select
                      value={playingExperiences[index]["to"]}
                      onChange={(e) =>
                        playingExperienceHandler(index, "to", e.target.value)
                      }
                      variant="outlined"
                      SelectProps={{
                        style: { minWidth: "9ch", boxShadow: "none" },
                      }}
                    >
                      {availableYears.map((year, index) => (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <div
                      style={{
                        width: "91.05px",
                        height: "56px",
                        margin: "24px",
                      }}
                    ></div>
                  )}
                </div>
                <div></div>
                <div className="coachAddAnotherExperience">
                  <p onClick={(e) => addPlayingExperience(e)}>
                    + Add Another Experience
                  </p>
                  <FormControl
                    component="fieldset"
                    className={styleClasses.formControl2}
                  >
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={playingExperiences[index]["current"]}
                          classes={{ checked: styleClasses.checkbox }}
                          onChange={(e) =>
                            playingExperienceHandler(
                              index,
                              "current",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="I am currently doing this."
                      classes={{ label: styleClasses.label }}
                    />
                  </FormControl>
                </div>
              </div>
            ))
            : null}
          <p></p>
          <p></p>
          <h3>Coaching Experience</h3>
          <h6 className="text-thin">
            This information is requested by the AU team for review and auditing
            purposes.
            <br />
            It will not save to your account.
          </h6>
          <FormControl
            component="fieldset"
            className={styleClasses.formControl}
          >
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={hasNoCoachingExp}
                  classes={{ checked: styleClasses.checkbox }}
                  onChange={(e) => setHasNoCoachingExp(e.target.checked)}
                  name="noCoachingExp"
                />
              }
              label="I have no coaching experience"
              classes={{ label: styleClasses.label }}
            />
          </FormControl>
          {!hasNoCoachingExp
            ? coachingExperiences.map((experience, index) => (
              <div
                className="removeFlexMobile"
                style={{ display: "flex", flexDirection: "column" }}
                key={index}
              >
                <div className="coachExpHeader">
                  <p className={styleClasses.p}>
                    Coaching Experience{" "}
                    {index === 0 ? index + 1 + "*" : index + 1}
                  </p>
                  {index !== 0 ? (
                    <p onClick={(e) => removeCoachingExperience(e, index)}>
                      Remove
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
                <div
                  className="removeFlexMobile"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <TextField
                    name="coachingExpLevel"
                    label="Type"
                    select
                    value={coachingExperiences[index]["level"]}
                    onChange={(e) =>
                      coachingExperienceHandler(
                        index,
                        "level",
                        e.target.value
                      )
                    }
                    variant="outlined"
                    SelectProps={{
                      style: { minWidth: "17ch", boxShadow: "none" },
                    }}
                  >
                    <MenuItem value={"head"}>Head Coach</MenuItem>
                    <MenuItem value={"assistant"}>Assistant Coach</MenuItem>
                    <MenuItem value={"personal"}>Private Coach</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                  </TextField>
                  {coachingExperiences[index]["level"] === "other" ? (
                    <TextField
                      name="coachingExpCustomLevel"
                      label="Level"
                      type="text"
                      value={coachingExperiences[index]["customLevel"]}
                      onChange={(e) =>
                        coachingExperienceHandler(
                          index,
                          "customLevel",
                          e.target.value
                        )
                      }
                      variant="outlined"
                      inputProps={{
                        style: { minWidth: "17ch", boxShadow: "none" },
                      }}
                    />
                  ) : null}
                  {coachingExperiences[index]["level"] === "head" ||
                    coachingExperiences[index]["level"] === "assistant" ? (
                    <TextField
                      name="coachingExpTeam"
                      label="Team Name"
                      type="text"
                      value={coachingExperiences[index]["team"]}
                      onChange={(e) =>
                        coachingExperienceHandler(
                          index,
                          "team",
                          e.target.value
                        )
                      }
                      variant="outlined"
                      inputProps={{
                        style: { minWidth: "17ch", boxShadow: "none" },
                      }}
                    />
                  ) : null}

                  {coachingExperiences[index]["level"] === "personal" ? (
                    <TextField
                      name="coachingExpBusiness"
                      label="Business Name"
                      type="text"
                      value={coachingExperiences[index]["business"]}
                      onChange={(e) =>
                        coachingExperienceHandler(
                          index,
                          "business",
                          e.target.value
                        )
                      }
                      variant="outlined"
                      inputProps={{
                        style: { minWidth: "17ch", boxShadow: "none" },
                      }}
                    />
                  ) : null}

                  <TextField
                    label="From"
                    select
                    value={coachingExperiences[index]["from"]}
                    onChange={(e) =>
                      coachingExperienceHandler(index, "from", e.target.value)
                    }
                    variant="outlined"
                    SelectProps={{
                      style: { minWidth: "9ch", boxShadow: "none" },
                    }}
                  >
                    {availableYears.map((year, index) => (
                      <MenuItem key={index} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </TextField>
                  {!coachingExperiences[index]["current"] ? (
                    <TextField
                      label="To"
                      select
                      value={coachingExperiences[index]["to"]}
                      onChange={(e) =>
                        coachingExperienceHandler(index, "to", e.target.value)
                      }
                      variant="outlined"
                      SelectProps={{
                        style: { minWidth: "9ch", boxShadow: "none" },
                      }}
                    >
                      {availableYears.map((year, index) => (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <div
                      style={{
                        width: "91.05px",
                        height: "56px",
                        margin: "24px",
                      }}
                    ></div>
                  )}
                </div>
                <div></div>
                <div className="coachAddAnotherExperience">
                  <p onClick={(e) => addCoachingExperience(e)}>
                    + Add Another Experience
                  </p>
                  <FormControl
                    component="fieldset"
                    className={styleClasses.formControl2}
                  >
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={coachingExperiences[index]["current"]}
                          classes={{ checked: styleClasses.checkbox }}
                          onChange={(e) =>
                            coachingExperienceHandler(
                              index,
                              "current",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="I am currently doing this."
                      classes={{ label: styleClasses.label }}
                    />
                  </FormControl>
                </div>
              </div>
            ))
            : null}
          <p></p>
          <p></p>
          <div>
            <FormControl component="fieldset" className="iAgree">
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={terms}
                    classes={{ checked: styleClasses.checkbox }}
                    onChange={(e) => setTerms(e.target.checked)}
                    name="terms"
                  />
                }
                label="I agree to Athletes Untapped's"
                classes={{ label: styleClasses.label }}
              />
              <span
                className="link text-large"
                onClick={() => routerHistory.push("/site-terms")}
              >
                Terms of Service.*
              </span>
            </FormControl>
          </div>
          <div>
            <FormControl component="fieldset" className="iAgree">
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={privacy}
                    onChange={(e) => setPrivacy(e.target.checked)}
                    name="privacy"
                  />
                }
                label="I agree to Athletes Untapped's"
                classes={{ label: styleClasses.label }}
              />
              <span
                className="link text-large"
                onClick={() => routerHistory.push("/privacy-policy")}
              >
                Privacy Policy.*
              </span>
            </FormControl>
          </div>
          <div>
            <FormControl component="fieldset" className="iAgree">
              <FormControlLabel
                control={
                  <GreenCheckbox
                    disabled={!hasSeenCoachAgreement}
                    checked={coachAgreement}
                    onChange={(e) => setCoachAgreement(e.target.checked)}
                    name="privacy"
                  />
                }
                label="I agree to the Athletes Untapped"
                classes={{ label: styleClasses.label }}
              />
              <span
                className="link text-large"
                onClick={() => openCoachAgreement()}
              >
                Coaches Agreement.*
              </span>
            </FormControl>
          </div>

          <Snackbar open={tooManyExp} onClose={(e) => setTooManyExp(false)}>
            <Alert onClose={(e) => setTooManyExp(false)} severity="error">
              {"There is a limit on the number of experiences you can put."}
            </Alert>
          </Snackbar>

          <div className="joinButtonDiv">
            <button type="submit" className="joinButton"
            // onClick={signup}
            >
              Apply
            </button>
          </div>
        </form>
      </div>
      <div className="coachSignUpDisclaimer">
        <p>
          This opportunity is for an independent contractor. Income earned will
          vary depending on session rate and the number of sessions booked.
        </p>
      </div>
      <Dialog
        onClose={() => setShowCoachingAgreement(false)}
        open={showCoachingAgreement}
        fullWidth={true}
      >
        <DialogTitle
          disableTypography
          className="coachAgreementDialogTitle"
          onClose={() => setShowCoachingAgreement(false)}
        >
          <span></span>
          <IconButton onClick={() => setShowCoachingAgreement(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <iframe
            title="coach agreement"
            width="100%"
            style={{ border: "0" }}
            src="/coach-agreement"
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withRouter(ApplyToCoachForm);
