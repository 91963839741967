import React from 'react';

import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsGolfIcon from '@mui/icons-material/SportsGolf';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import PoolIcon from '@mui/icons-material/Pool';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import { FitnessCenter, InsertEmoticon } from '@material-ui/icons';

export const sports = [
    { label: "Basketball", value: "basketball", icon: <SportsBasketballIcon /> },
    { label: "Soccer", value: "soccer", icon: <SportsSoccerIcon /> },
    { label: "Volleyball", value: "volleyball", icon: <SportsVolleyballIcon /> },
    { label: "Tennis", value: "tennis", icon: <SportsTennisIcon /> },
    { label: "Golf", value: "golf", icon: <SportsGolfIcon /> },
    { label: "Pickleball", value: "pickleball", icon: <SportsTennisIcon /> },
    { label: "Ice Hockey", value: "ice-hockey", icon: <SportsHockeyIcon /> },
    { label: "Swimming", value: "swimming", icon: <PoolIcon /> },
    { label: "Field Hockey", value: "field-hockey", icon: <SportsHockeyIcon /> },
    { label: "Lacrosse", value: "lacrosse", icon: <SportsHockeyIcon /> },
    { label: "Baseball", value: "baseball", icon: <SportsBaseballIcon /> },
    { label: "Softball", value: "softball", icon: <SportsFootballIcon /> },
    { label: "Football", value: "football", icon: <SportsFootballIcon /> },
    { label: "Track & Field", value: "track-and-field", icon: <SportsHandballIcon /> },
    { label: "Strength & Speed", value: "strength-and-speed", icon: <FitnessCenter /> },
    { label: "Mental Performance", value: "mental-performance", icon: <InsertEmoticon /> },
];

