import React  from 'react';
import ResetPassword from '../ResetPassword/ResetPassword';
import { withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import '../ResetPassword/ResetPassword.css';
import VerifyEmail from "../VerifyEmail/VerifyEmail";


const Actions = props => {
    const mode = qs.parse(props.location.search)['mode'];
    const actionCode = qs.parse(props.location.search)['oobCode'];

    const takeToHome = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        props.history.push({
        pathname: '/',
        });
    };



    switch (mode) {
        // case 'recoverEmail':
        //     // Display email recovery handler and UI.
        //     return <RecoverEmail actionCode={actionCode} />;
        case 'resetPassword':
            // Display reset password handler and UI.
            return <ResetPassword actionCode={actionCode} />;
        case 'verifyEmail':
            // Display email verification handler and UI.
            return <VerifyEmail actionCode={actionCode} />;
        default:
            // Error: invalid mode.
            return (
                <div className="ResetPasswordError">
                    <p>The page has expired.</p>
                    <p>Click <span onClick={e => takeToHome(e)}>here</span> to head back to the home page.</p>
                </div>
            );
    }
}

export default withRouter(Actions);