import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../screens/App/App";
import { createMessage, getAthleteCoaches, getMessages } from '../../api/api';
import { checkMessageForSensitiveInfo } from "../../utils/shared";
import './CoachInbox.scss';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import Button from "@material-ui/core/Button";
import blank_avatar from "../CoachProfile/avatar.png";
import { CLOUDFRONT_UPLOADS } from "../../constants/hosts";
import SearchForCoaches from "../SearchForCoaches/SearchForCoaches";
import { useHistory } from "react-router-dom";
import { getTag } from "@testing-library/jest-dom/dist/utils";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}
const BLOCKED_FROM_MESSAGING_MESSAGE = "Your account is temporarily restricted due to activities that don't align with our community guidelines. This means you can’t send messages right now. Please contact our support team to regain access: community@athletesuntapped.com"

const CoachInbox = (props) => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [messages, setMessages] = useState({});
  const [, setRelatedUsers] = useState([]);
  const [conversations, setConversations] = useState({});
  const [orderedConversationKeys, setOrderedConversationKeys] = useState([]);
  const [currentConv, setCurrentConv] = useState("");
  const [messageToSend, setMessageToSend] = useState("");
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [messagesEnd, setMessagesEnd] = useState(null);
  const [sendDisabled, setSendDisabled] = useState(false);
  const [quickMessage, setQuickMessage] = useState(null);
  const [messagingBlocked, setMessagingBlocked] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [idToken, setIdToken] = useState(null); // New state for storing the ID token

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (messagesEnd !== null) {
      messagesEnd.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentConv, messages, messagesEnd]);

  useEffect(() => {
    user.firebaseUser.getIdToken().then(setIdToken);
    console.log('id token being set')
  }, [user.firebaseUser]);

  useEffect(() => {
    if (idToken) {
      getMessages(idToken, user.firebaseUser.uid)
        .then(response => {
          setMessages(response.messages);
          setConversations(response.conversations);
          const sorted = Object.keys(response.conversations).sort((a, b) => {
            const aTime = response.messages[a][response.messages[a].length - 1]['time'];
            const bTime = response.messages[b][response.messages[b].length - 1]['time'];
            return bTime - aTime;
          });
          setOrderedConversationKeys(sorted);
          setCurrentConv(sorted[0]);
        })
        .catch(error => console.log(error));
    }
  }, [idToken, user.firebaseUser.uid]);

  useEffect(() => {
    if (idToken && user.profile.profileType !== 'coach') {
      getAthleteCoaches(idToken, user.firebaseUser.uid)
        .then(response => {
          setRelatedUsers(user.profile.profileType !== 'coach' ? response['coaches'] : response['clients']);
        })
        .catch(error => console.log(error));
    }
  }, [idToken, user.firebaseUser.uid, user.profile.profileType]);

  useEffect(() => {
    if (user.profile?.messaging_blocked) {
      setMessagingBlocked(user.profile.messaging_blocked);
    }
  }, [user.profile?.messaging_blocked]);

  const getAllMessages = (id) => {
    if (idToken) {
      getMessages(idToken, id)
        .then(response => {
          setMessages(response.messages);
          setConversations(response.conversations);
        })
        .catch(error => console.log(error));
    }
  };

  const sendMessage = async (e, message) => {
    e.preventDefault();
    try {
      checkMessageForSensitiveInfo(message);
    } catch (e) {
      if (user?.profile?.profileType === 'coach') {
        setWarning("Friendly reminder - Part of your AU coach contract includes keeping communication & bookings on our platform. Your message was blocked for potentially sensitive information. If you need assistance, contact our support team at community@athletesuntapped.com");
      } else {
        setWarning("Hey there! All communication & bookings are required to stay on our platform. Your message was flagged for potentially sensitive information. If you need assistance, contact our support team at community@athletesuntapped.com.");
      }
      setShowWarningAlert(true);
      return;
    }
    setSendDisabled(true);

    getTag('event', 'message_sent');
    getTag('event', 'conversion');
    
    const messageToSend = {
      "to": currentConv,
      "from": user.firebaseUser.uid,
      "content": message,
      "time": Date.now() // number of milliseconds since Jan 1 1970 UTC
    };

    try {
      await createMessage(idToken, messageToSend);
      setOpenSnackBar(true);
      setMessageToSend("");
      getAllMessages(user.firebaseUser.uid);
    } catch (e) {
      console.log('error: ', e);
      console.log('compare', e === "Messaging not allowed for this user");
      if (e === "Messaging not allowed for this user") {
        setError(BLOCKED_FROM_MESSAGING_MESSAGE);
        setMessagingBlocked(true);
      } else {
        setError("An error occurred while sending your message. Please try again later.");
      }
    } finally {
      setSendDisabled(false);
    }
  };

  const getDisplayName = (conversation, msg) => {
    if (msg['from'] !== user.firebaseUser.uid) {
      return getCorrectedName(conversation);
    } else {
      return getCorrectedName(user.profile);
    }
  };

  const getCorrectedName = (profile) => {
    if (profile.parentFirstName) {
      return profile['parentFirstName'] + " " + profile['parentLastName'];
    } else {
      return profile['firstName'] + " " + profile['lastName'];
    }
  };

  const copyProfileLinkToClipboard = () => {
    let url = window.location.protocol + '//' + window.location.hostname + '/book-session/' + (user.profile.customUrl ? user.profile.customUrl : user.firebaseUser.uid);
    navigator.clipboard.writeText(url);
    setQuickMessage("Booking link copied to clipboard! Paste & send to your client.");
  };

  const renderConversationSelector = () => {
    return (
      <Select
        value={currentConv}
        onChange={(e) => setCurrentConv(e.target.value)}
        className="mobile-conversation-selector"
        renderValue={(selected) => `Current Conversation: ${getCorrectedName(conversations[selected])}`}
      >
        {orderedConversationKeys.map((item) => (
          <MenuItem key={item} value={item}>
            {getCorrectedName(conversations[item])}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <div className="inbox">
      {Object.keys(messages).length === 0 ?
        user.profile.profileType !== 'coach' ?
          <div className="lookingForNew">
            <p>Once you message an AU coach, your correspondence will appear here!</p>
            <SearchForCoaches />
          </div>
          :
          <div className="lookingForNew">
            <p>Once clients initially reach out to you, you will be able to message them here!</p>
          </div>
        :
        <div>
          <div className="CoachInboxBody">
            {isMobile ? 
              renderConversationSelector() 
              : 
              ( 
                <div className="CoachInboxConversations">
                  {orderedConversationKeys.map((item, i) => (
                    <div
                      className={`messageListItem ${conversations[currentConv] === conversations[item] ? 'selected' : ''}`}
                      onClick={e => {
                        setCurrentConv(item);
                      }}>
                      <div className="items">
                        <div className="left">
                          <span className="name">{getCorrectedName(conversations[item])}</span>
                          <div className="message">
                            {messages[item][messages[item].length - 1]['content']}
                          </div>
                        </div>
                        <div
                          className="date">{new Date(messages[item][messages[item].length - 1]['time']).toLocaleString('default', { dateStyle: 'medium' })}</div>
                        <div className="rightCover"></div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div className="CoachInboxMessageWindow">
              <div className="buttonBox">
                {user.profile.profileType === 'coach' ?
                  <Button className="buttonPrimary" onClick={() => copyProfileLinkToClipboard()}>Send Your Booking Link!</Button>
                  :
                  <Button className="buttonPrimary" onClick={() => history.push('/book-session/' + (conversations[currentConv].customUrl ? conversations[currentConv].customUrl : currentConv))}>Book More Sessions!</Button>
                }
              </div>
              <div className="warningBoxContainer">
                <div className="warningBox">To protect your payment, always book and pay through the
                  AU platform.
                </div>
              </div>
              {(currentConv !== "") ? (messages[currentConv].map((msg, i) =>
              (
                <Paper
                  square
                  key={i}
                  elevation={0}
                  className="CoachInboxMessageContent">
                  {(i === 0 || (new Date(msg['time']).getUTCDay() !== new Date(messages[currentConv][i - 1]['time']).getUTCDay()))
                    ? <p className="CoachInboxDateSeparatorText">
                      {new Date(msg['time']).toDateString()}
                    </p>
                    : null}
                  <div className="messageInner">
                    {(msg['from'] !== user.firebaseUser.uid && conversations[currentConv]['profileImage']) || (msg['from'] === user.firebaseUser.uid && user.profile['profileImage']) ?
                      <img
                        src={CLOUDFRONT_UPLOADS + (msg['from'] !== user.firebaseUser.uid ? conversations[currentConv]['profileImage'] : user.profile['profileImage'])}
                        alt="Profile" className="userAvatar" />
                      :
                      <img src={blank_avatar} alt="Blank Avatar" className="userAvatar" />
                    }
                    <div className="messageRight">
                      <div className="CoachInboxMessageContentNameandDate">
                        <p className="CoachInboxMessageContentName">
                          {getDisplayName(conversations[currentConv], msg)}
                        </p>
                        <p className="CoachInboxMessageContentDate">
                          {new Date(msg['time']).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </p>
                      </div>
                      <div className="text-thin theMessage">{msg['content']}</div>
                    </div>
                  </div>
                </Paper>
              ))) : null}
              <div style={{ float: "left", clear: "both" }}
                ref={(el) => {
                  setMessagesEnd(el);
                }}></div>
            </div>
            {currentConv !== "" ?
              <div className="CoachInboxMessageToSend">
                <textarea
                  placeholder={messagingBlocked ? BLOCKED_FROM_MESSAGING_MESSAGE : "Type a message..."}
                  disabled={messagingBlocked}
                  value={messageToSend}
                  style={ messagingBlocked ? { backgroundColor: '#f0f0f0', fontSize: '12px', fontWeight: 'normal', height: '125px' } : {}}
                  onChange={e => setMessageToSend(e.target.value)}>
                </textarea>
                <Button
                  disabled={sendDisabled || messagingBlocked}
                  className="desktop buttonPrimary sendButton"
                  onClick={e => sendMessage(e, messageToSend)}
                >
                  Send
                </Button>
                <IconButton
                  disabled={sendDisabled || messagingBlocked}
                  className="mobile buttonPrimary sendButton"
                  onClick={e => sendMessage(e, messageToSend)}
                >
                  <SendIcon />
                </IconButton>
              </div>
              : null}
              <Snackbar open={openSnackbar}
                onClose={e => setOpenSnackBar(false)}
              >
                <Alert onClose={e => setOpenSnackBar(false)} severity="success">
                  Message Sent!
                </Alert>
              </Snackbar>
              <Snackbar open={error}
                onClose={e => setError(null)}
              >
                <Alert onClose={e => setError(null)} severity="error">
                  {error}
                </Alert>
              </Snackbar>
          </div>
        </div>
      }
      <Snackbar open={quickMessage} autoHideDuration={5000}
        onClose={e => setQuickMessage(null)}>
        <Alert onClose={e => setQuickMessage(null)} severity="success">
          {quickMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={showWarningAlert} autoHideDuration={5000}>
        <Alert onClose={e => setShowWarningAlert(false)} severity="warning">
          {warning}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CoachInbox;
