import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from "@material-ui/core";
import { FileCopyRounded } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import "./CopyField.scss";

export default function CopyField(props) {
  const { value, label } = props;
  const inputRef = useRef();
  const [tooltip, setTooltip] = useState("Copy");

  return (
    <FormControl
      style={{ flexBasis: "100%" }}
      margin="dense"
      variant="outlined"
    >
      <InputLabel htmlFor="custom-pricing-url">{label}</InputLabel>
      <OutlinedInput
        id="custom-pricing-url"
        onFocus={(e) => e.target.select()}
        inputRef={inputRef}
        value={value}
        type="text"
        margin="dense"
        readOnly
        inputProps={{
          style: {
            boxShadow: "none",
            maxWidth: "none",
          },
        }}
        labelWidth={150}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              style={{
                boxShadow: "none",
              }}
              edge="end"
              onClick={() => {
                navigator.clipboard.writeText(value);
                setTooltip("Copied!");
                inputRef.current?.select();
              }}
            >
              <Tooltip title={tooltip} placement="bottom">
                <FileCopyRounded id="copy-icon" />
              </Tooltip>
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
