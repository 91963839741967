import React from 'react';
import './OurTeam.css';

const OurTeam = props => {
    return (
        <div>
            <div className="OurTeamTitle">
                <p>Our Team</p>
            </div>
            
            
        </div>
        
    );
}

export default OurTeam;
