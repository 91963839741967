import React from "react";
import BottomNav from "../BottomNav/BottomNav";
import './Partnerships.scss';
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {CLOUDFRONT_UPLOADS} from "../../constants/hosts";


const Partnerships = () => {
  const history = useHistory();


  return (
    <div>
      <div className="partnerships">
        <div className="topBox topBoxWithForm">
          <h1>Partnerships</h1>
        </div>
        <div className="contentWrapper">
          <div className="mainContent">
            <div className="topContent">
              Transforming the youth sports industry can't be done alone. Whether it's us lending a hand or others helping us, our partners also aim to provide young athletes with better opportunities to succeed.
            </div>
            <h2>Champion Partner</h2>
            <div className="champion">
              <img alt="" src={CLOUDFRONT_UPLOADS + "static/every-kid-sports.jpg"}/>
              <div className="championContent">
                <p>
                  Kids across the nation are suffering without their sports. The need for youth sports financial support coming out of the pandemic will be greater than ever. Every Kid Sports and their “We pay. Kids play.” national campaign intends to generate the resources necessary to get kids
                  from
                  low-income families reconnecting, reengaging, and recovering through participation in youth sports.
                </p>
                <p>
                  Every Kid Sports is a 501(c)(3) non-profit
                  organization that pays youth sports registration fees for kids from
                  low-income families. Athletes Untapped donates 1% of annual profits to support their cause of getting kids back in the game.
                </p>
              </div>
            </div>
            <h2>All-Star Partners</h2>
            <div className="allStars">
              <a href="https://www.certn.co"><img alt="" src={CLOUDFRONT_UPLOADS + "static/certn-logo.jpg"}/></a>
              <a href="https://www.stripe.com"><img alt="" src={CLOUDFRONT_UPLOADS + "static/stripe-logo.jpg"}/></a>
              <a href="https://www.enetwebservices.com"><img alt="eNet Web Services" src={CLOUDFRONT_UPLOADS + "static/enet-logo.jpg"}/></a>
            </div>
          </div>
          <div className="getStartedBox">
            <h3>Get Involved</h3>
            <div className="buttonContainer">
              <Button className="buttonPrimary" onClick={() => history.push('/coaches')}>Find coaches</Button>
              <Button className="buttonPrimary" onClick={() => history.push({
                pathname: '/apply-to-coach',
                state: {forAthlete: false}
              })}>Apply to coach</Button>
            </div>
          </div>
        </div>
      </div>
      <BottomNav/>
    </div>
  );
};

export default Partnerships;