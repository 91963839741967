import { Box, Button, debounce, MenuItem } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import {
  confirmPaymentIntent,
  createMessage,
  createPaymentMethod,
  getPaymentMethods,
  modifyPaymentIntent,
} from "../../api/api";
import { CLOUDFRONT_UPLOADS } from "../../constants/hosts";
import { calculateSessionRateWithAdditionalAthletes } from "../../utils/shared";
import { useAuth } from "../../screens/App/App";
import blank_avatar from "../AthleteMessageCoach/avatar.png";
import BookingBoxScroller from "../BookingBoxScroller/BookingBoxScroller";
import BookingBoxScrollerForCustomPricingPackage from "../BookingBoxScroller/BookingBoxScrollerForCustomPricingPackage";
import { default as logo } from "../HomeNav/AULogoSmall.png";
import LoginSignupDialog from "../LoginSignupDialog/LoginSignupDialog";
import "./BookASession.scss";

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

const debounceModifyPaymentIntent = debounce(
  ({ user, total, paymentID, referralCode }) => {
    user.firebaseUser.getIdToken().then(function (idToken) {
      modifyPaymentIntent(idToken, {
        total,
        paymentIntent: paymentID,
        referralCode,
      });
    });
  },
  500
);

const BookASession = (props) => {
  const { user } = useAuth();

  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackBar] = useState("");
  const [numSessions, setNumSessions] = useState(
    props.numSessions ? props.numSessions : 5
  );
  const [numAthletes, setNumAthletes] = useState(
    props.numAthletes ? props.numAthletes : 1
  );
  const [showModal, setShowModal] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [
    sessionRateWithAdditionalAthletes,
    setSessionRateWithAdditionalAthletes,
  ] = useState(
    props.sessionRateWithAdditionalAthletes ?? props.coach.sessionRate
  );

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [listOfPaymentMethods, setListOfPaymentMethods] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState("new-card");
  const [nameOnCard, setNameOnCard] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const didMount = useRef(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    const effectiveSessionRate = calculateSessionRateWithAdditionalAthletes({
      additionalAthletesRateString: props.coach.additionalAthletesRate,
      sessionRateString: props.coach.sessionRate,
      numAthletes,
    });
    setSessionRateWithAdditionalAthletes(effectiveSessionRate);

    const total = effectiveSessionRate * numSessions;
    if (total === 0) {
      return;
    }
    if (!user || !user.firebaseUser || props.customPricingPackage) {
      return;
    }

    debounceModifyPaymentIntent({
      user,
      total,
      paymentID: props.paymentID,
      referralCode,
    });
  }, [
    numSessions,
    numAthletes,
    props.coach.sessionRate,
    props.coach.additionalAthletesRate,
    user,
    referralCode,
    props.paymentID,
    props.customPricingPackage,
  ]);

  useEffect(() => {
    if (!user || !user.firebaseUser) {
      return;
    }
    user.firebaseUser.getIdToken().then(function (idToken) {
      getPaymentMethods(idToken, user.firebaseUser.uid)
        .then((response) => {
          setListOfPaymentMethods(response["paymentMethods"]);
          if (response["paymentMethods"].length > 0) {
            setSelectedCardId(response["paymentMethods"][0]["id"]);
          }
        })
        .catch((e) => console.log(e));
    });
  }, [user]);

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = () => {
    setSaveDisabled(true);
    // let pay_method_id;
    if (!selectedCardId || selectedCardId === "new-card") {
      user.firebaseUser.getIdToken().then(function (idToken) {
        createPaymentMethod(idToken, { id: user.firebaseUser.uid })
          .then((response) => {
            /* const result = */
            stripe
              .confirmCardSetup(response["clientSecret"], {
                payment_method: {
                  card: elements.getElement(CardElement),
                  billing_details: {
                    name: nameOnCard,
                  },
                },
              })
              .then((result) => {
                setProcessing(true);
                finishSubmit(result.setupIntent.payment_method);
              })
              .catch((e) => {
                setError("Error.");
                setProcessing(false);
                setSaveDisabled(false);
                console.log("error", e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      });
    } else {
      setProcessing(true);
      finishSubmit(selectedCardId);
    }
  };

  const finishSubmit = (pay_method_id) => {
    if (!messageContent || messageContent.length === 0) {
      setOpenErrorSnackBar("Please Enter a Message.");
      setProcessing(false);
      setSaveDisabled(false);
      return;
    }

    const messageToSend = {
      to: props.coach.firebaseID,
      from: user.firebaseUser.uid,
      content: messageContent,
      time: Date.now(), // number of milliseconds since Jan 1 1970 UTC
    };

    const { customPricingPackage } = props;
    const pricingInformation = customPricingPackage
      ? {
        numSessions: customPricingPackage.numSessions,
        perSession:
          customPricingPackage.price / customPricingPackage.numSessions,
        total: customPricingPackage.price,
      }
      : {
        numSessions: numSessions,
        perSession: sessionRateWithAdditionalAthletes,
        total: sessionRateWithAdditionalAthletes * numSessions,
      };
    const confirmation = {
      paymentIntent: props.paymentID,
      paymentMethod: pay_method_id,
      athlete: user.firebaseUser.uid,
      coach: props.coach.firebaseID,
      ...pricingInformation,
    };

    user.firebaseUser
      .getIdToken()
      .then((idToken) =>
        confirmPaymentIntent(idToken, confirmation)
          .then(() =>
            createMessage(idToken, messageToSend)
              .then(() => {
                setMessageContent("");
                setError(null);
                setProcessing(false);
                setSaveDisabled(false);
                setSucceeded(true);

                window.dataLayer.push({
                  event: "session_booked",
                  num_sessions: numSessions,
                  session_rate: parseInt(props.coach.sessionRate),
                  value: confirmation["total"],
                  transaction_id: props.paymentID,
                });
              })
              .catch((e) => console.log(e))
          )
          .catch((e) => {
            console.log(e);
            setError("Payment failed.");
            setOpenErrorSnackBar(e);
            setProcessing(false);
            setSaveDisabled(false);
          })
      )
      .catch((e) => {
        setProcessing(false);
        setSaveDisabled(false);
        setOpenErrorSnackBar(e);
        console.log(e);
      });
  };


  const login = () => {
    setShowModal(true);
    setShowSignup(false);
  };

  const signup = () => {
    setShowModal(true);
    setShowSignup(true);
  };

  return (
    <div>
      {processing ? (
        <div
          className="loadingContainer"
          style={{
            marginTop: "5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <div className="loadingText text-large text-bold">
            Booking Session
          </div>
        </div>
      ) : (
        <div className="bookSessionWrapper">
          <div className="left">
            <h2>Contact {props.coach.firstName}</h2>
            <div className="coachInfo">
              {props.coach && props.coach["profileImage"] ? (
                <div
                  className="coachProfileHeaderAvatar"
                  style={{
                    backgroundImage:
                      "url('" +
                      CLOUDFRONT_UPLOADS +
                      props.coach["profileImage"] +
                      "')",
                  }}
                ></div>
              ) : (
                <div
                  className="coachProfileHeaderAvatar"
                  style={{ backgroundImage: "url('" + blank_avatar + "')" }}
                ></div>
              )}
              <div>
                <span className="text-bold">
                  {props.coach.firstName} {props.coach.lastName.substr(0, 1)}
                </span>
                <br />
                {props.coach.locationInfo.city},{" "}
                {props.coach.locationInfo.state}
                <br />
                <span className="uc-words">
                  Private {props.coach.sport.join(", ")} Coach
                </span>
              </div>
            </div>
            <div
              className={
                "messageBookContainer " +
                (!user ? "messageBookContainerPadded" : "")
              }
            >
              {!user ? (
                <div className="leftLoginOverlay">
                  <Button className="buttonPrimary" onClick={login}>
                    Log in to Book
                  </Button>
                  <span
                    className="link text-small signup-link"
                    onClick={signup}
                  >
                    Create an Account (Free)
                  </span>
                </div>
              ) : null}
              <div className="message">
                <h4>Pay With</h4>
                <div>
                  <div>
                    <TextField
                      name="paymentMethod"
                      variant="outlined"
                      select
                      label="Payment Method"
                      value={selectedCardId}
                      onChange={(e) => setSelectedCardId(e.target.value)}
                      SelectProps={{
                        style: { minWidth: "15ch", boxShadow: "none" },
                      }}
                    >
                      {listOfPaymentMethods.map((method, index) => {
                        return (
                          <MenuItem key={index} value={method["id"]}>
                            {method["card"]["brand"]} - {" ************"}
                            {method["card"]["last4"]}
                          </MenuItem>
                        );
                      })}
                      <MenuItem value="new-card">Add New Card</MenuItem>
                    </TextField>
                    {selectedCardId === "new-card" ? (
                      <div>
                        <div>
                          <input
                            className="AthletePaymentSettingsNameOnCard"
                            type="text"
                            value={nameOnCard}
                            placeholder={"Name on Card"}
                            onChange={(e) => {
                              setNameOnCard(e.target.value);
                            }}
                          ></input>
                        </div>
                        <CardElement
                          id="card-element"
                          onChange={handleChange}
                        />
                        {/* Show any error that happens when processing the payment */}
                        {error && (
                          <MuiAlert severity="error" className="CardEntryError">
                            {error}
                          </MuiAlert>
                        )}
                      </div>
                    ) : null}
                  </div>

                  <Dialog
                    open={succeeded}
                    onClose={(e) => {
                      setSucceeded(false);
                      window.location = "/dashboard";
                    }}
                  >
                    <DialogContent dividers>
                      <div className="successDialogContent">
                        <img src={logo} alt="Logo" />
                        Thank you for trusting AU! Your payment is being
                        processed and your coach has been notified. They will
                        respond to you shortly. If you have any further
                        questions, feel free to email our team:{" "}
                        <a href="mailto:community@athletesuntapped.com">
                          community@athletesuntapped.com
                        </a>
                        <Button
                          className="buttonPrimary"
                          onClick={() => {
                            setSucceeded(false);
                            window.location = "/calendar";
                          }}
                        >
                          Continue to calendar
                        </Button>
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
                <h6>Message {props.coach.firstName} (required to book)</h6>
                <textarea
                  value={messageContent}
                  onChange={(e) => setMessageContent(e.target.value)}
                ></textarea>
                <h6>Referral/Discount Code (optional)</h6>
                <TextField
                  name="referralCode"
                  label="Referral Code"
                  type="text"
                  value={referralCode}
                  onChange={(e) => setReferralCode(e.target.value)}
                  variant="outlined"
                />
                <Snackbar
                  open={openSnackbar}
                  onClose={(e) => setOpenSnackBar(false)}
                >
                  <Alert
                    onClose={(e) => setOpenSnackBar(false)}
                    severity="success"
                  >
                    Message Sent!
                  </Alert>
                </Snackbar>
              </div>

              <Box paddingBottom={4}>
                <h6>Scheduling Your Sessions</h6>
                <span className="text-large">
                  Once you book training sessions with a coach, be sure to
                  coordinate the date/time for each session on your calendar.
                  This is how your coach gets paid.
                </span>
              </Box>
              {/* Something is up with general availability. This does not work */}
              {/* <div className="availabilityWrapper">
                <div className="availability">
                  {props.coach.generalAvailability ? (
                    <div>
                      <h6>Coach Availability</h6>
                      {props.coach.generalAvailability.mornings ||
                      props.coach.generalAvailability.daytime ||
                      props.coach.generalAvailability.evenings ? (
                        <p
                          className="text-large text-bold"
                          style={{ margin: "1rem 0rem" }}
                        >
                          Monday - Friday
                        </p>
                      ) : null}
                      {props.coach.generalAvailability.mornings ? (
                        <p className="BookSessionAvailabilityFont">
                          Mornings:{" "}
                          {new Date(props.coach.mornings.From).toLocaleString(
                            "en-US",
                            {
                              hour: "numeric",
                              hour12: true,
                            }
                          )}
                          {" - "}
                          {new Date(props.coach.mornings.To).toLocaleString(
                            "en-US",
                            {
                              hour: "numeric",
                              hour12: true,
                            }
                          )}
                        </p>
                      ) : null}
                      {props.coach.generalAvailability.daytime ? (
                        <p className="BookSessionAvailabilityFont">
                          Daytime:{" "}
                          {new Date(props.coach.daytime.From).toLocaleString(
                            "en-US",
                            {
                              hour: "numeric",
                              hour12: true,
                            }
                          )}
                          {" - "}
                          {new Date(props.coach.daytime.To).toLocaleString(
                            "en-US",
                            {
                              hour: "numeric",
                              hour12: true,
                            }
                          )}
                        </p>
                      ) : null}
                      {props.coach.generalAvailability.evenings ? (
                        <p className="BookSessionAvailabilityFont">
                          Evenings:{" "}
                          {new Date(props.coach.evenings.From).toLocaleString(
                            "en-US",
                            {
                              hour: "numeric",
                              hour12: true,
                            }
                          )}
                          {" - "}
                          {new Date(props.coach.evenings.To).toLocaleString(
                            "en-US",
                            {
                              hour: "numeric",
                              hour12: true,
                            }
                          )}
                        </p>
                      ) : null}
                      {props.coach.generalAvailability.weekends ? (
                        <p className="text-large text-bold">Weekends</p>
                      ) : null}
                      {props.coach.generalAvailability.weekends ? (
                        <p className="BookSessionAvailabilityFont">
                          {new Date(props.coach.weekends.From).toLocaleString(
                            "en-US",
                            {
                              hour: "numeric",
                              hour12: true,
                            }
                          )}
                          {" - "}
                          {new Date(props.coach.weekends.To).toLocaleString(
                            "en-US",
                            {
                              hour: "numeric",
                              hour12: true,
                            }
                          )}
                        </p>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div> */}

              {processing ? (
                <CircularProgress />
              ) : (
                <Box display="flex" justifyContent="center" paddingBottom={1}>
                  <Button
                    disabled={saveDisabled}
                    className="buttonPrimary"
                    onClick={handleSubmit}
                    size="large"
                  >
                    Confirm and Pay
                  </Button>
                </Box>
              )}
              <Box display="Flex" justifyContent="center">
                <em className="text-large text-thin text-center">
                  By confirming and paying, you agree to our{" "}
                  <Link className="link" to="/privacy-policy">
                    privacy policy
                  </Link>
                  ,{" "}
                  <Link className="link" to="/site-terms">
                    site terms
                  </Link>
                  , and to pay the total amount shown.
                </em>
              </Box>
            </div>
          </div>
          <div className="right">
            {props.customPricingPackage ? (
              <BookingBoxScrollerForCustomPricingPackage
                customPricingPackage={props.customPricingPackage}
                coach={props.coach}
              />
            ) : (
              <BookingBoxScroller
                additionalAthletesRate={props.coach.additionalAthletesRate}
                numAthletes={numAthletes}
                numSessions={numSessions}
                sessionRate={sessionRateWithAdditionalAthletes}
                setNumAthletes={setNumAthletes}
                setNumSessions={setNumSessions}
              />
            )}
          </div>
        </div>
      )}
      <Snackbar open={openSnackbar} onClose={(e) => setOpenSnackBar(false)}>
        <Alert onClose={(e) => setOpenSnackBar(false)} severity="success">
          Session Reserved!
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!openErrorSnackbar}
        onClose={(e) => setOpenErrorSnackBar("")}
      >
        <Alert onClose={(e) => setOpenErrorSnackBar("")} severity="error">
          {openErrorSnackbar}
        </Alert>
      </Snackbar>
      <LoginSignupDialog
        show={showModal}
        close={() => setShowModal(false)}
        showSignup={showSignup}
        setShowSignup={setShowSignup}
        returnAfterLogin={true}
      />
    </div>
  );
};

export default BookASession;
