import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { sports } from "src/constants/sports";


function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

function validateZip(zip) {
  const re = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return re.test(String(zip).toLowerCase());
}

const textFieldColor = "green";
const textFieldColorNormal = "lightslategray";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "max-content",
    display: "flex",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      minWidth: "25ch",

      "& label.Mui-focused": {
        color: textFieldColor,
        fontFamily:
          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
      },
      "& label": {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: textFieldColor,
      },
      "& .MuiOutlinedInput-root": {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
        "& fieldset": {
          borderColor: textFieldColorNormal,
          borderRadius: "0",
          borderWidth: "1.2px",
        },
        "&:hover fieldset": {
          borderColor: textFieldColor,
          borderWidth: "2px",
        },
        "&.Mui-focused fieldset": {
          borderColor: textFieldColor,
          borderWidth: "2px",
        },
      },
    },
    "& > .MuiAutocomplete-root, & .MuiAutocomplete-inputRoot": {
      padding: 0,
      // backgroundColor: "aqua",
    },
    "& .MuiAutocomplete-input": {
      boxShadow: "none",
    },
    "& .MuiAutocomplete-option": {
      fontSize: "2.5rem",
    },
    "& .MuiAutocomplete-option:hover": {
      backgroundColor: "aqua",
    },
    "& .MuiAutocomplete-optionSelected": {
      backgroundColor: "black",
    },
    "& .MuiAutocomplete-optionSelected:hover": {
      backgroundColor: "aqua",
    },
    "& .MuiAutocomplete-clearIndicator": {
      fontSize: "0.5rem",
      boxShadow: "none",
    },
    "& .MuiAutocomplete-hasClearIcon": {
      // backgroundColor: "aquamarine",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "25px !important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      size: "0.75rem",
      boxShadow: "none",
    },
    "& .MuiInputBase-root": {
      backgroundColor: "white",
    },
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: "30ch",
  },
  p: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(1),
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
  },
  label: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
  },
}));

const ReadyToTrainForm = (props) => {
  const [sport, setSport] = useState("");
  const [zip, setZip] = useState("");
  const [searchErrorSnackBar, setSearchErrorSnackBar] = useState(false);
  const [searchErrorSnackBarMessage, setSearchErrorSnackBarMessage] =
    useState("");

  const handleForm = (e) => {
    e.preventDefault();

    if (!zip || zip === "" || !validateZip(zip)) {
      setSearchErrorSnackBarMessage("Please enter a valid 5-digit zip code.");
      setSearchErrorSnackBar(true);
      return;
    }

    props.history.push({
      pathname: "/coaches",
      search: "?zip=" + zip + "&sport=" + sport + "&radius=50",
      state: { sport: sport, zipCode: zip },
    });
  };

  const takeToBecomeCoach = (e) => {
    e.preventDefault();
    props.history.push({
      pathname: "/join",
      state: { forAthlete: false, sport: sport, zipCode: zip },
    });
  };

  const MUIclasses = useStyles();
  let submitFunc = takeToBecomeCoach;
  if (
    props.buttonText === "Find Your Coach" ||
    props.readyToTrain === "Find Your Private Coach"
  ) {
    submitFunc = (e) => handleForm(e);
  }

  return (
    <form onSubmit={submitFunc} className="formControl">
      {props.readyToTrain === "Find Your Private Coach" ? (
        <div className="findYourPrivateCoachHeader">
          <label>{props.readyToTrain}</label>
        </div>
      ) : (
        <label className="readyToTrainText">{props.readyToTrain}</label>
      )}
      <form className={MUIclasses.root}>

        <Autocomplete
        sx={{ width: 200 }}
        selectOnFocus
        autoHighlight={true}
        // autoSelect={true}
        blurOnSelect={true}
        options={sports}
        getOptionLabel={(option) => option.label}
        onChange={(event, newValue) => setSport(newValue ? newValue.value : '')}
        renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <span style={{fontWeight : 100, fontFamily: 'Roboto, Helvetica, Arial, sans-serif'}}>
              {option.label} 
              </span>
            </Box>
          )}
        renderInput={(params) => (
          <TextField
            {...params}
            value={sport}
            name="sport"
            type="sport"
            label="Sport"
            variant="outlined"
          />
        )}
      />

        <TextField
          className={MUIclasses.textField}
          onChange={(e) => setZip(e.target.value)}
          name="zip"
          value={zip}
          type="zip"
          label="Zip Code"
          variant="outlined"
          inputProps={{ style: { boxShadow: "none" } }}
        />
      </form>
      <div className="findCoachButtonDiv">
        <Button className="buttonPrimary" type="submit">
          {props.buttonText}
        </Button>
      </div>

      <Snackbar
        open={searchErrorSnackBar}
        onClose={(e) => setSearchErrorSnackBar(false)}
      >
        <Alert onClose={(e) => setSearchErrorSnackBar(false)} severity="error">
          {searchErrorSnackBarMessage}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default withRouter(ReadyToTrainForm);
