const Colors = {
  primary: '#222222',
  primaryLight: '#444',
  // secondary: '#5BAD50',
  secondary: 'darkgreen', // Basically primary, this is the main color of the app
  background: '#fff',
  foreground: '#000',
  gray: '#b8b8b8',
  negative: '#cc0000',
  backgroundLight: '#f0f0f0', // For hover/active states
};
module.exports = Colors;