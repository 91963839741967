import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../screens/App/App";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import './CoachVideosSessionPanel.scss';
import {awsPresignedGet, updateVideoComments} from '../../api/api';
import {CircularProgress, Dialog} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import {createTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ReactPlayer from 'react-player/';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";


const spinnerTheme = createTheme({
  palette: {
    primary: {
      main: '#006400'
    }
  }
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    elevation: 0
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiDialogContent);

//   const DialogActions = withStyles((theme) => ({
//       root: {
//           margin: 0,
//           padding: theme.spacing(1),
//       },
//   }))(MuiDialogActions);

const getThumbnails = (setShowPanel, setIsLoading, getPresignedUrl, session, user, setThumbnails, setCurrentVid, setCurrentVidID) => {
  setShowPanel(true);
  setIsLoading(true);
  if (session['videos']) {
    getPresignedUrl(session['videos'], 'thumbnails', user, setThumbnails, setIsLoading, setCurrentVid, setCurrentVidID);
  } else {
    setIsLoading(false);
    setThumbnails([]);
  }
};

const getPresignedUrl = (videoIDs, contentDesired, user, setThumbnails, setIsLoading, setCurrentVid, setCurrentVidID) => {
  const data = {
    'videoIDs': videoIDs,
    'contentDesired': contentDesired
  };
  user.firebaseUser.getIdToken()
    .then(function (idToken) {
      awsPresignedGet(idToken, data)
        .then(response => {
          console.log(response);
          if (contentDesired === "thumbnails" || contentDesired === "thumbnail") {
            setThumbnails(response);
            setIsLoading(false);
            console.log(response);
          } else {
            setCurrentVid(response[videoIDs[0]]);
            setCurrentVidID(videoIDs[0]);
          }

        })
        .catch(e => console.log(e));
    });
};

const CoachVideosSessionPanel = props => {

  const {user} = useContext(AuthContext);
  const [showPanel, setShowPanel] = useState(!!props.autoExpand);
  const [thumbnails, setThumbnails] = useState(null);
  const [currentVid, setCurrentVid] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentVidID, setCurrentVidID] = useState(null);
  const [commentToAdd, setCommentToAdd] = useState("");
  const [messagesEnd, setMessagesEnd] = useState(null);

  console.log('auto expand', !!props.autoExpand);

  const handleDialogClose = () => {
    setShowDialog(false);
  };


  useEffect(() => {
    if (messagesEnd !== null) {
      messagesEnd.scrollIntoView({behavior: 'smooth'});
    }
  }, [messagesEnd, commentToAdd]);

  useEffect(() => {
    if (props.autoExpand) {
      getThumbnails(setShowPanel, setIsLoading, getPresignedUrl, props.session, user, setThumbnails, setCurrentVid, setCurrentVidID);
    }
  }, [props.session, props.autoExpand, user, user.firebaseUser]);

  const loadVideos = (e, videoID) => {
    e.preventDefault();
    setShowDialog(true);
    if (props.session['videos']) {
      getPresignedUrl([videoID], 'videos', user, setThumbnails, setIsLoading, setCurrentVid, setCurrentVidID);
    }
  };

  const addVideoComment = e => {
    e.preventDefault();
    let comment = {
      'content': commentToAdd,
      'to': props.clientID,
      'from': user.firebaseUser.uid,
      'time': Date.now()
    };
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        updateVideoComments(idToken, currentVidID, comment)
          .then(response => {
            setThumbnails({
              ...thumbnails,
              [currentVidID]: {
                ...thumbnails[currentVidID],
                'metadata': {
                  ...thumbnails[currentVidID]['metadata'],
                  'videoComments': response['comments']
                }
              }
            });
            setCurrentVid({
              ...currentVid,
              'metadata': {
                ...currentVid['metadata'],
                'videoComments': response['comments']
              }
            });
            setCommentToAdd("");
          })
          .catch(e => console.log(e));
      });
  };

  // const getFromAWS = async (url, contentDesired) => {
  //     let contentType;
  //     if (contentDesired === "thumbnails") {
  //         contentType = 'image/jpg';
  //     } else {
  //         contentType = 'video/mp4';
  //     }
  //     const response = await fetch(
  //         new Request(url, {
  //           method: 'GET',
  //           headers: new Headers({
  //             'Content-Type': contentType,
  //           }),
  //         }),
  //       );
  //       if (response.status !== 200) {
  //         // The upload failed, so let's notify the caller.
  //         console.log(response)
  //       } else {
  //           console.log(response)
  //           response.blob()
  //           .then(async blob => {
  //             return await URL.createObjectURL(blob);
  //           })

  //       }
  // }
  console.log('client', props.client);
  const sessionFormattedDate = new Date(props.session['startTime']).toLocaleDateString() + ' - ' + new Date(props.session['startTime']).toLocaleString([], {timeStyle: 'short'});
  return (
    <div className="CoachVideosSessionPanel">
      <div className="timelineBox">
        <div className="timelineDot"></div>
      </div>
      <div className="sessionPanelRight">
        <div className="CoachVideosSessionPanelHeader">
          <div className="titleWrapper">
                <span className="CoachVideosSessionPanelHeaderTitle">
                    {props.client && props.client['profileType'] === 'athlete' ? 'Session with ' + props.client.firstName + ' ' + props.client.lastName + ' on ' + sessionFormattedDate : ''}
                  {props.client && props.client['profileType'] === 'coach' ? 'Session with ' + props.client.firstName + ' on ' + sessionFormattedDate : ''}
                </span>
            {props.addVideo ?
              <span className="link text-large" onClick={() => props.addVideo(props.clientID, props.session['eventDocID'])}>Add Video</span>
              : null}
          </div>
          {!props.autoExpand ?
            showPanel ?
              <div className="CoachVideosSessionPanelHeaderHideShow">
                <ExpandLessIcon onClick={e => setShowPanel(false)}/>
                <p onClick={e => setShowPanel(false)}>Hide Feedback</p>
              </div>
              : <div className="CoachVideosSessionPanelHeaderHideShow">
                <ExpandMoreIcon onClick={e => getThumbnails(setShowPanel, setIsLoading, getPresignedUrl, props.session, user, setThumbnails, setCurrentVid, setCurrentVidID)}/>
                <p onClick={e => getThumbnails(setShowPanel, setIsLoading, getPresignedUrl, props.session, user, setThumbnails, setCurrentVid, setCurrentVidID)}>Show Session Feedback</p>
              </div>
            : null}
        </div>
        {thumbnails && thumbnails.length === 0 ?
          <span className="text-large text-thin">No videos have been posted for this session.</span> : null
        }
        {(showPanel || props.autoExpand) && thumbnails ?
          <div>
            {Object.keys(thumbnails).map((videoID, index) => {
              const comments = thumbnails[videoID]['metadata']['videoComments'];
              return (
                <div className="videoRow">
                  <div key={index} className="CoachVideosSessionPanelMainContent">
                    <div className="CoachVideosSessionPanelThumbnail">
                      <img src={thumbnails[videoID]['src']} alt={`${thumbnails[videoID]} Thumbnail`}/>
                      <PlayArrowIcon fontSize="large" onClick={e => loadVideos(e, videoID)} className="CoachVideosSessionPanelThumbnailPlayButton"/>
                    </div>

                  </div>
                  <div className="videoComments">
                    <h6 className="text-thin">Comments</h6>
                    {comments.map((comment, index) => {
                      const isCoachComment = comment['from'] === thumbnails[videoID]['metadata']['coach'];
                      return (
                        <div className="videoComment">
                          <b className="text-large">{isCoachComment ? 'Coach ' + thumbnails[videoID]['metadata']['coachInfo']['firstName'] : thumbnails[videoID]['metadata']['athleteInfo']['firstName'] + ' ' + thumbnails[videoID]['metadata']['athleteInfo']['lastName']}</b>
                          <br/>
                          {comment['content']}
                        </div>
                      );
                    })
                    }
                    <span className="link text-large" onClick={(e) => loadVideos(e, videoID)}>Add a Comment</span>
                  </div>
                </div>

              );
            })}
          </div>
          : null}
        {showPanel && isLoading ?
          <div style={{display: 'flex', justifyContent: 'center', padding: '2rem 1rem 1rem 1rem'}}>
            <ThemeProvider theme={spinnerTheme}>
              <CircularProgress/>
            </ThemeProvider>
          </div>
          : null}
      </div>
      <Dialog onClose={handleDialogClose} aria-labelledby="customized-dialog-title" open={showDialog} maxWidth="md" fullWidth={true}>
        <DialogTitle id="customized-dialog-title" onClose={handleDialogClose} className="CoachVideosDialogVideoDisplayTitle">
          {sessionFormattedDate}
        </DialogTitle>
        <DialogContent dividers>
          <div className="CoachVideosDialogVideoDisplay">
            {currentVid ? <div>
              <div className="fullPlayer">
                <ReactPlayer url={currentVid['src']} controls={true}/>
              </div>
              <div className="mobilePlayer">
                <ReactPlayer width={"240px"} height={"140px"} url={currentVid['src']} controls={true}/>
              </div>
            </div>: null}

            <div style={{width: '100%', marginTop: '30px'}}>
              <h5>Add Comment</h5>
              <div className="CoachInboxMessageWindowSecondChild">
                <div className="CoachInboxMessageToSend">
                  <textarea
                    placeholder="Add a comment"
                    value={commentToAdd}
                    onChange={e => setCommentToAdd(e.target.value)}>
                  </textarea>
                  <br/>
                  <Button
                    className="buttonPrimary sendButton"
                    onClick={e => addVideoComment(e)}>Add Comment</Button>
                </div>
              </div>
              {currentVid ? currentVid['metadata']['videoComments'].map((comment, i) => (
                <Paper
                  style={{width: '100%'}}
                  square
                  key={i}
                  elevation={0}
                  className="CoachInboxMessageContent">

                  {i === 0 ? <h5>All Comments </h5> : null}
                  {(i === 0 || (new Date(comment['time']).getUTCDay() !== new Date(currentVid['metadata']['videoComments'][i - 1]['time']).getUTCDay()))
                    ? <p className="CoachInboxDateSeparatorText" style={{textAlign: 'center', marginBottom: '0rem', fontSize: 'medium'}}>
                      {new Date(comment['time']).toDateString()}
                    </p>
                    : null}
                  <div className="CoachInboxMessageContentNameandDate">
                    <p className="CoachInboxMessageContentName" style={{fontSize: 'medium'}}>
                      {comment['from'] === currentVid['metadata']['coach'] ?
                        currentVid['metadata']['coachInfo']['firstName'] + " " + currentVid['metadata']['coachInfo']['lastName'] :
                        currentVid['metadata']['athleteInfo']['firstName'] + " " + currentVid['metadata']['athleteInfo']['lastName']}
                    </p>
                    <p className="CoachInboxMessageContentDate">
                      {new Date(comment['time']).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                    </p>
                  </div>
                  <div>
                    <p>{comment['content']} </p>
                  </div>

                </Paper>
              )) : null}
              <div style={{float: "left", clear: "both"}}
                   ref={(el) => {
                     setMessagesEnd(el);
                   }}></div>
            </div>

          </div>

        </DialogContent>

      </Dialog>
    </div>
  );
};

export default CoachVideosSessionPanel;