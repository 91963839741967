import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../screens/App/App";
import {completeEvent, getConnectedAccount, getEvents, getFinancialSummary, getMessages, getProfile, updateEvent} from '../../api/api';
import "../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import {CircularProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import "./CoachDashboard.scss";
import {Create, MonetizationOn, VerifiedUser} from "@material-ui/icons";
import {Link} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Colors from "../../constants/colors";
import BottomNav from "../BottomNav/BottomNav";
import CoachCalenderDialog from "../CoachCalenderEvent/CoachCalenderEvent";

const refreshUpcomingEvents = (user, setEvents, setPendingClients, setClients) => {
  user.firebaseUser.getIdToken()
    .then(function (idToken) {
      getEvents(idToken, user.firebaseUser.uid, Date.now())
        .then(response => {
          console.log('events response', response);
          setEvents(response['events'].concat(response['pendingEvents'].concat(response['personalEvents'])));
          setPendingClients(response['pendingClients']);
          setClients(response['clients']);
          console.log('clients', response['clients']);
        })
        .catch(e => console.log(e));
    });
};

const CoachDashboard = props => {
  const {user} = useContext(AuthContext);
  const [messages, setMessages] = useState({});
  const [conversations, setConversations] = useState({});
  const [events, setEvents] = useState([]);
  const [clients, setClients] = useState(null);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [profile, setProfile] = useState(null);
  const [openSuccessSnackbar, setOpenSuccessSnackBar] = useState(false);
  const [successMessage] = useState('');
  const [openErrorSnackbar, setOpenErrorSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [financialSummary, setFinancialSummary] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [showEventDialog, setShowEventDialog] = useState(false);
  const [pendingClients, setPendingClients] = useState({});

  //const mailIcon = <Email/>;
  const verifiedIcon = <VerifiedUser/>;
  const pencilIcon = <Create/>;
  const moneyIcon = <MonetizationOn/>;

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getProfile(idToken, user.firebaseUser.uid)
          .then(response => {
            console.log('Profile', response, user);
            setProfile(response);
          })
          .catch(e => console.log(e));
      });
  }, [user, user.firebaseUser]);

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getFinancialSummary(idToken, user.firebaseUser.uid)
          .then(response => {
            console.log('Financial Summary', response, user);
            setFinancialSummary(response);
          })
          .catch(e => console.log(e));
      });
  }, [user, user.firebaseUser]);

  // const resendVerificationEmail = () => {
  //   user.firebaseUser.sendEmailVerification()
  //     .then(res => {
  //       setSuccessMessage('Email Verification Link Has Been Sent');
  //       setOpenSuccessSnackBar(true)
  //     })
  //     .catch(e => console.log('unable to resend verification email', e));
  // }


  useEffect(() => {
    refreshUpcomingEvents(user, setEvents, setPendingClients, setClients);
  }, [user, user.firebaseUser]);

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getMessages(idToken, user.firebaseUser.uid)
          .then(response => {
            setMessages(response.messages);
            console.log('conversations', response.messages);
            setConversations(response.conversations);
            setMessagesLoaded(true);
          })
          .catch(error => console.log(error));
      });
  }, [user.firebaseUser]);

  useEffect(() => {
    if (user.profile.stripeID) {
      user.firebaseUser.getIdToken()
        .then(function (idToken) {
          getConnectedAccount(idToken, user.firebaseUser.uid)
            .then(response => {
              console.log('stripe info', response);
            })
            .catch(e => console.log(e));
        });
    }
  }, [user.firebaseUser, user.profile.stripeID]);

  const eventActionHandler = (eventID, updates, updater) => {
    // if (updates['startTime'] > updates['endTime'] - 3600000/4) {
    //   setNewEventSnackbarError(true);
    //   return;
    // }
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        updateEvent(idToken, eventID, updates, updater)
          .then(response => {
            refreshUpcomingEvents(user, setEvents, setPendingClients, setClients);
            setShowEventDialog(false);
          })
          .catch(e => {
            setErrorMessage('There was an error updating this event.');
            setOpenErrorSnackBar(true);
          });
      });
  };

  const markEventComplete = (eventID, updates, completer) => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        completeEvent(idToken, eventID, updates, completer)
          .then(response => {
            refreshUpcomingEvents(user, setEvents, setPendingClients, setClients);
          })
          .catch(e => {
            setErrorMessage('There was an error completing this event.');
            setOpenErrorSnackBar(true);
          });
      });
  };

  return (
    <div className="coachDashboardWrapper">

      <h1>Welcome, Coach {user.profile.firstName}</h1>

      <div className="columns">
        <div className="col1">
          {profile && (!profile['public_profile_complete'] || !profile['payments_set_up']) ?
            <div>
              <div className="actionRequiredBox text-large">
                <h5>What to do now?</h5>
                <span className="text-thin">Before we allow parents to view your profile on our platform, we need you to take care of a few things. See the notification section below and check out your tabs marked with a red asterisk ( <span className="redAsterik">*</span> ). Once your background check is complete and all required fields are filled, you can start interacting with potential clients!</span>
              </div>
              <h5>Notifications</h5>

              <div className="actionItems text-large">
                {/*{user && !user.firebaseUser.emailVerified ?*/}
                {/*  <div className="actionItem">*/}
                {/*    {mailIcon}*/}
                {/*    <div>*/}
                {/*      Confirm your email address*/}
                {/*      <br/>*/}
                {/*      <span className="resendEmailLink" style={{color: Colors.secondary}} onClick={() => resendVerificationEmail()}>Resend Email Verification Link</span>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  : null}*/}
                {profile && !profile['certn_verified'] ?
                  <a rel="noopener noreferrer" target="_blank" href="https://athletesuntapped.certn.co/hr/apply/d25bbb81-7140-4758-b307-c68b400effbd">
                    <div className="actionItem">{verifiedIcon} Submit your Certn background request.</div>
                  </a>
                  : null}
                {profile && !profile['public_profile_complete'] ?
                  <Link to="/profile">
                    <div className="actionItem">{pencilIcon} Complete your coaching profile.</div>
                  </Link>
                  : null}
                {profile && !profile['payments_set_up'] ?
                  <Link to="/payments">
                    <div className="actionItem">{moneyIcon} Enter your bank information so you can get paid.</div>
                  </Link>
                  : null}
              </div>
            </div>
            :
            <div>
              {events ?
                <div>
                  <div className="headerContainer">
                    <h5>Upcoming Sessions</h5>
                    <Link to="/calendar" className="link text-large">View Full Calendar</Link>
                  </div>
                  {clients !== null && events && events.length === 0 ?
                    <h6>No upcoming sessions</h6> :
                    <div className="sessions boxedList">
                      {events.slice(0, 3).map((event, i) => {
                        const isPending = event.coachStatus === 'pending';
                        const date = new Date(event['startTime']);
                        if (clients === null) return '';
                        const client = clients.hasOwnProperty(event['athlete']) ? clients[event['athlete']] : pendingClients[event['athlete']];
                
                        return (
                          <div className="boxedListItem session">
                            <div className="leftContent text-large">
                              <span className='text-bold'>{isPending ? 'Request - ' : client ? 'Confirmed - ' : ''}{client ? client['parentFirstName'] + ' ' + client['parentLastName'].substr(0, 1) : "Personal Event"}</span>
                              <br/>
                              <span className="text-thin">{date.toLocaleString('default', {dateStyle: 'medium', timeStyle: 'short'})}</span>
                            </div>
                            <div className="rightContent" style={{color: Colors.secondary}}>{isPending ?
                              <span className="respondLink link" onClick={() => {
                                setSelectedEvent(event);
                                setShowEventDialog(true);
                              }}>Respond</span> : client ? 'Booked' : null}</div>
                          </div>
                        );
                      })}
                    </div>
                  }
                </div>
                : <CircularProgress/>}
              {messagesLoaded && (conversations !== {}) ?
                <div>
                  <div className="headerContainer">
                    <h5>Recent Messages</h5>
                    <Link to="/inbox" className="link text-large">View Inbox</Link>
                  </div>
                  {conversations.length === 0 ?
                    <h6>No recent messages</h6> :
                    <div className="boxedList messages">
                      {Object.keys(conversations).slice(0, 3).map((item, i) => {
                        const date = new Date(messages[item][messages[item].length - 1]['time']);
                        const firstName = conversations[item]['parentFirstName'] || conversations[item]['firstName'];
                        const lastName = conversations[item]['parentLastName'] || conversations[item]['lastName'];
                        return (
                          <div className="boxedListItem message">                            
                            <div className="content text-large text-thin">
                            <span className='text-bold'>{firstName + ' ' +  lastName}</span>: {messages[item][messages[item].length - 1]['content']}
                              <br/>
                              <span className="messageType text-small" style={{color: Colors.secondary}}>
                    {messages[item][messages[item].length - 1]['from'] === user.firebaseUser.uid ? 'Sent' : 'Received'} -
                      <span>{date.toLocaleDateString() + ' ' + date.toLocaleString([], {timeStyle: 'short'})}</span>
                      </span>
                            </div>

                          </div>


                        );
                      })}
                    </div>}
                </div> : <CircularProgress/>}
            </div>
          }
        </div>
        <div className="col2">
          {financialSummary ?
            <div className="coachingProgress text-thin text-large">
              <h5>Coaching Progress</h5>
              <div className="row">
                <div className="label">Annual earnings:</div>
                <div className="val">${Math.round(financialSummary.ytdIncome)}</div>
              </div>
              <div className="row">
                <div className="label">30-day earnings:</div>
                <div className="val">${Math.round(financialSummary.thirtyDayIncome)}</div>
              </div>
              <div className="row">
                <div className="label">30-day sessions:</div>
                <div className="val">{financialSummary.thirtyDaySessions}</div>
              </div>
            </div>
            : <CircularProgress/>}
        </div>
      </div>

      <Snackbar open={openSuccessSnackbar}  onClose={e => setOpenSuccessSnackBar(false)}>
        <Alert onClose={e => setOpenSuccessSnackBar(false)} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={openErrorSnackbar}  onClose={e => setOpenErrorSnackBar(false)}>
        <Alert variant="filled" onClose={e => setOpenErrorSnackBar(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      {showEventDialog ?
        <CoachCalenderDialog
          open={showEventDialog}
          setOpen={setShowEventDialog}
          selectedEvent={selectedEvent}
          completeEvent={markEventComplete}
          updateEvent={eventActionHandler}
          for={"coach"}
          name={selectedEvent['user'] ? null : ((selectedEvent['status'] === "accepted") || (selectedEvent['status'] === "completed") ? clients[selectedEvent['athlete']]['parentFirstName'] + ' ' + clients[selectedEvent['athlete']]['parentLastName']
            : pendingClients[selectedEvent['athlete']]['parentFirstName'] + ' ' + pendingClients[selectedEvent['athlete']]['parentLastName'])}
        />
        : null}

      <BottomNav/>
    </div>
  );
};

export default CoachDashboard;