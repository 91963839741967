import React, { useState } from 'react';
import logo from '../HomeNav/AULogoSmall.png';
import { Link, withRouter } from 'react-router-dom';
import './BottomNav.scss';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { LinkedIn } from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import Login from "../Login/Login";
import Dialog from "@material-ui/core/Dialog";
import ForgotPassword from "../ForgotPassword/ForgotPassword.js";


const BottomNav = (props) => {

  const takeToBecomeCoach = (e) => {
    e.preventDefault();
    props.history.push({
      pathname: '/join',
      state: { forAthlete: false }
    });
  };

  const takeToHome = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    props.history.push({
      pathname: '/',
    });
  };

  // const takeToHelp = (e) => {
  //   e.preventDefault();
  //   window.scrollTo(0, 0);
  //   props.history.push({
  //     pathname: '/',
  //     state: {showHelp: true}
  //   });
  // };


  const linkHandler = (e, pathPassed) => {
    e.preventDefault();
    props.history.push({
      pathname: pathPassed,
    });
  };

  const [openLogin, setOpenLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const openLoginHandler = () => {
    setOpenLogin(true);
  };

  const closeLoginHandler = () => {
    setOpenLogin(false);
  };

  const openForgotPasswordHandler = () => {
    setShowForgotPassword(true);
  };

  const closeForgotPasswordHandler = () => {
    setShowForgotPassword(false);
  };


  return (
    <>
      <div className="bottomNavWrapper">
        <div className="bottomNav">
          <img className="AULogoBottomNav" src={logo} alt="Athletes Untapped" onClick={e => takeToHome(e)} />
          <div style={{ display: 'flex', justifyContent: 'space-evenly', flexGrow: '1' }}>
            <ul className="listOfLinksBottom">
              <li>
                <div>Navigation</div>
              </li>
              <li>
                <Link to="#" className="bottomNavTab" onClick={(e) => takeToHome(e)}>Home</Link>
              </li>
              <li>
                <Link to="#" className="bottomNavTab" onClick={(e) => linkHandler(e, "/parents-and-athletes")}>Parents &amp; Athletes</Link>
              </li>
              <li>
                <Link to="#" className="bottomNavTab" onClick={(e) => linkHandler(e, "/apply-to-coach")}>Apply to Coach</Link>
              </li>
              <li>
                <Link to="#" className="bottomNavTab" onClick={(e) => linkHandler(e, "/our-difference")}>Our Difference</Link>
              </li>
              <li>
                <Link to="#" className="bottomNavTab" onClick={(e) => linkHandler(e, "/coaches-by-zip")}>Coaches by Zip Code</Link>
              </li>
            </ul>
            <ul className="listOfLinksBottom">
              <li className="blank">&nbsp;</li>
              <li>
                <Link to="#" className="bottomNavTab" onClick={(e) => takeToBecomeCoach(e)}>Sign Up</Link>
              </li>
              <li>
                <Link to="#" className="bottomNavTab" onClick={openLoginHandler}>Login</Link>
                <Dialog onClose={closeLoginHandler} open={openLogin} fullWidth="true" maxWidth="sm">
                  <DialogContent dividers>
                    <Login
                      showReset={() => {
                        closeLoginHandler();
                        openForgotPasswordHandler();
                      }}
                      switch={() => {
                        closeLoginHandler();
                        closeForgotPasswordHandler();
                      }} />
                  </DialogContent>
                </Dialog>
                <Dialog
                  onClose={closeForgotPasswordHandler}
                  open={showForgotPassword}
                  fullWidth="true"
                  maxWidth="sm"
                >
                  <DialogContent dividers>
                    <ForgotPassword closeReset={closeForgotPasswordHandler} />
                  </DialogContent>
                </Dialog>
              </li>

              <li>
                {/* <Link to="#" className="bottomNavTab" onClick={takeToHelp}>Contact</Link> */}
                <a className="bottomNavTab" href="mailto:community@athletesuntapped.com">Contact</a>
              </li>
              <li>
                <a className="bottomNavTab" href="https://locations.athletesuntapped.com">Locations</a>
              </li>
              <li>
                <Link to="#" className="bottomNavTab" onClick={(e) => linkHandler(e, "/partnerships")}>Partners</Link>
              </li>
            </ul>
            <ul className="listOfLinksBottom">
              <li>
                <div>Legal</div>
              </li>
              <li>
                <Link to="#" className="bottomNavTab" onClick={(e) => linkHandler(e, "/privacy-policy")}>Privacy Policy</Link>
              </li>
              <li>
                <Link to="#" className="bottomNavTab" onClick={(e) => linkHandler(e, "/site-terms")}>Site Terms</Link>
              </li>
            </ul>
          </div>
          <div>
            <span className="footerNavTitle">Social</span>
            <div className="SocialMediaLinks">
              <a href="https://www.facebook.com/athletesuntapped/" target="_blank" rel="noreferrer"><FacebookIcon fontSize='large' /></a>
              <a href="https://www.instagram.com/athletesuntapped/" target="_blank" rel="noreferrer"><InstagramIcon fontSize='large' /></a>
              <a href="https://www.linkedin.com/company/athletesuntapped/" target="_blank" rel="noreferrer"><LinkedIn fontSize='large' /></a>
              <a href="https://twitter.com/AthUntapped/" target="_blank" rel="noreferrer"><TwitterIcon fontSize='large' style={{ margin: '0px 1rem' }} /></a>
            </div>
          </div>
        </div>
      </div>
      <div><p style={{ textAlign: "center", margin: "10px" }}> &copy; 2024 Athletes Untapped. All rights reserved.</p></div>
    </>
  );
};

export default withRouter(BottomNav);