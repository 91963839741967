import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { createCustomPricingPackage } from "../../api/api";
import { useAuth } from "../../screens/App/App";
import CopyField from "../CopyField/CopyField";
import styles from "./CustomPricingPackageDialog.module.scss";
import "./index.scss";

export default function CustomPricingPackageDialog() {
  const { user } = useAuth();

  const coachBookingUrl = `${window.location.origin}/book-session/${user.firebaseUser?.uid}`;
  const [customPackageUrl, setCustomPackageUrl] = useState(coachBookingUrl);
  const [customPrice, setCustomPrice] = useState("");
  const [numSessions, setNumSessions] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const [showPackageUrl, setShowPackageUrl] = useState(false);

  const handleRateChange = (e) => {
    const strippedInput = e.target.value
      .replace(/[^\d.]/g, "") // Replace anything not . or number
      .replace(/\.{2,}/g, ".") // Replace 2 or more . with 1 .
      .replace(/(\.\d{2}).*/g, "$1"); // essentially `.toFixed(2)`
    const value = strippedInput === "" ? 0 : parseFloat(strippedInput);
    if (value > 10000) {
      return;
    }

    setCustomPrice(strippedInput);
    showPackageUrl && setShowPackageUrl(false);
  };

  const handleNumSessionChange = (e) => {
    const strippedInput = e.target.value.replace(/[^\d]/g, "");
    const value = strippedInput === "" ? 0 : parseInt(strippedInput);
    if (value > 100) {
      return;
    }
    setNumSessions(value);
    showPackageUrl && setShowPackageUrl(false);
  };

  const handleCreateLink = useCallback(async () => {
    const token = await user.firebaseUser.getIdToken();
    createCustomPricingPackage(token, {
      price: parseFloat(customPrice),
      numSessions,
    })
      .then((res) => {
        setCustomPackageUrl(
          coachBookingUrl + `?package=${res.custom_pricing_package_id}`
        );

        setShowPackageUrl(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, customPrice, coachBookingUrl, numSessions]);

  return (
    <>
      <div>
        <h6>Need to create a custom pricing package?</h6>
        <Button
          className="buttonPrimary"
          onClick={() => setShowDialog(!showDialog)}
        >
          Click Here!
        </Button>
      </div>
      <Dialog
        id="CustomPricingPackageDialog"
        open={showDialog}
        onClose={() => {
          setShowPackageUrl(false);
          setShowDialog(false);
        }}
      >
        <DialogTitle disableTypography>
          <h3 className="heading color-secondary">
            Create A Custom Pricing Package
          </h3>
        </DialogTitle>
        <DialogContent className={styles["flex-column-center"]}>
          <div className={styles["flex-column-start"]}>
            <div className="dollar-input">
              <span>$</span>
              <input
                className="SessionRateInput"
                type="text"
                value={customPrice}
                onChange={handleRateChange}
                onFocus={(e) => e.target.select()}
              />
              <span>Custom Package Price</span>
            </div>
            <div className="dollar-input" style={{ paddingLeft: 10 }}>
              <input
                className="SessionRateInput"
                type="text"
                value={numSessions}
                onChange={handleNumSessionChange}
                onFocus={(e) => e.target.select()}
              />
              <span>Sessions</span>
            </div>
          </div>
          {showPackageUrl ? (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              paddingTop={1}
            >
              <CopyField label="Custom Pricing URL" value={customPackageUrl} />
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions className="center">
          <Button className="buttonPrimary" onClick={handleCreateLink}>
            Create Link
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
