import React, { useContext, useEffect, useState } from "react";
import * as firebase from "firebase/app";
import { useHistory, withRouter, Link } from "react-router-dom";
import { createUser } from "../../api/api";
import TextField from "@material-ui/core/TextField";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import "./Join.scss";
import logo from "../../components/HomeNav/AULogoSmall.png";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { green } from "@material-ui/core/colors";
import BottomNav from "../../components/BottomNav/BottomNav";
import {
  Radio,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiPhoneNumber from "material-ui-phone-number";
import { AuthContext } from "../../screens/App/App";
import { Checkbox } from "@material-ui/core";
import { sports } from "src/constants/sports";

import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const spinnerTheme = createTheme({
  palette: {
    primary: {
      main: "#006400",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validatePhone(phone) {
  return !phone || phone.length === 0 || phone.length === 14;
}

function validateZip(zip) {
  const re = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return re.test(String(zip).toLowerCase());
}

function validatePassword(password) {
  var re = /\d/g; // Must have at least 1 number
  return String(password).length >= 6 && re.test(String(password));
}

const textFieldColor = "green";
const textFieldColorNormal = "lightslategray";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(3),

      "& label.Mui-focused": {
        color: textFieldColor,
      },
      "& label": {},
      "& .MuiInput-underline:after": {
        borderBottomColor: textFieldColor,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: textFieldColorNormal,
          borderRadius: "0",
          borderWidth: "1.2px",
        },
        "&:hover fieldset": {
          borderColor: textFieldColor,
          borderWidth: "2px",
        },
        "&.Mui-focused fieldset": {
          borderColor: textFieldColor,
          borderWidth: "2px",
        },
      },
    },
  },

  rootSmall: {
    "& .MuiSelect-root": {},
    "& .MuiTextField-root": {
      margin: theme.spacing(3),
      "& label.Mui-focused": {
        color: textFieldColor,
      },
      "& label": {},
      "& .MuiInput-underline:after": {
        borderBottomColor: textFieldColor,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: textFieldColorNormal,
          borderRadius: "0",
          borderWidth: "1.2px",
        },
        "&:hover fieldset": {
          borderColor: textFieldColor,
          borderWidth: "2px",
        },
        "&.Mui-focused fieldset": {
          borderColor: textFieldColor,
          borderWidth: "2px",
        },
      },
    },
  },

  formControl: {
    margin: theme.spacing(3),
    minWidth: "30ch",
  },
  formControl2: {
    marginLeft: theme.spacing(3),
    minWidth: "30ch",
    fontSize: "small",
  },
  p: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(1),
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
  },
  label: {},
}));

const GreenCheckbox = withStyles({
  root: {
    color: "lightslategray",
    "&$checked": {
      color: green[800],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const GreenRadio = withStyles({
  root: {
    color: "lightslategray",
    "&$checked": {
      color: green[800],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const Join = (props) => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  console.log("params", params);
  if (user) {
    if (params.returnTo) {
      window.location = params.returnTo;
    } else {
      history.push("/dashboard");
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoaded, setIsLoaded] = useState(true);
  const [error, setErrors] = useState("");
  const [errorSnackBar, setErrorSnackBar] = useState(false);

  let athleteSport = "";
  let athleteZipCode = "";

  if (typeof props.location.state !== "undefined") {
    if (props.location.state.forAthlete) {
      athleteSport = props.location.state.sport;
      athleteZipCode = props.location.state.zipCode;
    } 
  }

  const [prelimAthleteSport] = useState(athleteSport);
  const [prelimAthleteZipCode] = useState(athleteZipCode);

  // General function for submitting both new coaches and new athletes to backend
  const submit = (vals) => {
    // Put the loading screen up
    setIsLoaded(false);



    // Create the user then sign in with Firebase as the newly created user
    createUser(vals)
      .then((res) => {
        return firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      })
      .then(() => {
        return firebase
          .auth()
          .signInWithEmailAndPassword(vals.email, vals.password);
      })
      .catch((e) => {
        setErrors(e);
        setErrorSnackBar(true);
        // Only set isLoaded if there is an error to display. Otherwise, keep the loading
        // screen up until App.js switches the page
        setIsLoaded(true);
      });
  };


  if (isLoaded) {
      return (
        <div>
          <div className="greenBoxSignup">
            <AthleteJoin
              submit={submit}
              prelimSport={prelimAthleteSport}
              prelimZipCode={prelimAthleteZipCode}
            />
            <Snackbar
              open={errorSnackBar}
              onClose={(e) => setErrorSnackBar(false)}
            >
              <Alert onClose={(e) => setErrorSnackBar(false)} severity="error">
                {error}
              </Alert>
            </Snackbar>
          </div>
          <BottomNav />
        </div>
      );
   
  } else {
    // This can be a blank screen, spinner, etc..
    return (
      <div
        style={{ marginTop: "5rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
      >
        <ThemeProvider theme={spinnerTheme}>
          <CircularProgress />
        </ThemeProvider>
      </div>
    );
  }
};

const AthleteJoin = (props) => {
  const routerHistory = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sport, setSport] = useState([]);
  const [zipCode, setZipCode] = useState(props.prelimZipCode);
  const [referralCode, setReferralCode] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [parent, setParent] = useState(true);
  const [athlete, setAthlete] = useState(false);

  const [errorSnackBar, setErrorSnackBar] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState("");

  const vals = {
    firstName: firstName,
    lastName: lastName,
    sport: sport,
    zipCode: zipCode,
    email: email,
    emailConfirm: emailConfirm,
    phone: phone,
    password: password,
    profileType: "athlete",
    password2: password2,
    terms: terms,
    privacy: privacy,
    parentFirstName: parentFirstName,
    parentLastName: parentLastName,
    referralCode: referralCode,
  };

  const submitAthlete = (e) => {
    // Make sure that form runs our logic and not whatever default logic exists for forms
    // in HTML/JavaScript
    e.preventDefault();

    //TODO: do client-side validation on user's input (i.e. ensure all required fields are present)

    if (!parent && (!vals["firstName"] || vals["firstName"] === "")) {
      setErrorSnackBarMessage("Please enter a first name.");
      setErrorSnackBar(true);
      return;
    }
    if (!parent && !vals["lastName"]) {
      setErrorSnackBarMessage("Please enter a last name.");
      setErrorSnackBar(true);
      return;
    }
    if (
      !vals["email"] ||
      vals["email"] === "" ||
      !validateEmail(vals["email"])
    ) {
      setErrorSnackBarMessage("Please enter a valid email.");
      setErrorSnackBar(true);
      return;
    }
    if (!vals["emailConfirm"] || vals["email"] !== vals["emailConfirm"]) {
      setErrorSnackBarMessage("Emails entered do not match");
      setErrorSnackBar(true);
      return;
    }
    if (!validatePhone(vals["phone"])) {
      setErrorSnackBarMessage("Please enter a valid phone number.");
      setErrorSnackBar(true);
      return;
    }

    if (!vals["sport"] || vals["sport"].length === 0) {
      setErrorSnackBarMessage("Please select at least one sport.");
      setErrorSnackBar(true);
      return;
    }
    if (
      !vals["zipCode"] ||
      vals["zipCode"] === "" ||
      !validateZip(vals["zipCode"])
    ) {
      setErrorSnackBarMessage("Please enter a valid zip code.");
      setErrorSnackBar(true);
      return;
    }
    if (!vals["password"] || !validatePassword(vals["password"])) {
      setErrorSnackBarMessage(
        "Please enter a password of length 6 or more and with at least 1 number."
      );
      setErrorSnackBar(true);
      setPassword("");
      setPassword2("");
      return;
    }

    if (vals["password"] !== vals["password2"]) {
      setErrorSnackBarMessage(
        "Please make sure your password confirmation matches your original."
      );
      setErrorSnackBar(true);
      setPassword("");
      setPassword2("");
      return;
    }

    delete vals["password2"];

    if (!vals["terms"]) {
      setErrorSnackBarMessage("Terms of Service box is not checked.");
      setErrorSnackBar(true);
      return;
    }
    if (!vals["privacy"]) {
      setErrorSnackBarMessage("Privacy Policy box is not checked.");
      setErrorSnackBar(true);
      return;
    }

    vals["isParent"] = parent;

    // Submit the coach using the submit function passed in the props
    props.submit(vals);
  };

  const styleClasses = useStyles();

  const parentNameFields = (
    <div>
      <TextField
        value={parentFirstName}
        onChange={(e) => setParentFirstName(e.target.value)}
        name="parentFirstName"
        type="parentFirstName"
        label="Parent First Name*"
        variant="outlined"
        inputProps={{ style: { minWidth: "30ch", boxShadow: "none" } }}
      />
      <TextField
        value={parentLastName}
        onChange={(e) => setParentLastName(e.target.value)}
        name="parentLastName"
        type="parentLastName"
        label="Parent Last Name*"
        variant="outlined"
        inputProps={{ style: { minWidth: "30ch", boxShadow: "none" } }}
      />
    </div>
  );



  return (
    <div className="joinContainer">
      <div style={{ textAlign: "center" }}>
        <img src={logo} className="AULogoSignUp" alt="Athletes Untapped" />
        <p className="pSignUpHeader">
          Create an Account with Athletes Untapped! (It's Free)
        </p>
        <h6 className="text-thin">
          Once you create an account, you will be able to message coaches and
          book training sessions.
        </h6>
        <div className="switchViewJoinPage">
          <p>{"Not a Parent or Athlete?"}</p>
          <Link to="/apply">
            <p>Apply to Become an AU Coach Instead.</p>
          </Link>
        </div>
        <div className="switchViewJoinPage">
          <p>{"Fields with an asterisk (*) are required."}</p>
          <p></p>
        </div>
        <hr
          style={{
            width: "45%",
            border: "1px solid lightgray",
            marginBottom: "1.5rem",
          }}
        />
      </div>
      <Snackbar open={errorSnackBar} onClose={(e) => setErrorSnackBar(false)}>
        <Alert onClose={(e) => setErrorSnackBar(false)} severity="error">
          {errorSnackBarMessage}
        </Alert>
      </Snackbar>
      <div
        className="removeFlexMobile"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <form
          className={styleClasses.rootSmall}
          onSubmit={(e) => submitAthlete(e)}
        >
          <div
            className="removeFlexMobile"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <GreenRadio
                    checked={parent}
                    onChange={(e) => {
                      setParent(e.target.checked);
                      setAthlete(!e.target.checked);
                    }}
                    name="parent"
                  />
                }
                label="I'm the Athlete's Parent"
                classes={{ label: styleClasses.label }}
              />
            </FormControl>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <GreenRadio
                    checked={athlete}
                    onChange={(e) => {
                      setAthlete(e.target.checked);
                      setParent(!e.target.checked);
                      setLastName("");
                    }}
                    name="athlete"
                  />
                }
                label="I'm the Athlete"
                classes={{ label: styleClasses.label }}
              />
            </FormControl>
          </div>
          {parent ? parentNameFields : null}
          {parent ? (
            <div>
              {/*<TextField*/}
              {/*  value={firstName}*/}
              {/*  onChange={e => setFirstName(e.target.value)}*/}
              {/*  name="firstName"*/}
              {/*  type="firstName"*/}
              {/*  label="Athlete First Name*"*/}
              {/*  variant="outlined"*/}
              {/*  inputProps={{style: {minWidth: '30ch', boxShadow: 'none'}}}*/}
              {/*/>*/}
            </div>
          ) : (
            <div>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                name="firstName"
                type="firstName"
                label="First Name*"
                variant="outlined"
                inputProps={{ style: { minWidth: "30ch", boxShadow: "none" } }}
              />
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                name="lastName"
                type="lastName"
                label="Last Name*"
                variant="outlined"
                inputProps={{ style: { minWidth: "30ch", boxShadow: "none" } }}
              />
            </div>
          )}
          <div>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              type="email"
              label="Email*"
              variant="outlined"
              helperText="We'll email you confirmations and receipts for training sessions."
              inputProps={{ style: { minWidth: "67.8ch", boxShadow: "none" } }}
            />
          </div>
          <div>
            <TextField
              value={emailConfirm}
              onChange={(e) => setEmailConfirm(e.target.value)}
              name="emailConfirm"
              type="email"
              label="Confirm Email*"
              variant="outlined"
              inputProps={{ style: { minWidth: "67.8ch", boxShadow: "none" } }}
            />
          </div>
          <div>
            <MuiPhoneNumber
              autoFormat={true}
              disableCountryCode={true}
              defaultCountry={"us"}
              onlyCountries={["us"]}
              value={phone}
              onChange={(val) => setPhone(val)}
              name="phone"
              label="Phone"
              variant="outlined"
              helperText={
                "We'll send you reminders about upcoming training sessions - helpful for busy schedules! You can always opt out."
              }
              inputProps={{ style: { minWidth: "67.8ch", boxShadow: "none" } }}
            />
          </div>
          <div>
            <TextField
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              name="zipCode"
              type="zipCode"
              label="Zip Code*"
              key="f"
              variant="outlined"
              inputProps={{ style: { minWidth: "30ch", boxShadow: "none" } }}
            />
          </div>

          {/* signup sports checklist */}
          <div>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={sports}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => setSport(newValue.map(option => option.value))}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    <span style={{ fontWeight: 100, fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}>
                      {option.label}
                    </span>
                  </li>
                );
              }}
              style={{ width: 400 }}
              renderInput={(params) => (
                <TextField {...params}
                  name="sport"
                  type="sport"
                  label="Sport"
                  variant="outlined"
                  placeholder="Favorites"
                />
              )}
            />
          </div>


          <div>
            <TextField
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              name="referralCode"
              type="referralCode"
              label="Referral Code"
              variant="outlined"
              inputProps={{ style: { minWidth: "30ch", boxShadow: "none" } }}
            />
          </div>
          <div>
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              value={password}
              type="password"
              label="Create Password*"
              variant="outlined"
              inputProps={{ style: { minWidth: "67.8ch", boxShadow: "none" } }}
            />
          </div>
          <div>
            <TextField
              onChange={(e) => setPassword2(e.target.value)}
              name="password2"
              value={password2}
              type="password"
              label="Re-Enter Password*"
              variant="outlined"
              inputProps={{ style: { minWidth: "67.8ch", boxShadow: "none" } }}
            />
          </div>
          <div>
            <FormControl component="fieldset" className="iAgree">
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={terms}
                    classes={{ checked: styleClasses.checkbox }}
                    onChange={(e) => setTerms(e.target.checked)}
                    name="terms"
                  />
                }
                label="I agree to Athletes Untapped's"
                classes={{ label: styleClasses.label }}
              />
              <span
                className="link text-large"
                onClick={() => routerHistory.push("/site-terms")}
              >
                Terms of Service.*
              </span>
            </FormControl>
          </div>
          <div>
            <FormControl component="fieldset" className="iAgree">
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={privacy}
                    onChange={(e) => setPrivacy(e.target.checked)}
                    name="privacy"
                  />
                }
                label="I agree to Athletes Untapped's Privacy Policy.*"
                classes={{ label: styleClasses.label }}
              />
              <span
                className="link text-large"
                onClick={() => routerHistory.push("/privacy-policy")}
              >
                Privacy Policy.*
              </span>
            </FormControl>
          </div>

          {/* email verification  */}
          <div className="joinButtonDiv">
            <button type="submit" className="joinButton"
            // onClick={signup}
            >
              Start Training
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(Join);
