import React, { useEffect, useState, useContext } from 'react';
import { getCoachClients } from '../../api/api';
import { AuthContext } from "../../screens/App/App";
import './CoachClients.css';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgress } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';


const materialTheme = createTheme({
  palette: {
    primary: {
      main: '#006400'
    }
  }
});

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    fontFamily: 'Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif'
  },
});

const CoachClients = props => {
  const { user } = useContext(AuthContext);
  const [clients, setClients] = useState(null);
  const [loadingClients, setLoadingClients] = useState(true);
  const tableClasses = useStyles();

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getCoachClients(idToken, user.firebaseUser.uid)
          .then(response => {
            let clients = response['clients'];
            if (clients) {
              Object.values(clients).forEach((client) => {
                if (client.events) {
                  client.sortedCompletedEvents = Object.values(client.events).filter((item) => item.coachStatus === 'completed').sort((a, b) => a.startTime > b.startTime ? -1 : a.startTime < b.startTime ? 1 : 0)
                }
              });
            }
            setClients(clients);
            console.log('response', clients);
            setLoadingClients(false);
          })
          .catch(error => console.log(error));
      });
  }, [user.firebaseUser])

  return (
    <div>
      {loadingClients ?
        <div style={{ display: 'flex', justifyContent: 'center', padding: '4rem 0rem' }}>
          <ThemeProvider theme={materialTheme}>
            <CircularProgress />
          </ThemeProvider>
        </div> : null}
      {!loadingClients && !clients ?
        <div className="lookingForNew">
          <p>You don't have any clients yet. When you do, you will see information related to the sessions they have purchased from you.</p>
        </div> : null}
      {!loadingClients && clients ?
        <div style={{ padding: '0rem' }}>
          <div className="CoachClientsHeader">
            <p>Your Clients</p>
            {clients ? <p>Total Clients: {Object.keys(clients).length}</p> : null}
          </div>
          <div style={{ padding: '1rem 5rem' }}>
            <TableContainer className='fullSizeBookingsTable'>
              <Table className={tableClasses.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Parent/Athlete Name</TableCell>
                    {/* <TableCell align="right"># of Athletes</TableCell> */}
                    <TableCell align="right">Last Session</TableCell>
                    {/*<TableCell align="right">Next Session</TableCell>*/}
                    <TableCell align="right">Total Sessions</TableCell>
                    {/*<TableCell align="right">Sessions Completed</TableCell>*/}
                    <TableCell align="right">Completed</TableCell>
                    <TableCell align="right">Upcoming</TableCell>
                    <TableCell align="right">Pending</TableCell>
                    <TableCell align="right">Need to Schedule</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients ? Object.keys(clients).map((athleteID, i) => {
                    let lastEventTime = clients[athleteID].sortedCompletedEvents && clients[athleteID].sortedCompletedEvents.length > 0 ? clients[athleteID].sortedCompletedEvents[0].startTime : '';
                    let formattedDate = '';
                    if (lastEventTime) {
                      let date = new Date(lastEventTime);
                      formattedDate = date.toLocaleDateString();
                    }

                    return (<TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {(clients[athleteID]['athleteInfo']['parentFirstName'] || clients[athleteID]['athleteInfo']['firstName']) ? 
                          (clients[athleteID]['athleteInfo']['parentFirstName'] || clients[athleteID]['athleteInfo']['firstName']) + ' ' + 
                          (clients[athleteID]['athleteInfo']['parentLastName'] || clients[athleteID]['athleteInfo']['lastName']) 
                        : null}
                      </TableCell>
                      {/* <TableCell align="right">{clients[athleteID]['connection']['numAthletes']}{"test"}</TableCell> */}
                      <TableCell align="right">{formattedDate}</TableCell>
                      <TableCell align="right">{clients[athleteID]['connection']['numSessions']}</TableCell>
                      <TableCell align="right">{clients[athleteID]['connection']['sessionsCompleted']}</TableCell>
                      <TableCell align="right">{clients[athleteID]['connection']['acceptedSessions']}</TableCell>
                      <TableCell align="right">{clients[athleteID]['connection']['pendingSessions']}</TableCell>
                      <TableCell align="right">{clients[athleteID]['connection']['sessionsLeft']}</TableCell>
                      <TableCell align="right">{clients[athleteID]['connection']['sessionsLeft'] > 0 ? "Active" : "Inactive"}</TableCell>
                    </TableRow>
                    )
                  }) : null}
                </TableBody>
              </Table>
            </TableContainer>
            <div className='mobileBookingsTable'>
              {clients ? Object.keys(clients).map((athleteID, i) => {
                let lastEventTime = clients[athleteID].sortedCompletedEvents && clients[athleteID].sortedCompletedEvents.length > 0 ? clients[athleteID].sortedCompletedEvents[0].startTime : '';
                let formattedDate = '';
                if (lastEventTime) {
                  let date = new Date(lastEventTime);
                  formattedDate = date.toLocaleDateString();
                }
                return (
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className='heading'>Parent Name</TableCell>
                          <TableCell>{clients[athleteID]['athleteInfo']['parentFirstName'] ? clients[athleteID]['athleteInfo']['parentFirstName'] + ' ' + clients[athleteID]['athleteInfo']['parentLastName'] : null}</TableCell>
                        </TableRow>
                        {/* <TableRow>
                          <TableCell className='heading' align="right"># of Athletes</TableCell>
                          <TableCell align="right">{clients[athleteID]['connection']['numAthletes']}{"test"}</TableCell>
                        </TableRow> */}
                        <TableRow>
                          <TableCell className='heading' align="right">Last Session</TableCell>
                          <TableCell align="right">{formattedDate}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Total Sessions</TableCell>
                          <TableCell align="right">{clients[athleteID]['connection']['numSessions']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Completed</TableCell>
                          <TableCell align="right">{clients[athleteID]['connection']['sessionsCompleted']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Upcoming</TableCell>
                          <TableCell align="right">{clients[athleteID]['connection']['acceptedSessions']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Pending</TableCell>
                          <TableCell align="right">{clients[athleteID]['connection']['pendingSessions']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Need to Schedule</TableCell>
                          <TableCell align="right">{clients[athleteID]['connection']['sessionsLeft']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='heading' align="right">Status</TableCell>
                          <TableCell align="right" className="athleteCoachActions">
                            {clients[athleteID]['connection']['sessionsLeft'] > 0 ? "Active" : "Inactive"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                );
              }) : null}

            </div>
          </div>
        </div> : null}
    </div>
  );
}

export default CoachClients;