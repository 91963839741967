import React from "react";
import './OurStory.scss';
import {useHistory, withRouter} from 'react-router-dom';
import BottomNav from '../BottomNav/BottomNav';
import {CLOUDFRONT_UPLOADS} from "../../constants/hosts";
import Button from "@material-ui/core/Button";
import MetaTags from "react-meta-tags";


const OurStory = (props) => {
    const history = useHistory();

    const takeToBecomeCoach = () => {
        props.history.push({
            pathname: '/join',
            state: {forAthlete: false}
        });
    };

    return (
        <div>
            <MetaTags>
                <title>Top Coaches & Unrivaled Tech</title>
                <meta name="description"
                      content="Athletes Untapped | Combining sports and tech to serve our mission of providing young athletes with better opportunities to succeed."/>
                <meta property="og:title" content="Top Coaches & Unrivaled Tech"/>
            </MetaTags>
            <div className="ourStory">
                <div className="topBox">
                    <h1 className="title">Top coaches, unrivaled tech</h1>
                </div>
                <h3 className="subTitle">Our platform fosters strong connections between athletes and private coaches.
                    We enable the relationships to last by backing them with dynamic technology.</h3>
                <div className="storyBox">
                    <div className="content">
                        <h5 className="title">Video Feedback</h5>
                        Record short videos of your training sessions and post them to your AU account. This can be done
                        by a parent or AU coach. From home, coaches can relay feedback on each video so the athlete
                        knows what to work on in between training sessions.
                    </div>
                    <img alt="Screenshot of private basketball coach working with athlete"
                         src={CLOUDFRONT_UPLOADS + "static/story-video-feedback.png"}/>
                </div>
                <div className="storyBox">
                    <div className="content">
                        <h5 className="title">Communication</h5>
                        Inquire about working with a coach through our user-friendly messaging system. Once your first
                        training session is booked, rely on us for all future communication with your AU coach.
                    </div>
                    <img alt="Screenshot of messages between athletes and private sports coaches"
                         src={CLOUDFRONT_UPLOADS + "static/story-communication.png"}/>
                </div>
                <div className="storyBox">
                    <div className="content">
                        <h5 className="title">Scheduling</h5>
                        Use our calendar to manage your entire training schedule. Parents and athletes can reserve
                        sessions through our calendar and will be able to see when their coach is available for training
                        on a weekly basis.
                    </div>
                    <img alt="Screenshot of private sports coach's calendar"
                         src={CLOUDFRONT_UPLOADS + "static/story-scheduling.png"}/>
                </div>
                <div className="storyBox">
                    <div className="content">
                        <h5 className="title">Booking</h5>
                        Reserve training sessions and pay coaches through AU. Our secure payment system, backed by
                        Stripe, ensures your money will get to your AU coach — that's why we ask you to always pay
                        through our platform.
                    </div>
                    <img alt="Screenshot of checkout page for a private sports coach"
                         src={CLOUDFRONT_UPLOADS + "static/story-booking.png"}/>
                </div>
                <div className="storyBox">
                    <div className="content">
                        <h5 className="title">Vetting</h5>
                        We know safety is essential. You can trust that our qualified coaches passed their background
                        check and are able to train when you are ready!
                    </div>
                    <img alt="Screenshot of background check information for private sports coaches"
                         src={CLOUDFRONT_UPLOADS + "static/story-vetting.png"}/>
                </div>

                <div className="getStartedBox">
                    <h3>Ready to get started?</h3>
                    <div className="buttonContainer">
                        <Button className="buttonPrimary" onClick={() => history.push('/coaches')}>Find coaches</Button>
                        <Button className="buttonPrimary" onClick={takeToBecomeCoach}>Apply to coach</Button>
                    </div>
                </div>

            </div>
            <BottomNav/>

        </div>
    );
};

export default withRouter(OurStory);