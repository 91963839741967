import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import "./ReadyToTrainFormWide.scss";
import GeocodingUtils from "../../utils/GeocodingUtils";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { sports } from "src/constants/sports";

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

function validateZip(zip) {
  const re = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return re.test(String(zip).toLowerCase());
}

const ReadyToTrainFormWide = (props) => {
  const [sport, setSport] = useState("");
  const [zip, setZip] = useState("");
  const [searchErrorSnackBar, setSearchErrorSnackBar] = useState(false);
  const [searchErrorSnackBarMessage, setSearchErrorSnackBarMessage] = useState("");

  useState(function () {
    GeocodingUtils.GetZip(
      function (res) {
        setZip(res);
      },
      function (err) {
        console.log("err", err);
      }
    );

  });

  const handleForm = (e) => {
    e.preventDefault();

    if (!zip || zip === "" || !validateZip(zip)) {
      setSearchErrorSnackBarMessage("Please enter a valid 5-digit zip code.");
      setSearchErrorSnackBar(true);
      return;
    }

    if (!sport || sport === "") {
      setSearchErrorSnackBarMessage("Please choose a sport.");
      setSearchErrorSnackBar(true);
      return;
    }

    if(props.formType === "search") {
      props.history.push({
        pathname: "/coaches",
        search: "?zip=" + zip + "&sport=" + sport + "&radius=50",
        state: { sport: sport, zipCode: zip },
      });
    } else {
      props.history.push({
        pathname: "/apply",
        state: { forAthlete: false, sport: sport, zipCode: zip },
      });
    }
  };

  return (
    <form className="readyToTrainFormWide">
      <div className="readyToTrainFormWide-inputs">
        <Autocomplete
          sx={{ width: 200 }}
          selectOnFocus
          autoHighlight={true}
          blurOnSelect={true}
          options={sports}
          getOptionLabel={(option) => option.label}
          onChange={(event, newValue) => setSport(newValue ? newValue.value : '')}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <span style={{ fontWeight: 100, fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}>
                {option.label}
              </span>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              className="sport input"
              value={sport}
              name="sport"
              type="sport"
              label="Sport"
              variant="outlined"
            />
          )}
        />

        <TextField
          onChange={(e) => setZip(e.target.value)}
          className="zip input"
          name="zip"
          value={zip}
          type="zip"
          label="Zip Code"
          variant="outlined"
          inputProps={{ style: { boxShadow: "none" } }}
        />
      </div>
      <Button className="buttonPrimary" onClick={handleForm}>
        {props.buttonText}
      </Button>

      <Snackbar
        open={searchErrorSnackBar}
        onClose={(e) => setSearchErrorSnackBar(false)}
      >
        <div>
          <Alert onClose={(e) => setSearchErrorSnackBar(false)} severity="error">
            {searchErrorSnackBarMessage}
          </Alert>
        </div>
      </Snackbar>
    </form>
  );
};

export default withRouter(ReadyToTrainFormWide);
