import React, { useContext, useState } from 'react';
import blank_avatar from '../CoachProfile/avatar.png';
import './CoachProfileHeader.scss';
import ImageUploader from 'react-images-upload';
import { updateProfile, updateProfileImage } from "../../api/api";
import { AuthContext } from "../../screens/App/App";
import {
  Check,
  Help,
  Home,
  Star,
  ThumbUp,
  AlarmOn
} from "@material-ui/icons";
import Colors from "../../constants/colors";
import Button from "@material-ui/core/Button";
import { CLOUDFRONT_UPLOADS } from '../../constants/hosts';
import { useHistory } from "react-router";
import ProfileUtils from "../../utils/ProfileUtils";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import {CircularProgress, Dialog, DialogContent, DialogTitle, IconButton} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import { sports } from 'src/constants/sports';

function createAvailabilityString(weekAvailability) {
  let availableRanges = []
  if (weekAvailability.monday) availableRanges.push("Monday")
  if (weekAvailability.tuesday) availableRanges.push("Tuesday")
  if (weekAvailability.wednesday) availableRanges.push("Wednesday")
  if (weekAvailability.thursday) availableRanges.push("Thursday")
  if (weekAvailability.friday) availableRanges.push("Friday")
  if (weekAvailability.saturday) availableRanges.push("Saturday")
  if (weekAvailability.sunday) availableRanges.push("Sunday")

  if (availableRanges.length === 0) return undefined
  const availableRangesString = availableRanges.join(", ")
  return `Available: ${availableRangesString}`
}

const CoachProfileHeader = (props) => {

  //const [uploadedProfilePic, setUploadedProfilePic] = useState(null);
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [customUrl, setCustomUrl] = useState(props.profile.customUrl);
  const [showSuperCoachHelp, setShowSuperCoachHelp] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [error, setError] = useState(null);
  const isSuper = (props.numRatings > 0 || props.profile.isSuperCoach === true) && props.profile.isSuperCoach !== false;

  const checkIcon = <Check style={{ color: Colors.secondary }} />;
  const timeIcon = <AlarmOn style={{ color: Colors.secondary }} />;
  const homeIcon = <Home style={{ color: Colors.primary }} />;
  const starIcon = <Star style={{ color: Colors.primary }} />;
  const thumbUpIcon = <ThumbUp style={{ color: Colors.primary }} />;


  const isEditing = (user && user.profile.id === props.profile.id) || !props.public;
  const onDrop = (picture) => {
    //setUploadedProfilePic(picture);
    uploadFile(picture[0]);
  };

  const uploadFile = (file) => {
    setUploadingImage(true);
    const uploadData = {
      'bucketName': 'athletes-untapped-prod-uploads',
      'uploadedBy': user.firebaseUser.uid
    };
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        updateProfileImage(idToken, uploadData)
          .then(response => {
            console.log('aws presigned info', response);
            sendToAWS(response['url'], file);
            //callback(response['url'])

          })
          .catch(e => {
            console.log(e);
            setUploadingImage(false);
          });
      });
  };

  const sendToAWS = async (url, file) => {
    const response = await fetch(
      new Request(url, {
        method: 'PUT',
        body: file
      }),
    );
    if (response.status !== 200) {
      // The upload failed, so let's notify the caller.
      console.log(response);
      setUploadingImage(false);
    } else {
      console.log(response);
      setUploadingImage(false);
      //cheat ad reload page
      window.location.reload(false);
    }
  };

  const saveCustomUrl = () => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        updateProfile(idToken, { customUrl: customUrl }, props.profile.id)
          .then(response => {
            window.location.reload(false);

          })
          .catch(e => {
            setCustomUrl("");
            console.log(e);
            setError("Custom URL already in use.  Please try again.");
          });
      });
  };

  const profileUrl = "https://www.athletesuntapped.com/coach-profile/" + (props.profile.customUrl ? props.profile.customUrl : props.profile.id);

  // const getSportIcon = function(sport) {
  //   let ballIcon = sportsBasketball;
  //   switch (sport) {
  //     case 'basketball':
  //       ballIcon = sportsBasketball;
  //       break;
  //     case 'baseball':
  //       ballIcon = sportsBaseball;
  //       break;
  //     case 'soccer':
  //       ballIcon = sportsSoccerBall;
  //       break;
  //     case 'lacrosse':
  //       ballIcon = lacrosse;
  //       break;
  //     case 'softball':
  //       ballIcon = softball;
  //       break;
  //     case 'football':
  //       ballIcon = football;
  //       break;
  //     case 'track-and-field':
  //       ballIcon = trackField;
  //       break;
  //     case 'strength-and-speed':
  //       ballIcon = strengthSpeed;
  //       break;
  //     case 'mental-performance':
  //       ballIcon = insertEmoticon;
  //       break;
  //     default:
  //       ballIcon = sportsDefault;
  //       break;
  //   }
  //   return ballIcon;
  // }

  return (
    <div>

      <div className="header coachProfileHeader">
        <div className="left">
          <div className="leftInner">
            <div className="leftActions mobileShow">
              <h4>Hi, I'm {isEditing ? <span>Coach </span> : null}{props.profile.firstName}</h4>
              <div className="buttonContainer">
                <Button className="buttonSecondary" onClick={() => {
                  history.push('/message-coach/' + (props.profile.customUrl ? props.profile.customUrl : props.profile.id));
                }}>Message</Button>
                <Button className="buttonPrimary" onClick={() => {
                  history.push('/book-session/' + (props.profile.customUrl ? props.profile.customUrl : props.profile.id));
                }}>Book a Session</Button>
              </div>
            </div>
            {uploadingImage ?
              <CircularProgress></CircularProgress> :
              <div>
                {props.profile && props.profile['profileImage'] ?
                  <div className="coachProfileHeaderAvatar" style={{ backgroundImage: "url('" + CLOUDFRONT_UPLOADS + props.profile['profileImage'] + "')" }}></div>
                  :
                  <div className="coachProfileHeaderAvatar" style={{ backgroundImage: "url('" + blank_avatar + "')" }}></div>
                }
              </div>
            }
            {!props.public ?
              <ImageUploader
                singleImage={true}
                withIcon={false}
                withLabel={false}
                buttonText='Upload Image'
                onChange={onDrop}
                imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                maxFileSize={5242880}
              /> : null}

            <h5>{ProfileUtils.FormatCoachName(props.profile)}</h5>
            <ul className="checkList">
              <li>{checkIcon}Background Check</li>
              <li>{checkIcon}Contact Information</li>
              <li>{checkIcon}Experience Verified</li>
              {props.profile.weekAvailability ?
                <li>{timeIcon} {createAvailabilityString(props.profile.weekAvailability)}</li>
              : null}
            </ul>
            <div className="enforceBlock">We enforce a strict onboarding process to ensure all AU coaches are qualified and ready to train.</div>
          </div>
          {isEditing ?
            <Button className="buttonAlternate" style={{ background: Colors.alternateButton }} onClick={e => props.setPublic(!props.public)}>View Your {props.public ? 'Private' : 'Public'} Profile</Button> : null}
        </div>
        <div className="right">
          <div className="coachActions">
            <div className="leftActions mobileHide">
              <h4>Hi, I'm {isEditing ? <span>Coach </span> : null}{props.profile.firstName}</h4>
              <div className="buttonContainer">
                <Button className="buttonSecondary" onClick={() => {
                  history.push('/message-coach/' + (props.profile.customUrl ? props.profile.customUrl : props.profile.id));
                }}>Message</Button>
                <Button className="buttonPrimary" onClick={() => {
                  history.push('/book-session/' + (props.profile.customUrl ? props.profile.customUrl : props.profile.id));
                }}>Book a Session</Button>
              </div>
            </div>
            <div className="rightActions">
              ${props.profile.sessionRate}/<br />session
            </div>
          </div>
          {!props.public ?
            <div>
              <h4>Profile URL</h4>
              <a className="link text-large" href={profileUrl}>{profileUrl}</a>
              <br /><br />
              <div className="urlInstructions">
                <span className="text-large">Create a custom URL and link it on your Facebook/Instagram profiles!</span>
                <br />
                <span className="text-small text-thin">Must be at least 5 characters and may only contain alphanumeric characters and hyphens</span>
              </div>
              <div className="customUrlContainer">
                <span className="link-no-pointer text-small">www.athletesuntapped.com/coach-profile/</span>
                <input
                  type="text"
                  className="textBox"
                  maxLength={150}
                  value={customUrl}
                  onChange={e => {
                    const value = e.target.value.replace(/[^0-9a-zA-Z-]/g, '').toLowerCase();
                    setCustomUrl(value);
                  }}>
                </input>
                <div className="fieldSaveContainer">
                  <Button disabled={!customUrl || customUrl.length < 5} onClick={e => saveCustomUrl()} className="buttonPrimary">Save</Button>
                </div>
              </div>
            </div>
            : null}
          <h4>Why Train With Me?</h4>
          {!props.public ?
            <div>
              <textarea minLength={180} className={`${props.public ? 'public' : ''} elevatorPitch`}
                value={props.pitch}
                placeholder="Write your elevator pitch here..."
                onChange={e => {
                  props.stateFunctions["elevPitch"](e.target.value);
                  props.setUploadVals({
                    ...props.uploadVals,
                    "elevPitch": e.target.value
                  });
                }} />
              {!props.pitch || props.pitch.length < 180 ? <span className="warning">You must enter at least 180 characters.</span> : null}
              <div className="fieldSaveContainer">
                <Button disabled={!props.pitch || props.pitch.length < 180} onClick={e => props.submit(e)} className="buttonPrimary">Save</Button>
              </div>
            </div>
            :
            <div className="elevatorPitch">{props.pitch}</div>
          }
          <ul className="achievedList">
            {isSuper ?
                <li>
                  {thumbUpIcon} Supercoach
                  <Help style={{cursor: 'pointer'}} onClick={()=>setShowSuperCoachHelp(true)} />
                </li>
                : null}
            {props.profile.locationInfo ? <li>{homeIcon} {props.profile.locationInfo.city}, {props.profile.locationInfo.state}</li> : null}

            {/* mycode  */}
            {props.profile.sport.map((val) => {
            const sport = sports.find((s) => s.value === val);
            return (
              <li key={val}>
                {sport?.icon} Private {sport?.label} Coach
              </li>
            );
            })}
            {/* {props.profile.sport.map((val) => {
              const icon = getSportIcon(val);
              return <li>{icon} Private {val} Coach</li>  
            })}             */}
            <li>{starIcon} {props.numRatings} Review{props.numRatings !== 1 ? 's' : ''}</li>
          </ul>
        </div>
      </div>
      <Snackbar open={error != null} onClose={e => setError(null)}>
        <Alert onClose={e => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>

      <Dialog className={"profileDialog"} onClose={() => setShowSuperCoachHelp(false)} open={showSuperCoachHelp} fullWidth={false} >
        <DialogTitle disableTypography className="coachAgreementDialogTitle" onClose={() => setShowSuperCoachHelp(false)}>
          <h2>Supercoach</h2>
          <IconButton onClick={() => setShowSuperCoachHelp(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          Our Supercoaches are the best of the best. They go above and beyond the normal expectations of an Athletes Untapped coach. When you see the supercoach label, know you'll be training with someone who's a highly-ranked member of the AU community.
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CoachProfileHeader;