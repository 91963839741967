import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../screens/App/App";
import './AthleteDashboard.scss';
import {completeEvent, getAthleteEvents, getCompletedEvents, getMessages, getPendingReviews, updateEvent} from '../../api/api';
import "../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import {CircularProgress, Dialog} from '@material-ui/core';
import CoachVideosSessionPanel from '../CoachVideosSessionPanel/CoachVideosSessionPanel';
import SearchForCoaches from '../SearchForCoaches/SearchForCoaches';
import {Link} from "react-router-dom";
import Colors from "../../constants/colors";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CoachCalenderDialog from "../CoachCalenderEvent/CoachCalenderEvent";
import {CLOUDFRONT_UPLOADS} from "../../constants/hosts";
import blank_avatar from "../CoachProfile/avatar.png";
import Button from "@material-ui/core/Button";
import ReviewForm from "../ReviewForm/ReviewForm";
import ProfileUtils from "../../utils/ProfileUtils";


const refreshUpcomingEvents = (user, setEvents, setClients) => {
  user.firebaseUser.getIdToken()
    .then(async function (idToken) {
      try {
        const response = await getAthleteEvents(idToken, user.firebaseUser.uid, Date.now());
        setEvents(response['events'].concat(response['pendingEvents'].concat(response['personalEvents'])));
        // Added pending coaches to the coaches object, otherwise the accept flow breaks for the first event
        const coaches = Object.assign(response['coaches'], response['pendingCoaches']);
        setClients(coaches);
      } catch (e) {
        return console.error('Error refreshing upcoming events', e);
      }
    });
};


const AthleteDashboard = props => {
  const {user} = useContext(AuthContext);
  const [messages, setMessages] = useState({});
  const [conversations, setConversations] = useState({});
  const [events, setEvents] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [, setSessionsByCoach] = useState({});
  const [coaches, setCoaches] = useState({});
  const [coachesCopy, setCoachesCopy] = useState({});
  const [selectedEvent, setSelectedEvent] = useState({});
  const [showEventDialog, setShowEventDialog] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackBar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pendingReviews, setPendingReviews] = useState([]);
  const [promptForReview, setShowPromptForReview] = useState({});

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getCompletedEvents(idToken, user.firebaseUser.uid, 'athlete')
          .then(response => {
            console.log('response', response);
            setSessions(response['completedEvents']);
            setSessionsByCoach(response['completedEventsByClient']);
            //setCoaches(coaches.concat(response['coaches']));
            setCoaches(Object.assign(coachesCopy, response['coaches']));
          })
          .catch(e => console.log(e));
      });
  }, [coachesCopy, user.firebaseUser]);

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getPendingReviews(idToken, user.firebaseUser.uid)
          .then(response => {
            setPendingReviews(response['pending']);
            console.log('pending reviews', response);
            const params = new URLSearchParams(window.location.search);
            if(response['pending'].length > 0 && params.has('reviewCoachId')) {
              response['pending'].forEach((pendingItem) => {
                const reviewCoachId = params.get('reviewCoachId');
                if(pendingItem.id === reviewCoachId) {
                  setShowPromptForReview({id: reviewCoachId, rating: params.has('rating') ? params.get('rating') : 0});
                }
              })
            }
          })
          .catch(e => console.log(e));
      });
  }, [user.firebaseUser]);

  useEffect(() => {
    refreshUpcomingEvents(user, setEvents, setCoaches);
  }, [user, user.firebaseUser]);

  useEffect(() => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        getMessages(idToken, user.firebaseUser.uid)
          .then(response => {
            setMessages(response.messages);
            setConversations(response.conversations);
          })
          .catch(error => console.log(error));
      });
  }, [user.firebaseUser]);


  const eventActionHandler = (eventID, updates, updater) => {
    // if (updates['startTime'] > updates['endTime'] - 3600000/4) {
    //   setNewEventSnackbarError(true);
    //   return;
    // }
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        updateEvent(idToken, eventID, updates, updater)
          .then(response => {
            refreshUpcomingEvents(user, setEvents, setCoachesCopy);
          })
          .catch(e => {
            setErrorMessage('There was an error updating this event.');
            setOpenErrorSnackBar(true);
          });
      });
  };

  const markEventComplete = (eventID, updates, completer) => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        completeEvent(idToken, eventID, updates, completer)
          .then(response => {
            refreshUpcomingEvents(user, setEvents, setCoachesCopy);
          })
          .catch(e => {
            setErrorMessage('There was an error completing this event.');
            setOpenErrorSnackBar(true);
          });
      });
  };
  console.log('selectedEvent', selectedEvent, 'coaches', coaches)

  return (
    <div>
      <div className="coachDashboardWrapper athleteDashboardWrapper">

        <h1>Welcome, {ProfileUtils.FormatAthleteFirstName(user.profile)}!</h1>

        <div className="columns">
          <div className="col1">
            <div>
              {pendingReviews && pendingReviews.length > 0 ?
                <div>
                  <h5>Review Coaches</h5>
                  <span className="text-thin text-large">Now that your first session is over, help your coach by leaving a review!</span>
                  <br/><br/>
                  {pendingReviews.map((item) => {
                    return (
                      <div className="pendingReviewBox">
                        {(item['profileImage']) ?
                          <img src={CLOUDFRONT_UPLOADS + item['profileImage']} alt="Profile" className="userAvatar"/>
                          :
                          <img src={blank_avatar} alt="Blank Avatar" className="userAvatar"/>
                        }
                        <div>
                          <h6>{item['firstName']}</h6>
                          <Button className="buttonPrimary" onClick={() => setShowPromptForReview({id: item['id']})}>Write Review</Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                : null}
              {events && coaches ?
                <div>
                  <div className="headerContainer">
                    <h5>Upcoming Sessions</h5>
                    <Link to="/calendar" className="link text-large">View Full Calendar</Link>
                  </div>
                  {coaches && events && events.length === 0 ?
                    <div>
                      <span className="text-thin text-large">No upcoming sessions</span>
                      <SearchForCoaches/>
                    </div>
                    :
                    <div className="sessions boxedList">
                      {events.slice(0, 3).map((event, i) => {
                        const isPending = event.athleteStatus === 'pending';
                        const date = new Date(event['startTime']);
                        const coach = coaches[event['coach']];
                        console.log('coach', event);
                        return (
                          <div className="boxedListItem session">
                            <div className="leftContent text-large">
                              <span className='text-bold'>{isPending ? 'Request - ' : coach ? 'Confirmed - ' : ''}{coach ? coach['firstName'] + ' ' + coach['lastName'].substr(0, 1) : "Personal Event"}</span>
                              <br/>
                              <span className="text-thin">{date.toLocaleString('default', {dateStyle: 'medium', timeStyle: 'short'})}</span>
                            </div>
                            <div className="rightContent" style={{color: Colors.secondary}}>{isPending ?
                              <span className="respondLink link" onClick={() => {
                                console.log('event', event);
                                setSelectedEvent(event);
                                setShowEventDialog(true);
                              }}>Respond</span> : coach ? 'Booked' : null}</div>
                          </div>
                        );
                      })}
                    </div>
                  }
                </div>
                : <CircularProgress/>}
              {conversations !== {} ?
                <div>
                  <div className="headerContainer">
                    <h5>Recent Messages</h5>
                    <Link to="/inbox" className="link text-large">View Inbox</Link>
                  </div>
                  {conversations.length === 0 ?
                    <span className="text-thin text-large">No recent messages</span> :
                    <div className="boxedList messages">
                      {Object.keys(conversations).slice(0, 3).map((item, i) => {
                        const date = new Date(messages[item][messages[item].length - 1]['time']);
                        return (
                          <div className="boxedListItem message">                            
                            <div className="content text-large text-thin">
                            <span className='text-bold'>{conversations[item]['firstName'] + ' ' +  conversations[item]['lastName']}</span>: {messages[item][messages[item].length - 1]['content']}
                              <br/>
                              <span className="messageType text-small" style={{color: Colors.secondary}}>
                                {messages[item][messages[item].length - 1]['from'] === user.firebaseUser.uid ? 'Sent' : 'Received'} -
                                <span>{date.toLocaleDateString() + ' ' + date.toLocaleString([], {timeStyle: 'short'})}</span>
                              </span>
                            </div>

                          </div>


                        );
                      })}
                    </div>}
                </div> : <CircularProgress/>}
            </div>
          </div>
          <div className="col2">

            {!sessions ?
              null :
              <div>
                {sessions.length === 0 ?
                  <div>
                    <h5>No Recent Videos</h5>
                    <span className="text-large">
                    No videos have been uploaded yet.
                    Once you have completed a session with a coach,
                    you can start viewing and responding to video feedback in the "Videos" tab.</span>
                  </div>
                  :
                  <h5>Recent Sessions</h5>
                }
                {sessions.length > 0 ?
                  <CoachVideosSessionPanel
                    session={sessions[0]}
                    for={'athlete'}
                    clientID={sessions[0]['coach']}
                    autoExpand={true}
                    client={coaches[sessions[0]['coach']]}
                  /> : null}
                {sessions.length > 1 ?
                  <CoachVideosSessionPanel
                    session={sessions[1]}
                    for={'athlete'}
                    clientID={sessions[1]['coach']}
                    autoExpand={true}
                    client={coaches[sessions[1]['coach']]}
                  /> : null}
                {sessions.length > 2 ?
                  <CoachVideosSessionPanel
                    session={sessions[2]}
                    for={'athlete'}
                    clientID={sessions[2]['coach']}
                    autoExpand={true}
                    client={coaches[sessions[2]['coach']]}
                  /> : null}
              </div>}
          </div>
        </div>
      </div>

      <Snackbar open={openSuccessSnackbar}  onClose={e => setOpenSuccessSnackBar(false)}>
        <Alert onClose={e => setOpenSuccessSnackBar(false)} severity="success">
          {//successMessage
          }
        </Alert>
      </Snackbar>
      <Snackbar open={openErrorSnackbar}  onClose={e => setOpenErrorSnackBar(false)}>
        <Alert variant="filled" onClose={e => setOpenErrorSnackBar(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      {showEventDialog ?
        <CoachCalenderDialog
          open={showEventDialog}
          setOpen={setShowEventDialog}
          selectedEvent={selectedEvent}
          completeEvent={markEventComplete}
          updateEvent={eventActionHandler}
          for={"athlete"}
          name={true ? null : coaches[selectedEvent['coach']]['firstName'] + ' ' + coaches[selectedEvent['coach']]['lastName']}
        />
        : null}

      <Dialog onClose={() => setShowPromptForReview({})} open={promptForReview.id}>
        <ReviewForm coachId={promptForReview.id} rating={promptForReview.rating ? promptForReview.rating : 0} onComplete={() => window.location.reload(false)}/>
      </Dialog>

    </div>
  );
};

export default AthleteDashboard;
