import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../screens/App/App";
import {awsPresignedPost, saveVideoMetadata} from '../../api/api';
import './CoachVideosClientPanel.scss';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {CircularProgress} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from "@material-ui/core/Button";


const materialTheme = createTheme();

const CoachVideosClientPanel = props => {

  const {user} = useContext(AuthContext);
  const [videoNotes, setVideoNotes] = useState([]);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [selectedEventID, setSelectedEventID] = useState("");
  const [processing, setProcessing] = useState(false);
  const [uploadData, setUploadData] = useState({
    'coach': props.for === "coach" ? user.firebaseUser.uid : props.clientID,
    'uploadDate': null,
    'bucketName': 'athletes-untapped-prod-uploads',
    'athlete': props.for === "coach" ? props.clientID : user.firebaseUser.uid
  });


  const onUploadChange = e => {
    console.log(e.target.files[0]);
    setUploadData({
      ...uploadData,
      'uploadDate': e.target.files[0] ? e.target.files[0]['lastModified'] : null,
      'objectKey': e.target.files[0] ? ('videos/' + e.target.files[0]['name']) : null,
      'fileName': e.target.files[0]['name']
    });
    setFileToUpload(e.target.files[0]);

  };

  const uploadFile = (e) => {
    e.preventDefault();
    setProcessing(true);

    if (!uploadData['uploadDate'] || !uploadData['objectKey']) {
      console.log('Pick a file to upload.');
      setProcessing(false);
      return;
    }
    if (selectedEventID === "") {
      console.log('Please associate your video with a session.');
      setProcessing(false);
      return;
    }
    setUploadData({
      ...uploadData,
      'videoComments': videoNotes,
      'event': selectedEventID
    });
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        awsPresignedPost(idToken, uploadData)
          .then(response => {
            console.log(response);
            sendToAWS(response['url']);
            //callback(response['url'])
          })
          .catch(e => console.log(e));
      });
  };


  const sendToAWS = async url => {
    const response = await fetch(
      new Request(url, {
        method: 'PUT',
        body: fileToUpload,
        headers: new Headers({
          'Content-Type': 'video/*',
        }),
      }),
    );
    if (response.status !== 200) {
      // The upload failed, so let's notify the caller.
      console.log(response);
      setFileToUpload(null);
      setVideoNotes("");
    } else {
      const fileName = getFileName(url)
      // Upload to S3 succeeded
      console.log(response);
      const metadata = {
        'uploadBucket': uploadData['bucketName'],
        'uploadLocation': 'videos/'+ fileName.substring(0, fileName.indexOf('.')),
        'uploadDate': uploadData['uploadDate'],
        'coach': props.for === "coach" ? user.firebaseUser.uid : props.clientID,
        'athlete': props.for === "coach" ? props.clientID : user.firebaseUser.uid,
        'actualBucket': 'athletes-untapped-prod',
        'actualLocation': 'transformed-session/' + fileName.substring(0, fileName.indexOf('.')),
        'fileName': fileName,
        'fileType': fileToUpload['type'],
        'event': selectedEventID,
        'videoComments': videoNotes
      };
      saveVideo(metadata);
    }
  };

  function getFileName(url)
  {
    return url.split('/').pop().split('#')[0].split('?')[0];
  }

  const saveVideo = (metadata) => {
    user.firebaseUser.getIdToken()
      .then(function (idToken) {
        saveVideoMetadata(idToken, metadata)
          .then(response => {
            console.log(response);
            setProcessing(false);
            setFileToUpload(null);
            setVideoNotes("");
            if(props.setShowAddVideoDialog) {
              props.setShowAddVideoDialog(false);
            }
            //cheat and reload page for now
            window.location.reload();
          })
          .catch(e => {
            console.log(e);
            setProcessing(false);
            setFileToUpload(null);
            setVideoNotes("");
          });
      });
  };

  useEffect(() => {
    if (props.selectedSession) {
      setSelectedEventID(props.selectedSession);
      console.log('session selected', props.selectedSession);
    }
  }, [props.selectedSession]);

  return (
    <div className="coachVideoClientPanel">
      <div className="CoachVideosClientPanelContainer">
        {!processing ?
          <div className="CoachVideosClientPanelUpload">
            <h5>Adding Video For: {props.client ? props.client.firstName + ' ' + props.client.lastName : null}</h5>
            <div class="videoUploadButton">
              {!fileToUpload ?
                <label className="CoachVideosClientPanelUploadLabel">
                  <input type="file" onChange={e => onUploadChange(e)}/>
                  Select Video
                </label> :
                <label className="CoachVideosClientPanelUploadLabel">
                  {fileToUpload['name']}
                </label>}
              {fileToUpload ?
                <HighlightOffIcon
                  onClick={e => setFileToUpload(null)}
                  className="RemoveSelectedFileFromUploadButton"/>
                : null}
            </div>
            <textarea placeholder="Add comments..." onChange={e => setVideoNotes([{
              'content': e.target.value,
              'to': props.clientID,
              'from': user.firebaseUser.uid,
              'time': Date.now()
            }])}/>
            <Button className="buttonPrimary" onClick={e => uploadFile(e)}>Upload</Button>
          </div> :
          <div className="CoachVideosClientPanelUploadProcessing">
            <ThemeProvider theme={materialTheme}>
              <CircularProgress/>
              <p className="ClientPanelProcessingText">Uploading...</p>
            </ThemeProvider>
          </div>}
      </div>
    </div>
  );
};

export default CoachVideosClientPanel;