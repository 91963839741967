import React, { useEffect, useState } from "react";
import * as firebase from 'firebase/app';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import './Login.css';
import logo from '../HomeNav/AULogoSmall.png';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { getProfile } from "../../api/api";
import ForgotPassword from "../ForgotPassword/ForgotPassword.js";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      border: '0',
      borderColor: 'white',
      borderRadius: '0'
    },
  },

  textField: {
    margin: '0px 0px 1.5rem 0px',
    display: 'block',
    textAlign: 'center'
  },

}));

const LoginInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'lightslategray',
        borderRadius: '0',
        borderWidth: '1.2px'
      },
      '&:hover fieldset': {
        borderColor: 'green',
        borderWidth: '2px'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
        borderWidth: '2px'
      },
    },
  },

})(TextField);

const Login = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setErrors] = useState("");
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);


  const loginStyleClasses = useStyles();
  useEffect(() => {
    firebase.auth().onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {

        firebaseUser.getIdToken()
          .then(function (idToken) {
            return getProfile(idToken, firebaseUser.uid)
          })
          .then(res => {
            const params = new URLSearchParams(window.location.search);
            if (!params.has('redirectTo') && !props.returnAfterLogin) {
              window.location = '/dashboard';
            }
          })
      }
    });
  }, [history, props]);



  const handleForm = e => {

    e.preventDefault();

    if (!email || email === "") {
      setErrors("Please enter an email.");
      setOpenSnackBar(true);
      return;
    }

    if (!password || password === "") {
      setErrors("Please enter a password.");
      setOpenSnackBar(true);
      return;
    }

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        return firebase.auth().signInWithEmailAndPassword(email, password)
      })
      .then(res => {
        // Login successful - do any login logic here

        // Note: we don't need to manually change the URL path here because onAuthStateChange
        // in App.js will get triggered asynchronously by Firebase as the user just logged in. 
        // When it does, the App component will re-render and display the athlete or coach app
      })
      .catch(e => {
        console.log(e);
        var errorMessage = "There was an error while logging in. Please try again.";
        if (e.code === "auth/invalid-email") {
          errorMessage = "The email entered is blank or invalid."
        }
        if (e.code === "auth/user-disabled") {
          errorMessage = "The user corresponding to this email has been disabled."
        }
        if (e.code === "auth/user-not-found") {
          errorMessage = "The user corresponding to the given email has not signed up yet."
        }
        if (e.code === "auth/user-not-found") {
          errorMessage = "There is no user corresponding to the given email."
        }
        if (e.code === "auth/wrong-password") {
          errorMessage = "The password is incorrect or does not match with the given email."
        }
        setErrors(errorMessage);
        setOpenSnackBar(true);
      });
  };

  const openForgotPasswordHandler = () => {
    setShowForgotPassword(true);
  };

  const closeForgotPasswordHandler = () => {
    setShowForgotPassword(false);
  };

  return (

    <form onSubmit={e => handleForm(e)} className="loginFormControl">

      <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
        <img src={logo} alt="Athletes Untapped" className="AULogoLogin" />
        <p className="loginText">Log In</p>
        <hr style={{ width: '45%', border: '1px solid gray', marginBottom: '1.5rem' }} />
      </div>
      <LoginInput
        className={loginStyleClasses.textField}
        value={email}
        onChange={e => setEmail(e.target.value)}
        name="email"
        type="email"
        placeholder="Email"
        variant="outlined"
        inputProps={{ style: { boxShadow: 'none', fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif' } }}
      />
      <LoginInput
        className={loginStyleClasses.textField}
        onChange={e => setPassword(e.target.value)}
        name="password"
        value={password}
        type="password"
        placeholder="Password"
        variant="outlined"
        inputProps={{ style: { boxShadow: 'none', fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif' } }}
      />
      <div className="loginButton">
        <button type="submit">Login</button>
        <p >Forgot Password? <Link to='#' className="linkSwitchDialog" onClick={openForgotPasswordHandler}>Reset Here</Link>
          <Dialog
            onClose={closeForgotPasswordHandler}
            open={showForgotPassword}
            fullWidth="true"
            maxWidth="sm"
          >
            <DialogContent dividers>
              <ForgotPassword closeReset={closeForgotPasswordHandler} />
            </DialogContent>
          </Dialog></p>
        <p >Don't have an Account? <a className="linkSwitchDialog" href="/join">Sign Up</a></p>
      </div>
      <Snackbar open={openSnackbar} onClose={e => setOpenSnackBar(false)}>
        <Alert onClose={e => setOpenSnackBar(false)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default withRouter(Login);
