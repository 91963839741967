import React from 'react';
import {CLOUDFRONT_UPLOADS} from "../../constants/hosts";


const PrivacyPolicy = props => {
  return (
    <div>
      <div className="basicContentPage">
        <div>
          <h2>Coach Agreement</h2>
          <img className="logo" alt="" src={CLOUDFRONT_UPLOADS + 'static/au_logo_simple.jpg'}/>
          <p>
            Athletes Untapped, Inc.
          </p>
          <p className="p1">Athletes Untapped Final Coaches Agreement</p>
          <p className="p1">Last Updated: Monday, October 10, 2022</p>
          <p className="p2">This agreement (this "<strong>Agreement</strong>") sets forth the terms and conditions
            whereby a successful applicant to the Athletes Untapped coaching program (&ldquo;
            <strong>You</strong>&rdquo;) agree to provide certain peer-to-peer individualized training and coaching
            services to authorized users of Athletes Untapped LLC, a Pennsylvania Limited Liability Company (the
            "<strong>Company</strong>").</p>
          <p className="p2">Athletes Untapped LLC provides lead generation to independent sports and athletic training
            service providers (&ldquo;<strong>Third-Party Providers</strong>&rdquo;) of peer-to-peer individualized
            sports coaching and training services (&ldquo;<strong>Services</strong>&rdquo;) by pairing Third-Party
            Providers with local consumers of peer-to-peer individualized coaching and training services who have signed
            up to use the Athletes Untapped Platform (&ldquo;<strong>User</strong>&rdquo; or &ldquo;
            <strong>Users</strong>&rdquo;) by allowing both providers and consumers access to the online database that
            facilitates seeking, receiving, and fulfilling User requests for Services from Third-Party Providers
            (&ldquo;<strong>Platform</strong>&rdquo;).</p>
          <p className="p2">You desire to enter into this Agreement for the purpose of accessing and using the
            Platform&rsquo;s services to provide to Users of the Platform.</p>
          <p className="p2">You acknowledge and agree that Company is a technology services provider that does not
            provide peer-to-peer individualized coaching and training services.</p>
          <p className="p2">In order to use the Platform, You must agree to terms set forth in the Agreement below.<span
              className="Apple-converted-space">&nbsp;</span></p>
          <p className="p2">Upon acceptance (electronic or otherwise) of this Agreement, You and Company shall be bound
            by the terms set forth herein.</p>
          <p className="p3"><span className="s1">USE OF THE ATHLETES UNTAPPED </span><span
              className="s2">PLATFORM</span>.</p>
          <p className="p4">You must be eighteen (18) years of age and successfully complete a background check
            administered by Certn.Co in order to use the Platform. Company has the sole and utmost discretion to refuse
            to allow use of the Platform to applicants that do not pass the required background check. Company may also
            deny access to the Platform if new information comes to light after a background check has been successfully
            passed.</p>
          <p className="p4">You warrant that You have the required skill, experience, and qualifications to perform the
            Services. An example of the skills required may include, but are not limited to status as a current college
            athlete, former college athlete, current pro athlete, former pro athlete, former or current college coach,
            or experienced college trainer, with the training and knowledge sufficient to carry out the duties required
            of the Services.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p4">You shall perform the Services in a professional and workmanlike manner in accordance with
            best industry standards for similar services, and You shall devote sufficient resources to ensure that the
            Services are performed in a timely and reliable manner.<span className="Apple-converted-space">&nbsp;</span>
          </p>
          <p className="p4">You acknowledge and agree that Your provision of Services to Users creates a direct business
            relationship between You and the User. Company is not responsible or liable for the actions of inactions of
            a User in relation to Your Services. You shall have the sole responsibility for any obligations or
            liabilities to Users or third parties that arise from Your provision of Services.</p>
          <p className="p4">You acknowledge and agree that You are solely responsible for taking such precautions that
            may be reasonable and proper (including maintaining adequate insurance that meets the requirements of all
            applicable laws) pertaining to any acts, omissions, or other liabilities incurred during the provision of
            Services and communication to a User.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p4">You acknowledge and agree that You will provide Services in a manner that enhances the
            safety of a User.</p>
          <p className="p4">You acknowledge and agree that User will be prompted to rate You after the provision of
            Services.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p4">You acknowledge and agree that Company&rsquo;s provision to You of access to the Platform
            creates a direct business relationship between Company and You. The Company shall not control the manner or
            means by which You perform the Services, including but not limited to the time and place You perform the
            Services and the methods that You use to perform the Services. However, You are encouraged respond to User
            requests to provide Services within 24 hours of being contacted.</p>
          <p className="p4">Company shall provide You with access to its materials, information, and systems to the
            extent necessary for the performance of the Services.</p>
          <p className="p4">You shall comply with all rules and procedures communicated to You in writing by the
            Company, including those related to safety, security, and confidentiality.</p>
          <p className="p4">You acknowledge that this is not a deal made in connection with your name, image, and
            likeness (&ldquo;NIL&rdquo;) rights and should not be construed as such.</p>
          <p className="p5"><span className="s2">TERM</span>.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p4">The term of this Agreement shall commence as of the date set forth above and shall continue
            indefinitely, and may be terminated by either party at any time, for any reason.<span
                className="Apple-converted-space">&nbsp;</span></p>
          <p className="p3">The arbitration provision shall survive the termination of the Agreement.</p>
          <p className="p3"><span className="s2">FINANCIAL TERMS</span>.</p>
          <p className="p4">Under this Agreement, Your financial rates for Your work providing Services are to be set
            individually by You, namely the amount charged for a session (&ldquo;Session Rates&rdquo;). Session Rates
            are to be charged on an hourly basis.</p>
          <p className="p4">As consideration for Your continued access and use of the Platform, You agree to remit 20%
            of a charged Session Rate to Company. As an example, and for the avoidance of doubt, if You charge a $100
            Session Rate for a session, you will remit $20 (20%) to Athletes Untapped as compensation for the access to
            the Platform.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p4">You agree that Company will collect payment for a session from a User and will remit payment
            to you automatically after verifying the completion of the session. Company will automatically withhold its
            20% fee as described in Section 3.2 before paying you for your completed session at your stated Session
            Rate.</p>
          <p className="p4">You acknowledge and agree that all client bookings will be handled through the
            Company&rsquo;s Platform. Failure to book sessions through the Platform may result in prohibition of access
            to the Platform, or any other further action Company may decide.</p>
          <p className="p4">You acknowledge that You will receive an IRS Form 1099-NEC from the Company, and that You
            shall be solely responsible for all federal, state, and local taxes.</p>
          <p className="p4">You are solely responsible for any travel or other costs or expenses incurred by You in
            connection with the performance of the Services, and in no event shall the Company reimburse You for any
            such costs or expenses.</p>
          <p className="p3"><span className="s1">RELATIONSHIP OF THE PARTIES</span>.</p>
          <p className="p4">You are an independent contractor of the Company, and this Agreement shall not be construed
            to create any association, partnership, joint venture, employment, or agency relationship between You and
            the Company for any purpose. You have no authority (and shall not hold Yourself out as having authority) to
            bind the Company and You shall not make any agreements or representations on the Company's behalf without
            the Company's prior written consent.</p>
          <p className="p4">Without limiting Section 4.1, You will not be eligible to participate in any vacation, group
            medical or life insurance, disability, profit sharing or retirement benefits, or any other fringe benefits
            or benefit plans offered by the Company to its employees, and the Company will not be responsible for
            withholding or paying any income, payroll, Social Security, or other federal, state, or local taxes, making
            any insurance contributions, including for unemployment or disability, or obtaining workers' compensation
            insurance on Your behalf. You shall be responsible for, and shall indemnify the Company against, all such
            taxes or contributions, including penalties and interest.<span
                className="Apple-converted-space">&nbsp;</span></p>
          <p className="p4">Subject to the terms and conditions of this Agreement, Company hereby grants you a
            non-exclusive, non-transferable, non-sublicensable, non-assignable license, during the term of this
            Agreement, to use the Athletes Untapped Platform solely for the purpose of providing Services to Users. All
            rights not expressly granted to you are reserved by Company.</p>
          <p className="p3"><span className="s1">CONFIDENTIALITY</span>.</p>
          <p className="p4">You acknowledge that You will have access to information that is treated as confidential and
            proprietary by the Company including without limitation technology and information pertaining to operations
            of the Company, in each case whether spoken, written, printed, electronic, or in any other form or medium,
            as well as personal information from clients of Company including name of Platform Users, Platform
            Users&rsquo; addresses, emails<span className="Apple-converted-space">&nbsp; </span>and other personally
            identifiable information (collectively, the "<strong>Confidential Information</strong>"). You agree to treat
            all Confidential Information as strictly confidential, not to disclose Confidential Information or permit it
            to be disclosed, in whole or part, to any third party without the prior written consent of the Company in
            each instance, and not to use any Confidential Information for any purpose except as required in the
            performance of the Services. You shall notify the Company immediately in the event You become aware of any
            loss or disclosure of any Confidential Information.</p>
          <p className="p3"><span className="s1">REPRESENTATIONS AND WARRANTIES</span>.</p>
          <p className="p4">You represent and warrant to the Company that:</p>
          <p className="p4">You have the right to enter into this Agreement, to grant the rights granted herein, and to
            perform fully all of Your obligations in this Agreement;</p>
          <p className="p4">Your entering into this Agreement with the Company and Your performance of the Services do
            not and will not conflict with or result in any breach or default under any other agreement to which You are
            subject;</p>
          <p className="p4">You have the required skill, experience, and qualifications to perform the Services, You
            shall perform the Services in a professional and workmanlike manner in accordance with best industry
            standards for similar services, and You shall devote sufficient resources to ensure that the Services are
            performed in a timely and reliable manner.</p>
          <p className="p4">You shall perform the Services in compliance with all applicable federal, state, and local
            laws and regulations, including by maintaining all licenses, permits, and registrations required to perform
            the Services;</p>
          <p className="p4">The Company hereby represents and warrants to You that:</p>
          <p className="p4">it has the full right, power, and authority to enter into this Agreement and to perform its
            obligations hereunder; and</p>
          <p className="p4">the execution of this Agreement by its representative whose signature is set forth at the
            end hereof has been duly authorized by all necessary corporate action.</p>
          <p className="p6"><span className="s2">PRIVACY</span></p>
          <p className="p4">Company may collect your personal data during the course of your application for the use of
            the Athletes Untapped Platform. Personal data may be stored, processed, and accessed by Company for business
            purposes, including for marketing, lead generation, service development, analytics, and other purposes
            consistent with the marketing needs of company. You expressly consent to the use of such personal data.</p>
          <p className="p3"><span className="s1">INDEMNIFICATION</span>.</p>
          <p className="p4">You shall defend, indemnify, and hold harmless the Company and its affiliates and their
            officers, directors, employees, agents, successors, and assigns from and against all losses, damages,
            liabilities, deficiencies, actions, judgments, interest, awards, penalties, fines, costs, or expenses of
            whatever kind (including reasonable attorneys' fees) arising out of or resulting from:</p>
          <p className="p4">bodily injury, death of any person, or damage to real or tangible, personal property
            resulting from Your acts or omissions;</p>
          <p className="p4">bodily injury, death of any person, or damage to real or tangible, personal property
            resulting from Your provision of Services under this agreement and in connection with the use of the
            Platform;</p>
          <p className="p4">Your breach of any representation, warranty, or obligation under this Agreement; or</p>
          <p className="p4">Your negligence or intentional violation of any law, without limitation.</p>
          <p className="p3"><span className="s2">INSURANCE</span>. During the Term, it is recommended that You shall
            maintain in force adequate insurance, with policy limits sufficient to protect and indemnify the Company and
            its affiliates, and each of their officers, directors, agents, employees, subsidiaries, partners, members,
            controlling persons, and successors and assigns, from any losses resulting from Your acts or omissions.
            Although Athletes Untapped does not mandate insurance is maintained, it is strongly recommended.</p>
          <p className="p3"><span className="s2">NON-SOLICITATION</span>. You agree that during the Term of this
            Agreement, You shall not make any solicitation to take any of the Company&rsquo;s clients from the company
            while the Term of this Agreement survives. A solicitation may include, but is not limited to, inducing a
            client of Company to work with You directly and not use the Platform to book services.</p>
          <p className="p3"><span className="s2">ASSIGNMENT</span>. You shall not assign any rights, or delegate or
            subcontract any obligations, under this Agreement without the Company's prior written consent. Any
            assignment in violation of the foregoing shall be deemed null and void. The Company may freely assign its
            rights and obligations under this Agreement at any time. Subject to the limits on assignment stated above,
            this Agreement will inure to the benefit of, be binding on, and be enforceable against each of the Parties
            hereto and their respective successors and assigns.</p>
          <p className="p5"><span className="s2">REMEDIES.</span> In the event You breach any section of this Agreement,
            You hereby acknowledge and agree that the Company shall be entitled to seek, in addition to other available
            remedies, a temporary or permanent injunction or other equitable relief restraining such breach or
            threatened breach from any court of competent jurisdiction, and that money damages would not afford an
            adequate remedy, without the necessity of showing any actual damages. This equitable relief shall be in
            addition to, not in lieu of, legal remedies, monetary damages, or other available forms of relief.</p>
          <p className="p5"><span className="s2">ARBITRATION.</span></p>
          <p className="p3">Any dispute, controversy, or claim arising out of or related to this Agreement or any breach
            or termination of this Agreement, including but not limited to the Services You provide to the Company, and
            any alleged violation of any federal, state, or local statute, regulation, common law, or public policy,
            whether sounding in contract, tort, or statute, shall be submitted to and decided by binding arbitration.
            Arbitration shall be administered by the American Arbitration Association and held in Philadelphia,
            Pennsylvania before a single arbitrator, in accordance with the American Arbitration Association's rules,
            regulations, and requirements. Any arbitral award determination shall be final and binding upon the Parties.
            Judgment on the arbitrator's award may be entered in any court of competent jurisdiction.</p>
          <p className="p4">Arbitration shall proceed only on an individual basis. The Parties waive all rights to have
            their disputes heard or decided by a jury or in a court trial and the right to pursue any class or
            collective claims against each other in court, arbitration, or any other proceeding. Each party shall only
            submit their own individual claims against the other and will not seek to represent the interests of any
            other person. The arbitrator shall have no jurisdiction or authority to compel any class or collective
            claim, or to consolidate different arbitration proceedings with or join any other party to an arbitration
            between the Parties. The arbitrator, not any court, shall have exclusive authority to resolve any dispute
            relating to the enforceability or formation of this Agreement and the arbitrability of any dispute between
            the Parties, except for any dispute relating to the enforceability or scope of the class and collective
            action waiver, which shall be determined by a court of competent jurisdiction.</p>
          <p className="p5"><span className="s2">GOVERNING LAW, JURISDICTION, AND VENUE. </span>This Agreement and all
            related documents, whether sounding in contract, tort, or statute for all purposes shall be governed by and
            construed in accordance with, the laws of the State of Pennsylvania (including its statutes of limitations,
            without giving effect to any conflict of laws principles that would cause the application of the laws of any
            other jurisdiction other to apply.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5"><span className="s1">MISCELLANEOUS</span>.</p>
          <p className="p4">This Agreement, together with any other documents incorporated herein by reference, and
            related exhibits and schedules, constitutes the sole and entire agreement of the Parties to this Agreement
            with respect to the subject matter contained herein, and supersedes all prior and contemporaneous
            understandings, agreements, representations, and warranties, both written and oral, with respect to such
            subject matter.</p>
          <p className="p4">This Agreement may only be amended, modified, or supplemented by an agreement in writing
            signed by each party hereto, and any of the terms thereof may be waived, only by a written document signed
            by each party to this Agreement or, in the case of waiver, by the party or parties waiving compliance.</p>
          <p className="p4">If any term or provision of this Agreement is invalid, illegal, or unenforceable in any
            jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision
            of this Agreement or invalidate or render unenforceable such term or provision in any other
            jurisdiction.</p>
          <p className="p4">This Agreement may be executed in multiple counterparts and by electronic signature, each of
            which shall be deemed an original and all of which together shall constitute one instrument.</p>
          <p className="p2">Very truly Yours,</p>
          <p className="p2">Athletes Untapped, LLC</p>
          <p className="p2">BY: Gene Williams, Founder/CEO</p>
          <p className="p2">Name: Gene Williams</p>
          <p className="p2">Title: Founder/CEO</p>
        </div>

      </div>
    </div>
  );
};

export default PrivacyPolicy;
