import React from 'react';
import ReadyToTrainForm from '../ReadyToTrainForm/ReadyToTrainForm';
import './GreenBorderBoxWithForm.css';

const GreenBorderBoxWithForm = props => {
    return (
        <div style={{margin: '20px'}}>
            <ul className="panelList">    
                <li>
                    <div>{props.header}</div>
                </li>
                <li>
                    <ReadyToTrainForm buttonText={props.buttonText} readyToTrain={props.readyToTrain} history/>
                </li>
            </ul>
        </div>
    );
}

export default GreenBorderBoxWithForm;