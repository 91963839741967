import "./App.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as firebase from "firebase/app";
import React, { useEffect, useState } from "react";
import { getProfile } from "../../api/api";
import AthleteApp from "../../components/AthleteApp/AthleteApp";
import CoachApp from "../../components/CoachApp/CoachApp";
import HomeNav from "../../components/HomeNav/HomeNav";

const environment = process.env.REACT_APP_FIREBASE_ENV || 'production';
const config = require(`../../firebase_config.${environment}.json`);
console.log(config);
firebase.initializeApp(config);

// auth creation for firebase email verification
export var auth = firebase.auth();


const spinnerTheme = createTheme({
  palette: {
    primary: {
      main: "#006400",
    },
  },
});

// Used throughout components to get the currently logged in user and profile
export const AuthContext = React.createContext(undefined);
export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
function App() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  function handleRedirect() {
    const params = new URLSearchParams(window.location.search);
    if (params.has("redirectTo")) {
      window.location = params.get("redirectTo");
      return true;
    } 
    return false;
  }

  // Inspired by: https://medium.com/@johnwcassidy/firebase-authentication-hooks-and-context-d0e47395f402
  useEffect(() => {
    const unsubscribe = onAuthStateChange((completeUser) => {
      console.log("auth changed");
      if (completeUser && handleRedirect()) {
        return;
      }
      setUser(completeUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  function onAuthStateChange(callback) {
    return firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        firebaseUser
          .getIdToken()
          .then(function (idToken) {
            return getProfile(idToken, firebaseUser.uid);
          })
          .then((res) => {
            const completeUser = { firebaseUser: firebaseUser, profile: res };
            callback(completeUser);

            setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
            callback(undefined);
            setLoading(false);

            // Make sure that the user is signed out if there is an issue at any point
            // in the login process
            firebase.auth().signOut();
          });
      } else {
        callback(firebaseUser);
        setLoading(false);
      }
    });
  }

  if (loading) {
    // This can be changed to a blank screen, spinner, etc..
    return (
      <div
        style={{ marginTop: "5rem", display: "flex", justifyContent: "center" }}
      >
        <ThemeProvider theme={spinnerTheme}>
          <CircularProgress />
        </ThemeProvider>
      </div>
    );
  }

  if (user && user.profile && user.profile.profileType === "athlete") {
    if (handleRedirect()) {
      return <div></div>;
    }
    return (
      <AuthContext.Provider value={{ user, setUser }}>
        <div className="App">
          <AthleteApp></AthleteApp>
        </div>
      </AuthContext.Provider>
    );
  } else if (user && user.profile && user.profile.profileType === "coach") {
    if (handleRedirect()) {
      return <div></div>;
    }
    return (
      <AuthContext.Provider value={{ user, setUser }}>
        <div className="App">
          <CoachApp></CoachApp>
        </div>
      </AuthContext.Provider>
    );
  } else {
    return (
      <AuthContext.Provider value={{ user, setUser }}>
        <div className="App">
          <HomeNav></HomeNav>
        </div>
      </AuthContext.Provider>
    );
  }
}

export default App;
