import React from 'react';


const FAQs = props => {
    return (
        <div>
            <div className="OurTeamTitle">
                <p>FAQs</p>
            </div>
            
            
        </div>
        
    );
}

export default FAQs;
