import React, { useRef, useEffect, useState } from 'react';
import { IconButton, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, ListItemText, Snackbar, Button, CircularProgress } from '@material-ui/core';
import { updateProfile, deleteTrainingLocation } from '../../api/api';
import { MoreVert, Add, Edit, Delete } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import mapboxgl from 'mapbox-gl';
import { SearchBox } from '@mapbox/search-js-react';

import 'mapbox-gl/dist/mapbox-gl.css';
import './CoachProfileMap.scss';

const CoachProfileMap = ({ user,refreshProfile, canEdit = false }) => {
  const mapContainerRef = useRef(null);
  const map = useRef(null);
  const [activeLocation, setActiveLocation] = useState(null);
  const [locations, setLocations] = useState(user.profile?.trainingLocations || []);
  const [editingId, setEditingId] = useState(null);
  const [editingName, setEditingName] = useState('');
  const [editingAddress, setEditingAddress] = useState('');
  const [editingLongitude, setEditingLongitude] = useState(null);
  const [editingLatitude, setEditingLatitude] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [open, setOpen] = useState(false);
  const [menuLocation, setMenuLocation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const searchBoxRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const popperModifiers = {
    preventOverflow: {
      enabled: true,
      boundariesElement: 'viewport',
    },
    flip: {
      enabled: true,
    },
  };

  useEffect(() => {
    try {
      if ((locations.length > 0 || canEdit) && !map.current) {
        console.log('locations', locations)
      mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
      map.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
      });

      const coordinatesArray = locations.map(location => [location.longitude, location.latitude]);
      if(coordinatesArray.length > 0) {
        var bounds = coordinatesArray.reduce(function(bounds, coord) {
            return bounds.extend(coord);
          }, new mapboxgl.LngLatBounds(coordinatesArray[0], coordinatesArray[0]));
          map.current.fitBounds(bounds, { padding: 20 });
          map.current.addControl(new mapboxgl.NavigationControl());
        }
      }
    } catch (error) {
      console.log('Error setting up map', error, error.stack)
    }
  }, [locations, canEdit]);

  useEffect(() => {
    if (user.profile?.trainingLocations && user.profile?.trainingLocations.length > 0) {
      setLocations(user.profile.trainingLocations);
    }
  }, [user.profile?.trainingLocations]);

  useEffect(() => {
    if (map.current) {
      const existingMarkers = document.getElementsByClassName('mapboxgl-marker');
      while(existingMarkers[0]) {
        existingMarkers[0].parentNode.removeChild(existingMarkers[0]);
      }

      locations.forEach((location) => {
        const marker = new mapboxgl.Marker({ color: 'darkgreen' })
          .setLngLat([location.longitude, location.latitude])
          .addTo(map.current);

        marker.getElement().addEventListener('click', () => {
          setActiveLocation(location.id);
          focusLocation(location);
        });
      });
    }
  }, [locations]);

  const focusLocation = (location) => {
    map.current.flyTo({
      center: [location.longitude, location.latitude],
      zoom: 14
    });
  };

  const addLocation = () => {
    if (locations.length < 3) {
      const newLocation = {
        id: "new",
        name: "New Location",
        address: "",
        longitude: null,
        latitude: null
      };
      setLocations([...locations, newLocation]);
      setEditingId(newLocation.id);
    }
  };

  const deleteLocation = async (id) => {
    try {
      const token = await user.firebaseUser.getIdToken()
      await deleteTrainingLocation(token, user.firebaseUser.uid, id);
      setLocations(locations.filter(loc => loc.id !== id));
      refreshProfile();
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error deleting location: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleToggle = (event, location) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuLocation(location);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleEdit = () => {
    const locationToEdit = menuLocation;
    setEditingId(locationToEdit.id);
    setEditingName(locationToEdit.name);
    setEditingAddress(locationToEdit.address);
    setEditingLongitude(locationToEdit.longitude);
    setEditingLatitude(locationToEdit.latitude);
    handleClose();
  };

  const handleDelete = () => {
    deleteLocation(menuLocation.id);
    handleClose();
  };

  const handleSave = async (name, address, longitude, latitude) => {
    setIsLoading(true);
    validateLocation();
    const updatedLocations = locations.map(loc => 
      loc.id === editingId ? {
        ...loc,
        name: name || editingName,
        address: address || editingAddress,
        longitude: longitude || editingLongitude,
        latitude: latitude || editingLatitude
      } : loc
    );

    try {
      const idToken = await user.firebaseUser.getIdToken();
      await updateProfile(idToken, { trainingLocations: updatedLocations }, user.firebaseUser.uid);
      clearEditingState();
      refreshProfile();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error saving location', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAutofillRetrieve = (result) => {
    try {
      if (result.features && result.features.length > 0) {
        const feature = result.features[0];
        const shouldNotReplaceName = feature.properties.feature_type === "address" && editingName !== '';
        const name = shouldNotReplaceName ? editingName : feature.properties.name;
        const address = feature.properties.full_address || feature.properties.address || '';
        const [longitude, latitude] = feature.geometry.coordinates;
        setEditingName(name);
        setEditingAddress(address);
        setEditingLongitude(longitude);
        setEditingLatitude(latitude);
      }
    } catch (error) {
      console.error('Error processing autofill result:', error);
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const validateLocation = () => {
    if (!editingLatitude || !editingLongitude) {
      setSnackbar({ open: true, message: 'Address needs to be selected from the dropdown', severity: 'error' });
      return;
    }
  }

  const clearEditingState = () => {
    setLocations(locations.filter(loc => loc.id !== "new"));
    setEditingId(null);
    setEditingName('');
    setEditingAddress('');
    setEditingLongitude(null);
    setEditingLatitude(null);
  };

  return (
    <div className="coach-profile-map">
      <h3>Training Locations</h3>
      <div ref={mapContainerRef} className="map-container" />
      <div className={`location-boxes ${canEdit ? 'edit' : ''}`}>
        {locations.map((location) => (
          <div
            key={location.id}
            className={`location-box ${activeLocation === location.id ? 'active' : ''} ${!editingId ? 'enable-hover' : 'edit'}`}
            onClick={() => {
              if (!editingId) {
                setActiveLocation(location.id);
                focusLocation(location);
              }
            }}
          >
            {editingId === location.id ? (
              isLoading ? (
                <div className="loading-container">
                  <CircularProgress size={40} />
                </div>
              ) : (
                <>
                  <div className="inputs">
                    <input
                      type="text"
                      value={editingName}
                      onChange={(e) => setEditingName(e.target.value)}
                      placeholder="Location name"
                      className="name-input"
                    />
                    <SearchBox
                      ref={searchBoxRef}
                      onRetrieve={handleAutofillRetrieve}
                      map={map.current}
                      mapboxgl={mapboxgl}
                      marker
                      accessToken={process.env.REACT_APP_MAPBOX_KEY}
                      onChange={(newValue) => setEditingAddress(newValue)}
                      placeholder={editingAddress || "Search"}
                      options={{
                        language: 'en',
                        country: 'US',
                        types: ['poi', 'place', 'address']
                      }}
                      theme={{
                        icons: {
                          search: ""
                        },
                        variables: {
                          fontSize: '1rem',
                          padding: '10px',
                          border: '1px solid $color-gray',
                          borderRadius: '$border-radius-small',
                          boxShadow: 'none'
                        }
                      }}
                    />
                  </div>
                  <div className="buttons">
                    <Button 
                      className="close-button"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        clearEditingState();
                      }}
                    >
                      Close
                    </Button>
                    <Button 
                      className="save-button"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSave();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </>
              )
            ) : (
              <>
                <h3>{location.name}</h3>
                <p>{location.address}</p>
                {canEdit && !editingId && (
                  <IconButton
                    aria-controls="menu-list-grow"
                    aria-haspopup="true"
                    onClick={(e) => handleToggle(e, location)}
                    size="small"
                    className="menu-button"
                  >
                    <MoreVert fontSize="small" />
                  </IconButton>
                )}
              </>
            )}
          </div>
        ))}
        {locations.length === 0 && canEdit && 
        <>
          <Button 
            className="add-button" 
            size="medium"
            variant="outlined"
            onClick={addLocation}
            startIcon={<Add />}
            disabled={editingId}
          >
            Add a training location
          </Button>
          </>
        }
        {canEdit && locations.length < 3 && locations.length > 0 && (
          <IconButton 
            className="add-button" 
            size="medium"
            onClick={addLocation}
            disabled={editingId}
          >
            <Add />
          </IconButton>
        )}
      </div>
      <Popper 
        open={open} 
        anchorEl={anchorEl} 
        transition 
        disablePortal
        placement="bottom-start"
        modifiers={popperModifiers}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList 
                  autoFocusItem={open} 
                  id="menu-list-grow" 
                  style={{ maxWidth: '250px' }} // Adjust this value as needed
                >
                  <MenuItem onClick={handleEdit} className="menu-item">
                    <Edit className="menu-icon" fontSize="small" />
                    <ListItemText primary="Edit" />
                  </MenuItem>
                  <MenuItem onClick={handleDelete} className="menu-item">
                    <Delete className="menu-icon" fontSize="small" />
                    <ListItemText primary="Delete" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CoachProfileMap;
