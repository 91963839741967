import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import './DateAndTime.scss';

const materialTheme = createTheme({
  palette: {
    primary: {
      main: '#000'
    }
  }
});

const DateAndTime = props => {
  return (
    <ThemeProvider theme={materialTheme}>
      <div className="date-and-time-container">
        {props.showDatePicker && (
          <div className="date-picker-wrapper">
            <p className="picker-label">On</p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                className="date-picker"
                disableToolbar
                  variant="inline"
                  value={props.currentTimes?.['From']}
                  inputVariant="outlined"
                  inputProps={{width: '100%', borderRadius: '0px', borderColor: 'lightslategray'}}
                  onChange={newStartDate => {
                    const currentStart = props.currentTimes?.['From'];
                    const currentEnd = props.currentTimes?.['To'];
                    newStartDate.setHours(currentStart.getHours());
                    newStartDate.setMinutes(currentStart.getMinutes());
                    newStartDate.setSeconds(0);
                    newStartDate.setMilliseconds(0);
                    let newEndDate = new Date(newStartDate.valueOf());
                    newEndDate.setHours(currentEnd.getHours());
                    newEndDate.setMinutes(currentEnd.getMinutes());
                    newEndDate.setSeconds(0);
                    newEndDate.setMilliseconds(0);
                    props.setUpdates({
                      ...props.updates,
                      [props.slot]: {
                        'From': newStartDate,
                        'To': newEndDate
                      }
                    });
                    if (props.setAllCurrentTimes) {
                      props.setAllCurrentTimes({
                        ...props.allCurrentTimes,
                        [props.slot]: {
                          'From': newStartDate,
                          'To': newEndDate
                        }
                      });
                    }
                  }}
              />
            </MuiPickersUtilsProvider>
          </div>
        )}
        <div className="time-picker-wrapper">
          <p className="picker-label">From</p>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              className="time-picker"
              variant="inline"
              value={props.currentTimes?.['From']}
              minutesStep={15}
              inputVariant="outlined"
              inputProps={{width: '100%', borderRadius: '0px', borderColor: 'lightslategray'}}
              onChange={date => {
                const current = props.currentTimes?.['From'];
                const newDate = date instanceof Date ? date : new Date(date);
                
                if (current instanceof Date) {
                  newDate.setFullYear(current.getFullYear());
                  newDate.setMonth(current.getMonth());
                  newDate.setDate(current.getDate());
                  newDate.setSeconds(0);
                  newDate.setMilliseconds(0);
                }

                const newTo = (props.eventType === "Normal Session" || props.eventType === "") 
                  ? new Date(newDate.valueOf() + 3600000) 
                  : props.currentTimes?.['To'];

                props.setUpdates({
                  ...props.updates,
                  [props.slot]: {
                    'From': newDate,
                    'To': newTo
                  }
                });

                if (props.setAllCurrentTimes) {
                  props.setAllCurrentTimes({
                    ...props.allCurrentTimes,
                    [props.slot]: {
                      'From': newDate,
                      'To': newTo
                    }
                  });
                }

              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="time-picker-wrapper">
          <p className="picker-label">To</p>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              className="time-picker"
              variant="inline"
              value={(props.eventType === "Normal Session" || props.eventType === "") ? new Date(props.currentTimes?.['From'].valueOf() + 3600000) : props.currentTimes?.['To']}
                disabled={(props.eventType === "Normal Session" || props.eventType === "") ? true : false}
                minutesStep={15}
                inputVariant="outlined"
                inputProps={{width: '100%', borderRadius: '0px', borderColor: 'lightslategray'}}
                onChange={date => {
                  const current = props.currentTimes?.['To'];
                  const newDate = date instanceof Date ? date : new Date(date);

                  if (current instanceof Date) {
                    newDate.setFullYear(current.getFullYear());
                    newDate.setMonth(current.getMonth());
                    newDate.setDate(current.getDate());
                    newDate.setSeconds(0);
                    newDate.setMilliseconds(0);
                  }

                  const newTo = newDate.valueOf() < props.currentTimes?.['From'].valueOf()
                    ? new Date(props.currentTimes?.['From'].valueOf() + 900000)
                    : newDate;

                  props.setUpdates({
                    ...props.updates,
                    [props.slot]: {
                      'From': props.currentTimes?.['From'],
                      'To': newTo
                    }
                  });

                  if (props.setAllCurrentTimes) {
                    props.setAllCurrentTimes({
                      ...props.allCurrentTimes,
                      [props.slot]: {
                        'From': props.currentTimes?.['From'],
                        'To': newTo
                      }
                    });
                  }

                }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default DateAndTime;