import React from "react";
import AthleteNav from "../AthleteNav/AthleteNav";

export default function AthleteApp(props) {
  return (
    <div className="AthleteApp">
      <AthleteNav></AthleteNav>
      {/* You can put some type of footer here */}
    </div>
  );
}
