import React from "react";
import WhyThreeBoxPanel from "../WhyThreeBoxPanel/WhyThreeBoxPanel";
import BottomNav from "../BottomNav/BottomNav";
import "./AthletesAndParents.scss";
import { Check, Group, Mood, School } from "@material-ui/icons";
import { CLOUDFRONT_UPLOADS } from "../../constants/hosts";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import ReadyToTrainFormWide from "../ReadyToTrainFormWide/ReadyToTrainFormWide";

const AthletesAndParents = () => {
  const history = useHistory();

  return (
    <div>
      <MetaTags>
        <title>Find a Local Private Sports Coach</title>
        <meta
          name="description"
          content="Athletes Untapped | Connecting athletes with experienced private sports coaches. Search for coaches and start working towards your goals!"
        />
        <meta property="og:title" content="Find a Local Private Sports Coach" />
      </MetaTags>
      <div className="athletesParents">
        <div className="topBox topBoxWithForm">
          <h1>
            Find trusted, local coaches and take your game to the next level
          </h1>
          <div className="button">
            <ReadyToTrainFormWide buttonText="Find coaches" formType="search" />
          </div>
        </div>
        <div className="threeBoxesWrapper">
          <WhyThreeBoxPanel
            title="Why Choose an AU Coach?"
            page="AthletesandParents"
            box1={{
              header: "Convenience",
              caption:
                "Determine how far you are willing to travel for private training lessons.",
            }}
            box2={{
              header: "Experience",
              caption:
                "AU coaches excelled in their own athletic careers and are eager to work with the next generation.",
            }}
            box3={{
              header: "Results",
              caption:
                "Relay specific goals to your AU Coach and they will work with you to raise the bar.",
            }}
          />
        </div>
        <div className="basicGrayBox">
          <h2>Created for every athlete </h2>
          <School className="icon" />
          <h4>Scholarship seekers</h4>
          <p>
            Our coaches have played at the highest levels and want to help
            rising athletes follow in their footsteps.
          </p>
          <Group className="icon" />
          <h4>Team makers</h4>
          <p>
            Our coaches will make sure you are ready for next season by
            tailoring a training plan to your specific needs.
          </p>
          <Mood className="icon" />
          <h4>Game learners</h4>
          <p>
            Our coaches want to work with the next generation of athletes by
            instilling a baseline skill level and love for the game.
          </p>
        </div>
        <h2 className="promise">Our promises to you</h2>
        <div className="promiseBox">
          <div>
            <h6>No initiation fees</h6>
            Some coaching platforms charge parents a one-time fee to connect
            with their coaches. We don't. You only pay your AU coach for their
            hard work.. like it should be!
          </div>
          <div>
            <h6>AU Guarantee</h6>
            Find the right fit, or it's free. If you do not think your coach was
            the right fit after your first session, we will refund you the full
            payment. This policy covers the first session with a new coach.
          </div>
        </div>
        <div className="boxWithImageAndChecks">
          <img
            alt="Former college lacrosse player training young athletes and teaching them how to succeed at the next level"
            src={CLOUDFRONT_UPLOADS + "static/only-platform.jpg"}
          />
          <div className="content">
            <h3>The only platform you need</h3>
            <ul className="checkList">
              <li>
                <Check />
                Connect With a Private Coach
              </li>
              <li>
                <Check />
                Communicate Your Goals
              </li>
              <li>
                <Check />
                Book Training Sessions
              </li>
              <li>
                <Check />
                Manage Your Training Schedule
              </li>
              <li>
                <Check />
                Track Your Athletic Growth
              </li>
            </ul>
            <br />
            <Button
              className="buttonSecondary"
              onClick={() => history.push("/our-difference")}
            >
              Learn More
            </Button>
            <br />
          </div>
        </div>
        <div className="getStartedBox">
          <h2>Ready to get started?</h2>
          <div className="buttonContainer">
            <Button
              className="buttonPrimary"
              onClick={() => history.push("/coaches")}
            >
              Find coaches
            </Button>
            <Button
              className="buttonPrimary"
              onClick={() =>
                history.push({
                  pathname: "/apply",
                  state: { forAthlete: true },
                })
              }
            >
              Apply to coach
            </Button>
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default AthletesAndParents;
