import React from 'react';
import {CLOUDFRONT_UPLOADS} from "../../constants/hosts";
import BottomNav from "../BottomNav/BottomNav";


const PrivacyPolicy = props => {
  return (
    <div>
      <div className="basicContentPage">
        <div>
          <h1>Privacy Policy</h1>
          <img className="logo" alt="" src={CLOUDFRONT_UPLOADS + 'static/au_logo_simple.jpg'}/>
          <p>
            Athletes Untapped, Inc.
          </p>
          <p className="p1"><strong>Website Privacy Policy</strong></p>
          <p className="p2"><strong>Last modified: Monday, October 10, 2022</strong></p>
          <p className="p3">&nbsp;</p>
          <p className="p4"><span className="s1"><strong>Introduction</strong></span></p>
          <p className="p5">Athletes Untapped LLC (<strong>"Company"</strong> or<strong>&nbsp;"We"</strong>) respect
            your privacy and are committed to protecting it through our compliance with this policy.</p>
          <p className="p5">This policy describes the types of information We may collect from you or that you may
            provide when you visit the website www.athletesuntapped.com (our "<strong>Website</strong>") and our
            practices for collecting, using, maintaining, protecting, and disclosing that information.</p>
          <p className="p5">This policy applies to information We collect:</p>
          <p className="p6">On this Website.</p>
          <p className="p6">In email, text, and other electronic messages between you and this Website.</p>
          <p className="p5">It does not apply to information collected by:</p>
          <p className="p6">Us offline or through any other means, including on any other website operated by Company or
            any third party; or<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p6">Any third party, including through any application or content (including advertising) that
            may link to or be accessible from or through the Website.</p>
          <p className="p5">Please read this policy carefully to understand our policies and practices regarding your
            information and how We will treat it. If you do not agree with our policies and practices, your choice is
            not to use our Website. By accessing or using this Website, you agree to this privacy policy.<span
                className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">This policy may change from time to time (see <span className="s2">HYPERLINK \l "a286592"Changes to Our Privacy Policy</span>).
            Your continued use of this Website after We make changes is deemed to be acceptance of those changes, so
            please check the policy periodically for updates.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p7">&nbsp;</p>
          <p className="p4"><span className="s1"><strong>Children Under the Age of 18</strong></span></p>
          <p className="p5">Our Website is not intended for children under 18 years of age. No one under age 18 may
            provide any personal information to the Website. We do not knowingly collect personal information from
            children under 18. If you are under 18, do not use or provide any information on this Website. If We learn
            We have collected or received personal information from a child under 18 without verification of parental
            consent, We will delete that information. If you believe We might have any information from or about a child
            under 18, please contact us at community@athletesuntapped.com</p>
          <p className="p5">California residents under 16 years of age may have additional rights regarding the
            collection and sale of their personal information. Please see HYPERLINK \l "a820658"Your State Privacy
            Rights for more information.</p>
          <p className="p8">&nbsp;</p>
          <p className="p4"><span className="s1"><strong>Information We Collect About You and How We Collect It</strong></span>
          </p>
          <p className="p5">We collect several types of information from and about users of our Website, including
            information:</p>
          <p className="p6">By which you may be personally identified, such as name, zip code, email address, telephone
            number, and sport ("<strong>personal information</strong>");</p>
          <p className="p6">About your internet connection, the equipment you use to access our Website, and usage
            details.</p>
          <p className="p5">We collect this information:</p>
          <p className="p6">Directly from you when you provide it to us.</p>
          <p className="p6">Automatically as you navigate through the site. Information collected automatically may
            include usage details, IP addresses, and information collected through cookies.</p>
          <p className="p4"><em>Information You Provide to Us</em></p>
          <p className="p5">The information We collect on or through our Website may include:</p>
          <p className="p6">Information that you provide by filling in forms on our Website. This includes information
            provided at the time of registering to use our Website, subscribing to our service, or posting material. We
            may also ask you for information when you report a problem with our Website.</p>
          <p className="p6">Records and copies of your correspondence (including email addresses), if you contact
            us.</p>
          <p className="p6">Details of transactions you carry out through our Website and of the fulfillment of your
            orders.</p>
          <p className="p5">You also may provide information to be published or displayed (hereinafter,
            "<strong>posted</strong>") on public areas of the Website, or transmitted to other users of the Website or
            third parties (collectively, "<strong>User Contributions</strong>"). Your User Contributions are posted on
            and transmitted to others at your own risk. Please be aware that no security measures are perfect or
            impenetrable. Additionally, We cannot control the actions of other users of the Website with whom you may
            choose to share your User Contributions. Therefore, We cannot and do not guarantee that your User
            Contributions will not be viewed by unauthorized persons.</p>
          <p className="p4"><em>Information We Collect Through Automatic Data Collection Technologies</em></p>
          <p className="p5">As you navigate through and interact with our Website, We may use automatic data collection
            technologies to collect certain information about your equipment, browsing actions, and patterns,
            including:</p>
          <p className="p6">Details of your visits to our Website, including traffic data, location data, and other
            communication data and the resources that you access and use on the Website.</p>
          <p className="p6">Information about your computer and internet connection, including your IP address,
            operating system, and browser type.</p>
          <p className="p5">We also may use these technologies to collect information about your online activities over
            time and across third-party websites or other online services (behavioral tracking).</p>
          <p className="p5">The information We collect automatically may be only statistical data and does not include
            personal information, but We may maintain it or associate it with personal information We collect in other
            ways or receive from third parties. It helps us to improve our Website and to deliver a better and more
            personalized service, including by enabling us to:</p>
          <p className="p6">Estimate our audience size and usage patterns.</p>
          <p className="p6">Store information about your preferences, allowing us to customize our Website according to
            your individual interests.</p>
          <p className="p6">Speed up your searches.</p>
          <p className="p6">Recognize you when you return to our Website.</p>
          <p className="p5">The technologies We use for this automatic data collection may include:</p>
          <p className="p6"><strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard
            drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on
            your browser. However, if you select this setting you may be unable to access certain parts of our Website.
            Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies
            when you direct your browser to our Website.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p6"><strong>Web Beacons.</strong> Pages of our Website and our emails may contain small
            electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs)
            that permit the Company, for example, to count users who have visited those pages or opened an email and for
            other related website statistics (for example, recording the popularity of certain website content and
            verifying system and server integrity).<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">We do not collect personal information automatically, but We may tie this information to
            personal information about you that We collect from other sources or you provide to us.</p>
          <p className="p8">&nbsp;</p>
          <p className="p4"><span
              className="s1"><strong>Third-Party Use of Cookies and Other Tracking Technologies</strong></span></p>
          <p className="p5">Some content or applications, including advertisements, on the Website are served by
            third-parties, including advertisers, ad networks and servers, content providers, and application providers.
            These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies
            to collect information about you when you use our Website. The information they collect may be associated
            with your personal information or they may collect information, including personal information, about your
            online activities over time and across different websites and other online services. They may use this
            information to provide you with interest-based (behavioral) advertising or other targeted content.<span
                className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">We do not control these third parties' tracking technologies or how they may be used. If you
            have any questions about an advertisement or other targeted content, you should contact the responsible
            provider directly. For information about how you can opt out of receiving targeted advertising from many
            providers, see <span className="s2">HYPERLINK \l "a741158"Choices About How We Use and Disclose Your Information</span>.
          </p>
          <p className="p8">&nbsp;</p>
          <p className="p4"><span className="s1"><strong>How We Use Your Information</strong></span></p>
          <p className="p5">We use information that We collect about you or that you provide to us, including any
            personal information:</p>
          <p className="p6">To present our Website and its contents to you.</p>
          <p className="p6">To provide you with information, products, or services that you request from us.</p>
          <p className="p6">To fulfill any other purpose for which you provide it.</p>
          <p className="p6">To carry out our obligations and enforce our rights arising from any contracts entered into
            between you and us, including for billing and collection.</p>
          <p className="p6">To notify you about changes to our Website or any products or services We offer or provide
            though it.</p>
          <p className="p6">To allow you to participate in interactive features on our Website.</p>
          <p className="p6">ANY OTHER USE PURPOSES.</p>
          <p className="p5">We may use the information We have collected from you to enable us to display advertisements
            to our advertisers' target audiences. Even though We do not disclose your personal information for these
            purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser
            may assume that you meet its target criteria.</p>
          <p className="p3">&nbsp;</p>
          <p className="p4"><span className="s1"><strong>Disclosure of Your Information</strong></span></p>
          <p className="p5">We may disclose aggregated information about our users without restriction.<span
              className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">We may disclose personal information that We collect or you provide as described in this
            privacy policy:</p>
          <p className="p6">To our subsidiaries and affiliates.</p>
          <p className="p6">To contractors, service providers, and other third parties We use to support our business
            and who are bound by contractual obligations to keep personal information confidential and use it only for
            the purposes for which We disclose it to them.</p>
          <p className="p6">To a buyer or other successor in the event of a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or all of Athletes Untapped&rsquo;s assets,
            whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal
            information held by Athletes Untapped about our Website users is among the assets transferred.</p>
          <p className="p6">To third parties to market their products or services to you if you have not opted out of
            these disclosures. We contractually require these third parties to keep personal information confidential
            and use it only for the purposes for which We disclose it to them. For more information, see <span
                className="s2">HYPERLINK \l "a741158"Choices About How We Use and Disclose Your Information</span>.</p>
          <p className="p6">For any other purpose disclosed by us when you provide the information.</p>
          <p className="p6">With your consent.</p>
          <p className="p5">We may also disclose your personal information:</p>
          <p className="p6">To comply with any court order, law, or legal process, including to respond to any
            government or regulatory request.</p>
          <p className="p6">If We believe disclosure is necessary or appropriate to protect the rights, property, or
            safety of Athletes Untapped, our customers, or others. This includes exchanging information with other
            companies and organizations for the purposes of fraud protection and credit risk reduction.</p>
          <p className="p9">* Please note that whatever personal information you send to another party through the site
            is not protected by this Privacy Policy.</p>
          <p className="p3">&nbsp;</p>
          <p className="p4"><span className="s1"><strong>Choices About How We Use and Disclose Your Information</strong></span>
          </p>
          <p className="p5">We strive to provide you with choices regarding the personal information you provide to us.
            We have created mechanisms to provide you with the following control over your information:<span
                className="Apple-converted-space">&nbsp;</span></p>
          <p className="p6"><strong>Tracking Technologies and Advertising.</strong> You can set your browser to refuse
            all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies,
            please note that some parts of this site may then be inaccessible or not function properly.</p>
          <p className="p5">We do not control third parties' collection or use of your information to serve
            interest-based advertising. However these third parties may provide you with ways to choose not to have your
            information collected or used in this way. You can opt out of receiving targeted ads from members of the
            Network Advertising Initiative ("<strong>NAI</strong>") on the NAI's website.</p>
          <p className="p5">Residents of certain states, such as California, Nevada, Colorado, Connecticut, Virginia,
            and Utah may have additional personal information rights and choices. Please see HYPERLINK \l "a820658"Your
            State Privacy Rights for more information.</p>
          <p className="p8">&nbsp;</p>
          <p className="p4"><span className="s1"><strong>Your State Privacy Rights</strong></span></p>
          <p className="p5">State consumer privacy laws may provide their residents with additional rights regarding our
            use of their personal information. California's "Shine the Light" law (Civil Code Section &sect; 1798.83)
            permits users of our App that are California residents to request certain information regarding our
            disclosure of personal information to third parties for their direct marketing purposes.</p>
          <p className="p5">Colorado, Connecticut, Virginia, and Utah each provide their state residents with rights
            to:</p>
          <p className="p6">Confirm whether We process their personal information.</p>
          <p className="p6">Access and delete certain personal information.</p>
          <p className="p6">Data portability.</p>
          <p className="p6">Opt-out of personal data processing for targeted advertising and sales.</p>
          <p className="p5">Colorado, Connecticut, and Virginia also provide their state residents with rights to:</p>
          <p className="p6">Correct inaccuracies in their personal information, taking into account the information's
            nature processing purpose.</p>
          <p className="p6">Opt-out of profiling in furtherance of decisions that produce legal or similarly significant
            effects.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p3">&nbsp;</p>
          <p className="p4"><span className="s1"><strong>Data Security</strong></span></p>
          <p className="p5">We have implemented measures designed to secure your personal information from accidental
            loss and from unauthorized access, use, alteration, and disclosure.</p>
          <p className="p5">The safety and security of your information also depends on you. Where We have given you (or
            where you have chosen) a password for access to certain parts of our Website, you are responsible for
            keeping this password confidential. We ask you not to share your password with anyone.</p>
          <p className="p5">Unfortunately, the transmission of information via the internet is not completely secure.
            Although We do our best to protect your personal information, We cannot guarantee the security of your
            personal information transmitted to our Website. Any transmission of personal information is at your own
            risk. We are not responsible for circumvention of any privacy settings or security measures contained on the
            Website.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p3">&nbsp;</p>
          <p className="p4"><span className="s1"><strong>Changes to Our Privacy Policy</strong></span></p>
          <p className="p5">It is our policy to post any changes We make to our privacy policy on this page. If We make
            material changes to how We treat our users' personal information, We will notify you by email to the email
            address specified in your account or through a notice on the Website home page. The date the privacy policy
            was last revised is identified at the top of the page. You are responsible for ensuring We have an
            up-to-date active and deliverable email address for you, and for periodically visiting our Website and this
            privacy policy to check for any changes.</p>
          <p className="p3">&nbsp;</p>
          <p className="p4"><span className="s1"><strong>Contact Information</strong></span></p>
          <p className="p5">To ask questions or comment about this privacy policy and our privacy practices, contact us
            at:<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">community@athletesuntapped.com or 484-730-1899.</p>
        </div>

      </div>
      <BottomNav/>
    </div>
  );
};

export default PrivacyPolicy;
