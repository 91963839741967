

export default class ProfileUtils {
  static FormatCoachName(profile) {
    return `${profile.firstName} ${profile.lastName && profile.lastName.length > 0 ? profile.lastName.substr(0, 1) + "." : ""}`
  }

  static FormatAthleteFirstName(profile) {
    return profile.parentFirstName ? profile.parentFirstName : profile.firstName;
  }
}