import React from "react";
import Button from "@material-ui/core/Button";
import "date-fns";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DateAndTime from "../DateAndTime/DateAndTime";
import { Info } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

const CoachCalenderCreate = (props) => {
  return (
    <div className="calendarCreateContainer">
      <h4>Schedule Event</h4>
      <form>
        <div className="sessionTypeContainer">
          <TextField
            className="textField"
            name="typeOfEvent"
            variant="outlined"
            select
            label="Type of Event"
            value={props.eventType}
            onChange={(e) => props.setEventType(e.target.value)}
          >
            <MenuItem value={"Normal Session"}>AU Session</MenuItem>
            {props.profile.profileType === "coach" ? (
              <MenuItem value={"Personal"}>Personal Event</MenuItem>
            ) : null}
          </TextField>
          {props.profile.profileType === "coach" ? (
            <Tooltip title="Adding personal events to your calendar helps let players and parents know when you aren't available for coaching sessions.">
              <Info className="toolTipIcon" />
            </Tooltip>
          ) : null}
        </div>

        {props.eventType === "Normal Session" ? (
          <TextField
            className="textField clientSelect"
            name="listOfClients"
            variant="outlined"
            select
            label={props.for === "athlete" ? "Select Coach" : "Invite Athlete"}
            value={!props.clientToInvite ? "" : props.clientToInvite}
            onChange={(e) => props.setClientToInvite(e.target.value)}
          >
            {props.clients
              ? Object.keys(props.clients).map((clientID, index) => {
                  if (props.for === "athlete") {
                    return (
                      <MenuItem key={index} value={clientID}>
                        {props.clients[clientID]["coachInfo"]["firstName"] +
                          " " +
                          props.clients[clientID]["coachInfo"]["lastName"]}
                      </MenuItem>
                    );
                  } else if (
                    props.clients[clientID]["athleteInfo"]["parentFirstName"]
                  ) {
                    return (
                      <MenuItem key={index} value={clientID}>
                        {props.clients[clientID]["athleteInfo"][
                          "parentFirstName"
                        ] +
                          " " +
                          props.clients[clientID]["athleteInfo"][
                            "parentLastName"
                          ]}
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem key={index} value={clientID}>
                        {props.clients[clientID]["athleteInfo"]["firstName"] +
                          " " +
                          props.clients[clientID]["athleteInfo"]["lastName"]}
                      </MenuItem>
                    );
                  }
                })
              : null}
          </TextField>
        ) : null}

        {props.eventType === "Personal" ? (
          <TextField
            className="textField"
            name="eventTitle"
            variant="outlined"
            label="Event Title"
            value={props.eventTitle}
            onChange={(e) => props.setEventTitle(e.target.value)}
          ></TextField>
        ) : null}

        <DateAndTime
          className="textField"
          slot={props.slot}
          min={props.min}
          max={props.max}
          currentTimes={props.currentTimes}
          allCurrentTimes={props.allCurrentTimes}
          setAllCurrentTimes={props.setAllCurrentTimes}
          updates={props.updates}
          setUpdates={props.setUpdates}
          showDatePicker={props.showDatePicker}
          eventType={props.eventType}
        />

        <div style={{ textAlign: "center", margin: "30px 0rem" }}>
          <Button
            variant="contained"
            className="buttonPrimary"
            onClick={(e) => props.createEvent(e)}
          >
            Create Event
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CoachCalenderCreate;
