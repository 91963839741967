import React from 'react';

const Contact = props => {
    return (
        <div>
            <div className="OurTeamTitle">
                <p>Contact</p>
            </div>
            
            
        </div>
        
    );
}

export default Contact;
