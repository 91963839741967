import React from 'react';
import { withRouter } from 'react-router-dom';
import './OnlyPlatform.css';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';

const OnlyPlatform = props => {

    const learnMoreHandler = (e) => {
        e.preventDefault();
        props.history.push({
        pathname: '/our-difference',
        });
    };

    const takeToBecomeCoach = (e) => {
        e.preventDefault();
        props.history.push({
        pathname: '/join',
        state: {forAthlete: false}
        });
    };

    const takeToBecomeAthlete = (e) => {
        e.preventDefault();
        props.history.push({
        pathname: '/join',
        state: {forAthlete: true}
        });
    };

    return (
        <Paper elevation={10} variant="outlined" style={{backgroundImage: `url(${props.onlyPlatformImage})`}} className="OnlyPlatformPaper">
        <div style={{ marginTop: '0rem', padding: '3rem'}}>
            <p className="OnlyPlatformHeader" >{props.title}</p>
            <div className="listOfReasonsDivFlex">
                <div>
                    <p className="paragraphReason">{props.reasonParagraph1} <br></br> <br></br> {props.reasonParagraph2}</p>
                </div>
                <div>
                <ul className="listOfReasons"> 
                    {props.reasonList.map((reason) => {
                        return  <li>
                                    <div style={{display: 'flex',  alignItems: 'center'}}>
                                        <CheckIcon  style={{paddingRight: '0.7rem', color: green[800]}}/>
                                        <p >{reason}</p>
                                    </div>
                                </li>;
                    })}
                </ul>
                </div>
                
            </div>
            <div className="OurPlatformButtons">
                <button onClick={(e) => (learnMoreHandler(e))}> Learn More</button>
                {props.for === "coach" ? 
                    <button onClick={e => takeToBecomeCoach(e)}> Apply To Coach</button>
                    :
                    <button onClick={e => takeToBecomeAthlete(e)}> Find Your Coach</button>}
            </div>
        </div>
        </Paper>
    );
}

export default withRouter(OnlyPlatform);