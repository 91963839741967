import React, {useContext} from 'react';
import {AuthContext} from "../../screens/App/App";
import {updateProfile} from '../../api/api';
import {Link} from "react-router-dom";
import logo from "../HomeNav/AULogo.png";
import './CoachWelcome.scss';
import Colors from "../../constants/colors";


const CoachWelcome = props => {
  const {user} = useContext(AuthContext);

  user.firebaseUser.getIdToken()
    .then(function (idToken) {
      updateProfile(idToken, {hasSeenWelcome: true}, user.firebaseUser.uid)
        .then(response => {
          console.log('Updated profile', response, user.profile);
        })
        .catch(e => console.log(e));
    })

  return (
    <div className="coachWelcome">
      <div className="coachWelcomeInner">
        <div className="logoWrapper">
          <Link to="/">
            <img src={logo} className="logo" alt="Athletes Untapped"/>
          </Link>
        </div>
        <h3>Thank you for applying!</h3>
        <p className="text-large">Thank you for applying to become an AU Coach! To ensure quality control for our athletes and their parents, we carefully review every coach application.
          The next step from here is to view your dashboard and complete the rest of your profile. Once it's done and you pass the Checkr background application, we will notify you.</p>
        <Link to="/dashboard">
          <button style={{background: Colors.secondary}} type="submit">Go To Dashboard</button>
        </Link>
      </div>
    </div>
  )

}

export default CoachWelcome