import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import React from "react";
import { withRouter } from "react-router-dom";
import Login from "../Login/Login";
import Signup from "../Signup/Signup";
import "./LoginSignupDialog.scss";

const LoginSignupDialog = (props) => {
  const {showSignup, setShowSignup, show, close, returnAfterLogin, sendMessageSignup} = props

  return (
    <div>
      <Dialog open={show} fullWidth maxWidth="sm" onClose={close}>
        <DialogContent dividers>
          {showSignup ? (
            <Signup
              returnAfterLogin={returnAfterLogin}
              sendMessageSignup={sendMessageSignup}
              switch={() => setShowSignup(!showSignup)}
              close={close}
            />
          ) : (
            <Login
              returnAfterLogin={returnAfterLogin}
              switch={() => setShowSignup(!showSignup)}
              close={close}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withRouter(LoginSignupDialog);
