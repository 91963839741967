import React from "react";
import { withRouter } from 'react-router-dom';
import './Signup.scss';
import logo from '../HomeNav/AULogoSmall.png';
import CloseIcon from '@material-ui/icons/Close'; 

const SendMessageSignup = (props) => {
  const state = {
    forAthlete: false
  };
  let search = null;
  if (props.returnAfterLogin) {
    search = '?returnTo=' + encodeURIComponent(window.location.href);
  }

  const takeToBecomeAthlete = (e) => {
    if (props.close) {
      props.close();
    }
    e.preventDefault();
    state.forAthlete = true;
    props.history.push({
      pathname: '/join',
      state: state,
      search: search
    });
  };

  return (
    <div className="signupControl" >
      <div 
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          cursor: 'pointer'
        }}
        onClick={props.close}
      >
        <CloseIcon fontSize="small" style={{fontSize: '16px'}} />
      </div>

      <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
        <h4 className="signupHeader">Create an Account to Send Your Message!</h4>
        <h5 className="signupSubtitle">It's free to join the AU community. You only pay for lessons with your coach - the way it should be!</h5>
        <img src={logo} alt="Athletes Untapped" className="AULogoSignup" />
      </div>
      <div className="signupButton">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={(e) => takeToBecomeAthlete(e)}>Sign Up</button>
        </div>
        <p>Already in the AU Community?</p>
        <p className="pSwitchToLogin" onClick={props.switch}>Log in here</p>
      </div>
    </div>
  );
};

export default withRouter(SendMessageSignup);
