import React, { useState } from 'react';
import './CoachProfileWeeklyAvailability.scss';
import { Button, Checkbox, FormControl, FormControlLabel, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import DateAndTime from '../DateAndTime/DateAndTime';
import { updateProfile, getProfile } from '../../api/api';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  largeCheckbox: {
    '& svg': {
      width: '1.5em',
      height: '1.5em',
    },
  },
}));

const CoachProfileWeeklyAvailability = ({ user, initialAvailability, edit = false }) => {
  const classes = useStyles();

  const [weekAvailability, setWeekAvailability] = useState(initialAvailability || {});
  const [snackbarTimeSuccess, setSnackbarTimeSuccess] = useState(false);
  const [snackbarTimeError, setSnackbarTimeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  const formatTime = (time) => {
    return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const getProfileTimes = async () => {
    if(!user.profile.id) { return }
    try {
      const token = await user.firebaseUser.getIdToken();
      const response = await getProfile(token, user.profile.id);
      if (response.weekAvailability) {
        const updatedWeekAvailability = {};
        DAYS_OF_WEEK.forEach(day => {
          if (response.weekAvailability[day]) {
            updatedWeekAvailability[day] = {
              From: new Date(response.weekAvailability[day].From),
              To: new Date(response.weekAvailability[day].To)
            };
          } else {
            updatedWeekAvailability[day] = null;
          }
        });
        setWeekAvailability(updatedWeekAvailability);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateAvailability = async (e) => {
    e.preventDefault();
    const allUpdates = { weekAvailability: {} };
    
    for (const day in weekAvailability) {
      if (weekAvailability[day]?.From && weekAvailability[day]?.To) {
        if (
          weekAvailability[day].From.valueOf() >
          weekAvailability[day].To.valueOf() - 3600000 / 4
        ) {
          setErrorMessage(`Invalid time range for ${day}. End time must be at least 15 minutes after start time.`);
          setSnackbarTimeError(true);
          return;
        }
        allUpdates.weekAvailability[day] = {
          From: weekAvailability[day].From.valueOf(),
          To: weekAvailability[day].To.valueOf()
        };
      } else {
        allUpdates.weekAvailability[day] = null;
      }
    }

    try {
      if (!user.profile.id) {
        throw new Error('User profile ID is missing');
      }
      const token = await user.firebaseUser.getIdToken(true);
      await updateProfile(token, allUpdates, user.profile.id);
      setSnackbarTimeSuccess(true);
      await getProfileTimes();
    } catch (e) {
      console.log(e);
      setErrorMessage("Failed to update availability. Please try again.");
      setSnackbarTimeError(true);
    }
  };

  const hasAnyAvailability = DAYS_OF_WEEK.some(day => weekAvailability[day]);

  if (!hasAnyAvailability && !edit) {
    return null;
  }

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarTimeSuccess(false);
  };

  const handleCloseErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarTimeError(false);
  };

  return (
    <div className="weekly-availability">
      <h3>Weekly Availability</h3>
        {edit ? (
      <div className="availability-grid">
            {DAYS_OF_WEEK.map((day) => (
              <div key={day} className="day-availability">
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!weekAvailability[day]}
                        onChange={(e) => {
                          setWeekAvailability(prev => ({
                            ...prev,
                            [day]: e.target.checked 
                              ? { From: null, To: null }
                              : null
                          }));
                        }}
                        name={day.charAt(0).toUpperCase() + day.slice(1)}
                        className={classes.largeCheckbox}
                      />
                    }
                    label={day.charAt(0).toUpperCase() + day.slice(1)}
                  />
                </FormControl>
                {weekAvailability[day]  && (
                  <DateAndTime
                    slot={day}
                    min={0}
                    max={24}
                    currentTimes={weekAvailability[day]}
                    allCurrentTimes={weekAvailability}
                    setAllCurrentTimes={setWeekAvailability}
                    updates={weekAvailability}
                    setUpdates={setWeekAvailability}
                    showDatePicker={false}
                  />
                )}
              </div>
            ))}
          </div>

        ) : (
          <div className="mobile-columns">
            <div className="availability-grid desktop-grid">
             {DAYS_OF_WEEK.map(day => (
              <div key={day} className={`availability-show ${weekAvailability[day] ? 'available' : 'unavailable'}`}>
                <h4>{day.charAt(0).toUpperCase() + day.slice(1)}</h4>
                {weekAvailability[day] ? (
                  <p>{formatTime(weekAvailability[day].From)} - {formatTime(weekAvailability[day].To)}</p>
                ) : (
                  <p className="unavailable">Unavailable</p>
                )}
              </div>
            ))}
            </div>
          <div className="mobile-column-left">
            {DAYS_OF_WEEK.slice(0, 4).map(day => (
              <div key={day} className={`availability-show ${weekAvailability[day] ? 'available' : 'unavailable'}`}>
                <h4>{day.charAt(0).toUpperCase() + day.slice(1)}</h4>
                {weekAvailability[day] ? (
                  <p>{formatTime(weekAvailability[day].From)} - {formatTime(weekAvailability[day].To)}</p>
                ) : (
                  <p className="unavailable">Unavailable</p>
                )}
              </div>
            ))}
          </div>
          <div className="mobile-column-right">
            {DAYS_OF_WEEK.slice(4).map(day => (
              <div key={day} className={`availability-show ${weekAvailability[day] ? 'available' : 'unavailable'}`}>
                <h4>{day.charAt(0).toUpperCase() + day.slice(1)}</h4>
                {weekAvailability[day] ? (
                  <p>{formatTime(weekAvailability[day].From)} - {formatTime(weekAvailability[day].To)}</p>
                ) : (
                  <p className="unavailable">Unavailable</p>
                )}
              </div>
            ))}
            </div>
          </div>
        )}
        {edit && (
          <Button
            className="buttonPrimary"
            onClick={(e) => updateAvailability(e)}
          >
            Save
          </Button>
        )}
        <Snackbar open={snackbarTimeSuccess} autoHideDuration={6000} onClose={handleCloseSuccessSnackbar}>
          <Alert onClose={handleCloseSuccessSnackbar} severity="success">
            Availability updated successfully!
          </Alert>
        </Snackbar>

        <Snackbar open={snackbarTimeError} autoHideDuration={6000} onClose={handleCloseErrorSnackbar}>
          <Alert onClose={handleCloseErrorSnackbar} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
    </div>
  );
};

export default CoachProfileWeeklyAvailability;
