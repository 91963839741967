import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { getCoachZips } from "../../api/api";
import BottomNav from "../BottomNav/BottomNav";
import './CategoryZipList.scss';



const CategoryZipList = (props) => {

    const [catZipList, setCatZipList] = useState(null)

    useEffect(() => {
        getCoachZips().then((data)=> {
            setCatZipList(data);
            console.log('here', data);
        })
    }, [])
    
    
    return (
        <div className="coachZipList">
            <h1>Coaches by Category and Zip Code</h1>

            {catZipList? Object.keys(catZipList).map(function(sport) {
                return (
                    <div className="sport">
                        <h3 className="sportTitle">{sport}</h3>
                        <ul>
                            {catZipList[sport].map((val, index) => <li><a className="link" href={"/coaches/?radius=1&zip="+val+"&sport="+sport}>{val}</a></li>)}
                        </ul>
                    </div>
                )
             }) : <h2>Loading...</h2>}
             <br/><br/><br/>
             <BottomNav/>
        </div>)
};

export default withRouter(CategoryZipList);