import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyAJ68Plp3ZCIhGiw3yxzKAp0N5ZNDvvyGY");
// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("es");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");

export default class GeocodingUtils {
    static GetZip(successCallback, errorCallback) {
        navigator.geolocation.getCurrentPosition(function (pos) {
            // console.log('pos', pos.coords.latitude);
            Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then(
                (response) => {

                    if(!response.results || response.results.length === 0) {
                        errorCallback("No results");
                        return;
                    }
                    const address = response.results[0];
                    let addressComponents = address.address_components;
                    for (let i = 0; i < addressComponents.length; i++) {
                        if (addressComponents[i]['types'].includes('postal_code')) {
                            let zip = address.address_components[i].short_name;
                            successCallback(zip);
                            return;
                        }
                    }
                    errorCallback("Zip not found");
                },
                (error) => {
                    console.error(error);
                    errorCallback(error);
                }
            );
        })

    }
}