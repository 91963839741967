import React from 'react';
import { CLOUDFRONT_UPLOADS } from "../../constants/hosts";
import BottomNav from "../BottomNav/BottomNav";


const SiteTerms = props => {
  return (
    <div>
      <div className="basicContentPage">
        <div>
          <h1>Site Terms</h1>
          <img className="logo" alt="" src={CLOUDFRONT_UPLOADS + 'static/au_logo_simple.jpg'} />
          <p>
            Athletes Untapped, Inc.
          </p>
          <p>
            Site Terms
          </p>
          <p>
            Last Modified: Monday, October 10, 2022
          </p>
          <p className="p4"><span className="s1"><strong>SECTION A &ndash; TERMS OF USE</strong></span></p>
          <p className="p5">Athletes Untapped provides a personalized online technology database of independent training
            and coaching providers (&ldquo;<strong>Platform</strong>&rdquo;) that enables you to conveniently find,
            request, or and schedule independent, authorized sports and athletic training and coaching service providers
            (&ldquo;<strong>Third-Party Providers</strong>&rdquo;) by utilizing the Athletes Untapped Platform to
            schedule training and coaching sessions with Third-Party Providers of peer-to-peer individualized training
            and coaching services (&ldquo;<strong>Services</strong>&rdquo;).</p>
          <p className="p5">These Terms of Use (&ldquo;<strong>Terms</strong>&rdquo;) govern your access or use, from
            within the United States and its territories and possessions, of the Athletes Untapped Platform and any
            related content or Services made available in the United States and its territories and possessions by
            Athletes Untapped LLC and described herein.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">PLEASE READ THESE TERMS CAREFULLY, AS THEY CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND
            ATHLETES UNTAPPED.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">In these Terms, the words &ldquo;including&rdquo; and "include&rdquo; mean &ldquo;including,
            but not limited&nbsp;to.&rdquo;</p>
          <p className="p5">By registering to be an account holder with the intent to use the Services herein (&ldquo;
            <strong>Authorized User</strong>&rdquo;) you confirm your agreement to be bound by these Terms. If you do
            not agree to these Terms, you may not access or use the Services. These Terms expressly supersede prior
            agreements or arrangements with you regarding the use of the&nbsp;Services.</p>
          <p className="p5">Athletes Untapped may immediately terminate these Terms or any Services with respect to you,
            or generally cease offering or deny access to the Services or any portion thereof, at any time for
            any&nbsp;reason. Athletes Untapped&rsquo;s collection and use of personal information in connection with the
            Services is described in Athletes Untapped's Privacy Policy.</p>
          <p className="p6"><span className="s2"><strong>Acceptance of the Terms of Use</strong></span></p>
          <p className="p5">These Terms are entered into by and between You and Athletes Untapped LLC
            ("<strong>Company</strong>," "<strong>we</strong>," or "<strong>us</strong>"). The Terms govern your access
            to and use of www.athletesuntapped.com, including any content, functionality, and services offered on or
            through www.athletesuntapped.com (the "<strong>Website</strong>"), whether as a guest or a registered user.
          </p>
          <p className="p5">Please read the Terms carefully before you start to use the Website. <strong>By using the
            Website, you accept and agree to be bound and abide by these Terms of Use and our Privacy
            Policy.</strong> If you do not want to agree to these Terms of Use or the Privacy Policy, you must not
            access or use the Website. These Terms apply to all users of the site, including without limitation users
            who are browsers, vendors, customers, merchants, and/ or contributors of content.</p>
          <p className="p6"><span className="s2"><strong>Eligibility</strong></span></p>
          <p className="p5">By agreeing to these Terms, you represent that you are at least the age of majority in your
            state or province of residence, or that you are the age of majority in your state or province of residence
            and you have given us your consent to allow any of your minor dependents to use this site.</p>
          <p className="p4"><span className="s1"><strong>SECTION B &ndash; USE OF THE SERVICES</strong></span></p>
          <p className="p7">&nbsp;</p>
          <p className="p5"><span className="s2"><strong>The Platform &amp;&nbsp;Services</strong></span></p>
          <p className="p5">Athletes Untapped operates a personalized online technology database of independent training
            and coaching providers that is accessed in a number of forms, including a web-based application with mobile
            functionality (&ldquo;<strong>Applications</strong>&rdquo;). Among other things, the Platform enables you
            to:</p>
          <p className="p5">conveniently find, request, or and schedule independent, authorized sports and athletic
            training and coaching service providers of peer-to-peer individualized training and coaching services
            through using the Athletes Untapped Platform.</p>
          <p className="p5">any supporting services, including payment processing and customer support.<span
            className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">You acknowledge and agree that Company is a technology services provider that does not
            provide peer-to-peer individualized coaching and training services. YOU ACKNOWLEDGE THAT YOUR ABILITY TO
            REQUEST, AND IF APPLICABLE, OBTAIN TRAINING SERVICES FROM THIRD-PARTY PROVIDERS IN CONNECTION WITH THE USE
            OF THE ATHLETES UNTAPPED PLATFORM AND SERVICES DOES NOT ESTABLISH ATHLETES UNTAPPED AS A PROVIDER OF
            PEER-TO-PEER INDIVIDUALIZED COACHING AND TRAINING SERVICES.</p>
          <p className="p5">THE ATHLETES UNTAPPED PLATFORM IS ONLY OPEN TO REGISTERED USERS OF THE ATHLETES UNTAPPED
            PLATFORM AND NOT TO THE GENERAL&nbsp;PUBLIC.</p>
          <p className="p5">YOU ACKNOWLEDGE THAT INDEPENDENT THIRD-PARTY PROVIDERS, INCLUDING TRAINERS, ARE NOT ACTUAL
            AGENTS, APPARENT AGENTS, OSTENSIBLE AGENTS, OR EMPLOYEES OF ATHLETES UNTAPPED IN ANY&nbsp;WAY. YOU ALSO
            ACKNOWLEDGE THAT ANY SAFETY RELATED EFFORT, FEATURE, PROCESS, POLICY, STANDARD OR OTHER EFFORT UNDERTAKEN BY
            ATHLETES UNTAPPED IN THE INTEREST OF PUBLIC SAFETY (WHETHER REQUIRED BY APPLICABLE REGULATIONS OR NOT) IS
            NOT AN INDICATION OF AN EMPLOYMENT, ACTUAL AGENCY, APPARENT AGENCY, OR OSTENSIBLE AGENCY RELATIONSHIP WITH
            AN INDEPENDENT THIRD-PARTY&nbsp;DRIVER.</p>
          <p className="p6"><em>Third-Party Services and&nbsp;Content.</em></p>
          <p className="p5">The Services may be made available or accessed in connection with third-party services and
            content (including advertising) that Athletes Untapped does not control. Once you click on a link to
            third-party services or content, you will be subject to the terms and conditions and privacy policy of that
            website, destination, or third-party service provider. Athletes Untapped will not warn you that you have
            left the Services or that you are subject to the terms and conditions (including privacy policies) of
            another website, destination, or third-party service provider. You use all links in third-party websites and
            advertisements at your own risk as these are not part of the Services and are not controlled by Athletes
            Untapped. You acknowledge that different terms of use and privacy policies may apply to your use of such
            third-party services and content. Athletes Untapped does not endorse such third-party services and content
            and in no event shall Athletes Untapped be responsible or liable for any products or services of such
            Third-Party&nbsp;Providers.</p>
          <p className="p5"><span className="s2"><strong>Access and Use of the Services</strong></span></p>
          <p className="p6"><em>User&nbsp;Accounts.</em></p>
          <p className="p5">In order to use most aspects of the Services, you must register for and maintain an active
            personal user Services account (&ldquo;<strong>Account</strong>&rdquo;). You must be at least 18 years of
            age, or the age of legal majority in your jurisdiction (if different than 18), to obtain an Account, unless
            a specific Service permits otherwise. You cannot register for or maintain an Account if you have previously
            been banned from accessing or using the Services. Account registration requires you to submit to Athletes
            Untapped certain personal information, such as your name, address, mobile phone number and age, as well as
            at least one valid payment method supported by Athletes Untapped. For more information regarding Athletes
            Untapped&rsquo;s use of your personal information, please see our Privacy Policy.<span
              className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">You agree to maintain accurate, complete, and up-to-date information in your Account,
            including a valid phone number, zip code and payment method. Your failure to comply with these Terms,
            including, without limitation, your failure to maintain accurate, complete, and up-to-date Account
            information, including having an invalid or expired payment method on file, may result in your inability to
            access or use the Services. You are responsible for all activity that occurs under your Account, and you
            agree to maintain the security and secrecy of your Account username and password at all times. Unless
            otherwise permitted by Athletes Untapped in writing, you may only possess one&nbsp;Account.</p>
          <p className="p6"><em>User Conduct.</em></p>
          <p className="p5">The Services are not available for use by persons under the age of 18. You may not authorize
            third-parties to use your Account, and you may not allow persons under the age of 18 to request or receive
            Services from Third-Party Providers unless they are accompanied by you. You may not assign or otherwise
            transfer your Account to any other person or entity. You agree to comply with all applicable laws when
            accessing or using the Services.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p6"><em>Coach&nbsp;Accounts.</em></p>
          <p className="p5">Athletes Untapped carefully reviews all coaching applications. Coaches must be eighteen (18) years of age and successfully complete a background check administered by Certn.Co in order to use the Platform. Company has the sole and utmost discretion to refuse to allow use of the Platform to applicants that do not pass the required background check. Company may also deny access to the Platform if new information comes to light after a background check has been successfully passed. Athletes Untapped also reserves the right in extenuating and extraordinary circumstances to admit a coach onto the site who is flagged through the background check. Company will evaluate all such situations on a case-by-case basis, but Company will consider factors such as exemplary character, strong history of relevant coaching or playing experience, and a rigorous interview process to determine, in the discretion and judgment of Company, that applicant is fit to use the Platform as a coach.
            <span
              className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5"><span className="s2"><strong>Payment</strong></span></p>
          <p className="p5">You understand that there is no cost to sign up for use of the Services through Athletes
            Untapped. Athletes Untapped facilitates finding, requesting, and/or scheduling Third-Party Providers through
            the Athletes Untapped Platform. Upon registering for the Athletes Untapped Platform, you will be able to use
            the Platform to contact and contract with Third-Party Providers.</p>
          <p className="p5">Payment terms will be set by Third-Party Providers and will be charged on an hourly basis to
            be determined in the Third-Party Provider&rsquo;s sole discretion. Prices charged by Third-Party providers
            are always subject to change. Any such disputes you have with a Third-Party Provider on pricing is to be
            resolved between you and the Third-Party Provider.</p>
          <p className="p5">All transactions with Third-Party Providers must be made on the Platform. Under no
            circumstances will payment be given to Third-Party Providers through any other means outside the Athletes
            Untapped Platform, including, but not limited to, cash, Venmo, check, or any other private transaction.</p>
          <p className="p5">Athletes Untapped will receive a 20% remittance from any fee paid to a Third-Party Provider
            for providing a Service booked through the Platform. As an example, and for the avoidance of doubt, if a
            Third-Party Provider charges $100 for a session booked through the Platform, Athletes Untapped will<span
              className="Apple-converted-space">&nbsp; </span>$20 (20%) to Athletes Untapped as compensation for the
            access to the Platform. This 20% remittance is accounted for in the fee charged by a Third-Party Provider.
          </p>
          <p className="p5"><span className="s2"><strong>Refund Policy</strong></span></p>
          <p className="p5">All Athletes Untapped coaching sessions are eligible for a refund - minus our payment processing fees - or credit within 30 days of the date of purchase. After 30 days, all remaining coaching sessions are only transferrable for credit. Unused credit expires one year from the date of purchase.</p>
          <p className="p5">Sessions purchased in the prior calendar year are ineligible for refunds, but they can be used as credits.</p>

          <p className="p5"><span
            className="s2"><strong>Disclaimers; Limitation of Liability; Indemnity.</strong></span></p>
          <p className="p6"><em>Disclaimer.</em></p>
          <p className="p5">THE SERVICES ARE PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE.&rdquo;<span
            className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">ATHLETES UNTAPPED DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR
            STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, ATHLETES UNTAPPED MAKES NO
            REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, OR
            AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE
            SERVICES WILL BE UNINTERRUPTED OR&nbsp;ERROR-FREE.</p>
          <p className="p5">ATHLETES UNTAPPED DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF
            THIRD-PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY
            SERVICE OR GOOD REQUESTED OR OBTAINED FROM THIRD-PARTY PROVIDERS IN CONNECTION THEREWITH, REMAINS SOLELY
            WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE&nbsp;LAW.</p>
          <p className="p5">ATHLETES UNTAPPED DOES NOT CONTROL, MANAGE OR DIRECT ANY THIRD-PARTY PROVIDERS INCLUDING
            TRAINERS. THIRD-PARTY PROVIDERS ARE NOT ACTUAL AGENTS, APPARENT AGENTS, OSTENSIBLE AGENTS, OR EMPLOYEES
            OF&nbsp;ATHLETES UNTAPPED.</p>
          <p className="p6"><em>Limitation of&nbsp;Liability.</em></p>
          <p className="p5">ATHLETES UNTAPPED SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY,
            PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE
            RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, REGARDLESS OF THE
            NEGLIGENCE (EITHER ACTIVE, AFFIRMATIVE, SOLE, OR CONCURRENT) OF ATHLETES UNTAPPED, EVEN IF ATHLETES UNTAPPED
            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH&nbsp;DAMAGES.</p>
          <p className="p5">ATHLETES UNTAPPED SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF:
            (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY
            TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD-PARTY PROVIDER, EVEN IF ATHLETES UNTAPPED HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">ATHLETES UNTAPPED SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM
            CAUSES BEYOND ATHLETES UNTAPPED'S REASONABLE CONTROL.<span className="Apple-converted-space">&nbsp;</span>
          </p>
          <p className="p5">YOU ACKNOWLEDGE THAT THIRD-PARTY PROVIDERS PROVIDING PERSONALIZED PEER-TO-PEER COACHING AND
            TRAINING SERVICES REQUESTED THROUGH SOME ATHLETES UNTAPPED SERVICES MAY OFFER PEER-TO-PEER INDVIDUALIZED
            TRAINING AND COACHING SERVICES AND MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED. YOU ACKNOWLEDGE THAT
            THIRD-PARTY PROVIDERS ARE NOT OSTENSIBLE AGENTS, APPARENT AGENTS, ACTUAL AGENTS, OR EMPLOYEES
            OF&nbsp;ATHLETES UNTAPPED.</p>
          <p className="p5">THE SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE PERSONALIZED PEER-TO-PEER COACHING
            AND TRAINING SERVICES WITH THIRD-PARTY PROVIDERS, BUT YOU AGREE THAT ATHLETES UNTAPPED HAS NO RESPONSIBILITY
            OR LIABILITY TO YOU RELATED TO ANY PERSONALIZED PEER-TO-PEER COACHING AND TRAINING SERVICES PROVIDED TO OR
            NOT PROVIDED TO YOU BY THIRD-PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET FORTH IN THESE&nbsp;TERMS.</p>
          <p className="p5">THE LIMITATIONS AND DISCLAIMERS IN THIS SECTION DO NOT PURPORT TO LIMIT LIABILITY OR ALTER
            YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW. BECAUSE SOME STATES OR JURISDICTIONS
            DO NOT ALLOW THE EXCLUSION OF OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN
            SUCH STATES OR JURISDICTIONS, ATHLETES UNTAPPED&rsquo;S LIABILITY SHALL BE LIMITED TO THE EXTENT PERMITTED
            BY LAW. THIS PROVISION SHALL HAVE NO EFFECT ON ATHLETES UNTAPPED&rsquo;S CHOICE OF LAW PROVISION SET
            FORTH&nbsp;BELOW.</p>
          <p className="p6"><em>Indemnity.</em></p>
          <p className="p5">You agree to indemnify and hold Athletes Untapped and its affiliates and their officers,
            directors, employees, and agents harmless from and against any and all actions, claims, demands, losses,
            liabilities, costs, damages, and expenses (including attorneys&rsquo; fees), arising out of or in connection
            with: (i) your use of the Services or services or goods obtained through your use of the Services; (ii) any
            injuries to You or Your child resulting from the provision of Services by Third-Party Providers; (iii) your
            breach or violation of any of these Terms; (iv) Athletes Untapped's use of your User Content; or (v) your
            violation of the rights of any third party, including Third-Party&nbsp;Providers.</p>
          <p className="p5">In the event of an incident or injury, You agree to notify us within 24 hours and provide us
            with all reasonable information relating to any incident or accident that occurs during your use of the
            Services and you agree to cooperate with any investigation and attempted resolution of such&nbsp;incident.
            This is purely for informational purposes, and does not render Athletes Untapped liable for any of the
            actions, claims, demands, losses, liabilities, costs, damages, and expenses arising out of your individual
            interactions with Third-Party Providers.</p>
          <p className="p5"><strong>Other Provisions</strong></p>
          <p className="p5">Any dispute, controversy, or claim arising out of or related to this Agreement or any breach
            or termination of this Agreement, including but not limited to the Services You receive from a Third-Party
            Provider, and any alleged violation of any federal, state, or local statute, regulation, common law, or
            public policy, whether sounding in contract, tort, or statute, shall be submitted to and decided by binding
            arbitration. Arbitration shall be administered by the American Arbitration Association and held in
            Philadelphia, Pennsylvania before a single arbitrator, in accordance with the American Arbitration
            Association's rules, regulations, and requirements. Any arbitral award determination shall be final and
            binding upon the Parties. Judgment on the arbitrator's award may be entered in any court of competent
            jurisdiction.</p>
          <p className="p5">Arbitration shall proceed only on an individual basis. The Parties waive all rights to have
            their disputes heard or decided by a jury or in a court trial and the right to pursue any class or
            collective claims against each other in court, arbitration, or any other proceeding. Each party shall only
            submit their own individual claims against the other and will not seek to represent the interests of any
            other person. The arbitrator shall have no jurisdiction or authority to compel any class or collective
            claim, or to consolidate different arbitration proceedings with or join any other party to an arbitration
            between the Parties. The arbitrator, not any court, shall have exclusive authority to resolve any dispute
            relating to the enforceability or formation of this Agreement and the arbitrability of any dispute between
            the Parties, except for any dispute relating to the enforceability or scope of the class and collective
            action waiver, which shall be determined by a court of competent jurisdiction.</p>
          <p className="p5">This agreement, whether sounding in contract, tort, or statute for all purposes shall be
            governed by and construed in accordance with, the laws of the State of Pennsylvania (including its statutes
            of limitations, without giving effect to any conflict of laws principles that would cause the application of
            the laws of any other jurisdiction other to apply.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p6"><em>General.</em></p>
          <p className="p5">You may not assign these Terms without Athletes Untapped&rsquo;s prior written approval.
            Athletes Untapped may assign these Terms without your consent to: (i) a subsidiary or affiliate; (ii) an
            acquirer of Athletes Untapped's equity, business or assets; or (iii) a successor by merger. Any purported
            assignment by you in violation of this section shall be void. No joint venture, partnership, employment, or
            agency relationship exists between you, Athletes Untapped or any Third-Party Provider as a result of this
            Agreement or use of the Services. If any provision of these Terms is held to be invalid or unenforceable,
            such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under
            law. Athletes Untapped's failure to enforce any right or provision in these Terms shall not constitute a
            waiver of such right or provision unless acknowledged and agreed to by Athletes Untapped in writing. This
            provision shall not affect the Severability and Survivability section of the Arbitration Agreement of
            these&nbsp;Terms.</p>
          <p className="p8">&nbsp;</p>
          <p className="p4"><span className="s1"><strong>SECTION C &ndash; USE OF THE WEBSITE</strong></span></p>
          <p className="p9">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Changes to the Terms of Use</strong></span></p>
          <p className="p5">You can review the most current version of the Terms at any time on this page. We reserve
            the right to update, change or replace any part of these Terms by posting updates and/or changes to our
            website. It is your responsibility to check this page periodically for changes. Your continued use of or
            access to the website following the posting of any changes constitutes acceptance of those changes.</p>
          <p className="p5">Athletes Untapped may make changes to these Terms from time to time. If Athletes Untapped
            makes changes, it will provide you with notice of such changes, such as by sending an email, providing a
            notice through the Services, or updating the date at the top of these Terms. Unless Athletes Untapped says
            otherwise in its notice, the amended Terms will be effective immediately and your continued access to and
            use of the Services after Athletes Untapped provides such notice will confirm your acceptance of the
            changes. If you do not agree to the amended Terms, you must stop accessing and using the&nbsp;Services.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Accessing the Website and Account Security</strong></span></p>
          <p className="p5">We reserve the right to withdraw or amend this Website, and any service or material we
            provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all
            or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict
            access to some parts of the Website, or the entire Website, to users, including registered users.</p>
          <p className="p5">You are responsible for both:</p>
          <p className="p10">Making all arrangements necessary for you to have access to the Website.</p>
          <p className="p10">Ensuring that all persons who access the Website through your internet connection are aware
            of these Terms and comply with them.</p>
          <p className="p5">To access the Website or some of the resources it offers, you may be asked to provide
            certain registration details or other information. It is a condition of your use of the Website that all the
            information you provide on the Website is correct, current, and complete. You agree that all information you
            provide to register with this Website or otherwise, including, but not limited to, through the use of any
            interactive features on the Website, is governed by our <em>Privacy Policy</em>, and you consent to all
            actions we take with respect to your information consistent with our Privacy Policy.</p>
          <p className="p5">If you choose, or are provided with, a user name, password, or any other piece of
            information as part of our security procedures, you must treat such information as confidential, and you
            must not disclose it to any other person or entity. You also acknowledge that your account is personal to
            you and agree not to provide any other person with access to this Website or portions of it using your user
            name, password, or other security information. You agree to notify us immediately of any unauthorized access
            to or use of your user name or password or any other breach of security. You also agree to ensure that you
            exit from your account at the end of each session. You should use particular caution when accessing your
            account from a public or shared computer so that others are not able to view or record your password or
            other personal information.</p>
          <p className="p5">We have the right to disable any user name, password, or other identifier, whether chosen by
            you or provided by us, if, in our opinion, you have violated any provision of these Terms.</p>
          <p className="p9">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Intellectual Property Rights</strong></span></p>
          <p className="p5">The Website and its entire contents, features, and functionality (including but not limited
            to all information, software, text, displays, images, video, and audio, and the design, selection, and
            arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are
            protected by United States and international copyright, trademark, patent, trade secret, and other
            intellectual property or proprietary rights laws.</p>
          <p className="p11">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Trademarks</strong></span></p>
          <p className="p5">The Company name, the terms and all related names, logos, product and service names,
            designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such
            marks without the prior written permission of the Company. All other names, logos, product and service
            names, designs, and slogans on this Website are the trademarks of their respective owners.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Prohibited Uses</strong></span></p>
          <p className="p5">You may use the Website only for lawful purposes and in accordance with these Terms. You
            agree not to use the Website:</p>
          <p className="p10">In any way that violates any applicable federal, state, local, or international law or
            regulation (including, without limitation, any laws regarding the export of data or software to and from the
            US or other countries).<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p10">For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way
            by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</p>
          <p className="p10">To send, knowingly receive, upload, download, use, or re-use any material that does not
            comply with the Content Standards set out in these Terms.</p>
          <p className="p10">To transmit, or procure the sending of, any advertising or promotional material including
            any "junk mail," "chain letter," "spam," or any other similar solicitation.</p>
          <p className="p10">To impersonate or attempt to impersonate the Company, a Company employee, another user, or
            any other person or entity (including, without limitation, by using email addresses associated with any of
            the foregoing).</p>
          <p className="p10">To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the
            Website, or which, as determined by us, may harm the Company or users of the Website, or expose them to
            liability.</p>
          <p className="p5">Additionally, you agree not to:</p>
          <p className="p10">Use the Website in any manner that could disable, overburden, damage, or impair the site or
            interfere with any other party's use of the Website, including their ability to engage in real time
            activities through the Website.</p>
          <p className="p10">Use any robot, spider, or other automatic device, process, or means to access the Website
            for any purpose, including monitoring or copying any of the material on the Website.</p>
          <p className="p10">Use any manual process to monitor or copy any of the material on the Website, or for any
            other purpose not expressly authorized in these Terms, without our prior written consent.</p>
          <p className="p10">Use any device, software, or routine that interferes with the proper working of the
            Website.</p>
          <p className="p10">Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is
            malicious or technologically harmful.</p>
          <p className="p10">Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the
            Website, the server on which the Website is stored, or any server, computer, or database connected to the
            Website.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p10">Attack the Website via a denial-of-service attack or a distributed denial-of-service
            attack.</p>
          <p className="p10">Otherwise attempt to interfere with the proper working of the Website.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>User Contributions</strong></span></p>
          <p className="p5">The Website may contain message boards, chat rooms, personal web pages or profiles, forums,
            bulletin boards, and other interactive features (collectively, "<strong>Interactive Services</strong>") that
            allow users to post, submit, publish, display, or transmit to other users or other persons (hereinafter,
            "<strong>post</strong>") content or materials (collectively, "<strong>User Contributions</strong>") on or
            through the Website.</p>
          <p className="p5">All User Contributions must comply with the Content Standards set out in these Terms.</p>
          <p className="p5">Any User Contribution you post to the site will be considered non-confidential and
            non-proprietary. By providing any User Contribution on the Website, you grant us and our licensees,
            successors, and assigns the right to use, reproduce, modify, perform, display, distribute, and otherwise
            disclose to third parties any such material.</p>
          <p className="p5">You represent and warrant that:<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p10">You own or control all rights in and to the User Contributions and have the right to grant
            the license granted above to us our licensees, successors, and assigns.</p>
          <p className="p10">All of your User Contributions do and will comply with these Terms.<span
            className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">You understand and acknowledge that you are responsible for any User Contributions you
            submit or contribute, and you, not the Company, have full responsibility for such content, including its
            legality, reliability, accuracy, and appropriateness.</p>
          <p className="p5">We are not responsible or liable to any third party for the content or accuracy of any User
            Contributions posted by you or any other user of the Website.<span
              className="Apple-converted-space">&nbsp;</span></p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Monitoring and Enforcement; Termination</strong></span></p>
          <p className="p5">We have the right to:</p>
          <p className="p10">Remove or refuse to post any User Contributions for any or no reason in our sole
            discretion.</p>
          <p className="p10">Take any action with respect to any User Contribution that we deem necessary or appropriate
            in our sole discretion, including if we believe that such User Contribution violates the Terms, including
            the Content Standards, infringes any intellectual property right or other right of any person or entity,
            threatens the personal safety of users of the Website or the public, or could create liability for the
            Company.</p>
          <p className="p10">Disclose your identity or other information about you to any third party who claims that
            material posted by you violates their rights, including their intellectual property rights or their right to
            privacy.</p>
          <p className="p10">Take appropriate legal action, including without limitation, referral to law enforcement,
            for any illegal or unauthorized use of the Website.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p10">Terminate or suspend your access to all or part of the Website for [any or no reason,
            including without limitation,] any violation of these Terms.</p>
          <p className="p5">Without limiting the foregoing, we have the right to cooperate fully with any law
            enforcement authorities or court order requesting or directing us to disclose the identity or other
            information of anyone posting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS THE
            COMPANY FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY
            LAW ENFORCEMENT AUTHORITIES.</p>
          <p className="p5">However, we cannot review material before it is posted on the Website, and cannot ensure
            prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for
            any action or inaction regarding transmissions, communications, or content provided by any user or third
            party. We have no liability or responsibility to anyone for performance or nonperformance of the activities
            described in this section.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Content Standards</strong></span></p>
          <p className="p5">These content standards apply to any and all User Contributions and use of Interactive
            Services. User Contributions must in their entirety comply with all applicable federal, state, local, and
            international laws and regulations. Without limiting the foregoing, User Contributions must not:</p>
          <p className="p10">Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing,
            violent, hateful, inflammatory, or otherwise objectionable.</p>
          <p className="p10">Promote sexually explicit or pornographic material, violence, or discrimination based on
            race, sex, religion, nationality, disability, sexual orientation, or age.</p>
          <p className="p10">Infringe any patent, trademark, trade secret, copyright, or other intellectual property or
            other rights of any other person.</p>
          <p className="p10">Violate the legal rights (including the rights of publicity and privacy) of others or
            contain any material that could give rise to any civil or criminal liability under applicable laws or
            regulations or that otherwise may be in conflict with these Terms and our Privacy Policy.</p>
          <p className="p10">Be likely to deceive any person.</p>
          <p className="p10">Promote any illegal activity, or advocate, promote, or assist any unlawful act.</p>
          <p className="p10">Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass,
            alarm, or annoy any other person.</p>
          <p className="p10">Impersonate any person, or misrepresent your identity or affiliation with any person or
            organization.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p10">Involve commercial activities or sales, such as contests, sweepstakes, and other sales
            promotions, barter, or advertising.</p>
          <p className="p10">Give the impression that they emanate from or are endorsed by us or any other person or
            entity, if this is not the case.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Reliance on Information Posted</strong></span></p>
          <p className="p5">The information presented on or through the Website is made available solely for general
            information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any
            reliance you place on such information is strictly at your own risk. We disclaim all liability and
            responsibility arising from any reliance placed on such materials by you or any other visitor to the
            Website, or by anyone who may be informed of any of its contents.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Changes to the Website</strong></span></p>
          <p className="p5">We may update the content on this Website from time to time, but its content is not
            necessarily complete or up-to-date. Any of the material on the Website may be out of date at any given time,
            and we are under no obligation to update such material.<span className="Apple-converted-space">&nbsp;</span>
          </p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span
            className="s2"><strong>Information About You and Your Visits to the Website</strong></span></p>
          <p className="p5">All information we collect on this Website is subject to our Privacy Policy. By using the
            Website, you consent to all actions taken by us with respect to your information in compliance with the
            Privacy Policy.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Links from the Website</strong></span></p>
          <p className="p5">If the Website contains links to other sites and resources provided by third parties, these
            links are provided for your convenience only. This includes links contained in advertisements, including
            banner advertisements and sponsored links. We have no control over the contents of those sites or resources,
            and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you
            decide to access any of the third-party websites linked to this Website, you do so entirely at your own risk
            and subject to the terms and conditions of use for such websites.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Geographic Restrictions</strong></span></p>
          <p className="p5">The owner of the Website is based in the State of Pennsylvania in the United States. We
            provide this Website for use only by persons located in the United States. We make no claims that the
            Website or any of its content is accessible or appropriate outside of the United States. Access to the
            Website may not be legal by certain persons or in certain countries. If you access the Website from outside
            the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Disclaimer of Warranties</strong></span></p>
          <p className="p5">You understand that we cannot and do not guarantee or warrant that files available for
            downloading from the internet or the Website will be free of viruses or other destructive code. You are
            responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements
            for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our
            site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE
            FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY
            HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
            MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
            DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>
          <p className="p5">YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
            IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
            PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
            IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION
            WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE.
            WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR
            WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE
            ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE
            SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY
            SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.<span
              className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY
            KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
            MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
          <p className="p5">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER
            APPLICABLE LAW.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Limitation on Liability</strong></span></p>
          <p className="p5">TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR
            THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY
            KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE
            WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
            INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
            INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
            ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
            NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.<span
              className="Apple-converted-space">&nbsp;</span></p>
          <p className="p5">The limitation of liability set out above does not apply to liability resulting from our
            gross negligence or willful misconduct or death or bodily injury caused by products you purchase through the
            site.</p>
          <p className="p5">THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER
            APPLICABLE LAW.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Indemnification</strong></span></p>
          <p className="p5">You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors,
            and service providers, and its and their respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments,
            awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating
            to your violation of these Terms or your use of the Website, including, but not limited to, your User
            Contributions, any use of the Website's content, services, and products other than as expressly authorized
            in these Terms, or your use of any information obtained from the Website.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Governing Law and Jurisdiction</strong></span></p>
          <p className="p5">All matters relating to the Website and these Terms, and any dispute or claim arising
            therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed
            by and construed in accordance with the internal laws of the State of Pennsylvania without giving effect to
            any choice or conflict of law provision or rule (whether of the State of Pennsylvania or any other
            jurisdiction).</p>
          <p className="p5">Any legal suit, action, or proceeding arising out of, or related to, these Terms or the
            Website shall be instituted exclusively in the federal courts of the United States or the courts of the
            State of Pennsylvania, although we retain the right to bring any suit, action, or proceeding against you for
            breach of these Terms in your country of residence or any other relevant country. You waive any and all
            objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Arbitration</strong></span></p>
          <p className="p5">At Company's sole discretion, it may require You to submit any disputes arising from these
            Terms or use of the Website, including disputes arising from or concerning their interpretation, violation,
            invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration
            of the American Arbitration Association applying Pennsylvania law.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Limitation on Time to File Claims</strong></span></p>
          <p className="p5">ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OR THE
            WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
            ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Waiver and Severability</strong></span></p>
          <p className="p5">No waiver by the Company of any term or condition set out in these Terms shall be deemed a
            further or continuing waiver of such term or condition or a waiver of any other term or condition, and any
            failure of the Company to assert a right or provision under these Terms shall not constitute a waiver of
            such right or provision.</p>
          <p className="p5">If any provision of these Terms is held by a court or other tribunal of competent
            jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or
            limited to the minimum extent such that the remaining provisions of the Terms will continue in full force
            and effect.<span className="Apple-converted-space">&nbsp;</span></p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Entire Agreement</strong></span></p>
          <p className="p5">The Terms and our Privacy Policy constitute the sole and entire agreement between you and
            Athletes Untapped regarding the Website and supersede all prior and contemporaneous understandings,
            agreements, representations, and warranties, both written and oral, regarding the Website.<span
              className="Apple-converted-space">&nbsp;</span></p>
          <p className="p8">&nbsp;</p>
          <p className="p6"><span className="s2"><strong>Your Comments and Concerns</strong></span></p>
          <p className="p5">This website is operated by Athletes Untapped LLC.</p>
          <p className="p5">All other feedback, comments, requests for technical support, and other communications
            relating to the Website should be directed to: <a href="mailto:community@athletesuntapped.com">community@athletesuntapped.com</a>.</p>
        </div>

      </div>
      <BottomNav />
    </div>
  );
};

export default SiteTerms;
