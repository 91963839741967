export function calculateSessionRateWithAdditionalAthletes({ sessionRateString, additionalAthletesRateString, numAthletes }) {
    const sessionRate = Number(sessionRateString);
    const additionalAthletesRate = Number(
        additionalAthletesRateString ?? 0);
    if (numAthletes < 2) {
        return sessionRate;
    }
    return sessionRate + (additionalAthletesRate * (numAthletes - 1));
}

export function checkMessageForSensitiveInfo(message) {
    const lowerMessage = message.toLowerCase();
    const phoneRegex = /\b\d{10}\b/;
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
    const bannedKeywords = ['cash', 'venmo', 'zelle', 'paypal', 'call', 'phone', 'email', 'gmail', 'yahoo', 'outlook'];
    const keywordRegex = new RegExp(`\\b(${bannedKeywords.join('|')})\\b`, 'i');

    if(emailRegex.test(lowerMessage) || phoneRegex.test(lowerMessage) || keywordRegex.test(lowerMessage)) {
        throw new Error("All communication is required to stay on the platform - it ensures privacy & payment security for the AU community.");
    }
}
