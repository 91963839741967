import React, {useState, useEffect} from 'react';
import * as firebase from 'firebase/app';
import {createTheme, ThemeProvider, makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import './ResetPassword.css';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {SendPasswordResetSuccessEmail} from "../../api/api";


function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

function validatePassword(password) {
  // Must have at least 1 number and be of length 6 or more
  var re = /\d/g;
  return String(password).length >= 6 && re.test(String(password));
}

const spinnerTheme = createTheme({
  palette: {
    primary: {
      main: '#006400'
    }
  }
});

const textFieldColor = "green";
const textFieldColorNormal = "lightslategray";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.5rem',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      minWidth: '25ch',

      '& label.Mui-focused': {
        color: textFieldColor,
        fontFamily: 'Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif',

      },
      '& label': {
        fontFamily: 'Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif',

      },
      '& .MuiInput-underline:after': {
        borderBottomColor: textFieldColor,
      },
      '& .MuiOutlinedInput-root': {
        fontFamily: 'Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif',
        '& fieldset': {
          borderColor: textFieldColorNormal,
          borderRadius: '0',
          borderWidth: '1.2px'
        },
        '&:hover fieldset': {
          borderColor: textFieldColor,
          borderWidth: '2px'
        },
        '&.Mui-focused fieldset': {
          borderColor: textFieldColor,
          borderWidth: '2px'
        },
      },
    },
  },

  formControl: {
    margin: theme.spacing(3),
    minWidth: '30ch',
  },
  p: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(1),
    fontFamily: 'Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif'
  },
  label: {
    fontFamily: 'Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif',

  },
}));


const ResetPassword = props => {
  const inputStyles = useStyles();
  const [email, setEmail] = useState(null);
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [validCode, setValidCode] = useState(null);
  const [verifiedCode, setVerifiedCode] = useState(null);
  const [errorSnackbar, setErrorSnackbar] = useState(false);


  useEffect(() => {
    // Verify the password reset code is valid.

    firebase.auth()
      .verifyPasswordResetCode(props.actionCode)
      .then(email => {
        setEmail(email);
        setValidCode(true);
        setVerifiedCode(true);
        console.log(email);
      }, error => {
        // Invalid or expired action code. Ask user to try to reset the password again.
        setError(error.message);
        setErrorSnackbar(true);
        setValidCode(false);
        setVerifiedCode(true);
      })
  });

  const handleResetPassword = (event) => {
    event.preventDefault();
    if (!password || password === "" || !validatePassword(password)) {
      setError("Password must be of length 6 or more and contain at least 1 number.")
      setErrorSnackbar(true);
      return;
    }
    const newPassword = password;

    firebase.auth().checkActionCode(props.actionCode)
      .then(result => {
        console.log('Action Code Data', result)
        firebase.auth().confirmPasswordReset(props.actionCode, newPassword)
          .then(() => SendPasswordResetSuccessEmail(result.data.email))
      })
      .then(() => {
        // Password reset has been confirmed and new password updated.
        setSuccess(true);
      }, error => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        setError(error.message);
        setErrorSnackbar(true);
      });
  }

  let component;

  if (!verifiedCode) {
    component = (
      <div style={{marginTop: '5rem', display: 'flex', justifyContent: 'center'}}>
        <ThemeProvider theme={spinnerTheme}>
          <CircularProgress/>
        </ThemeProvider>
      </div>);
  } else if (success) {
    component = (
      <div className="ResetPasswordSuccess">
        <p>Password changed successfully!</p>
        <p>You can now sign in with your new password.</p>
      </div>
    );
  } else if (verifiedCode && validCode) {
    component = (
      <div className="ResetPassword">
        <p>Reset your password for your <span>{email}</span> Athletes Untapped account.</p>

        <form className={inputStyles.root} onSubmit={e => handleResetPassword(e)}>

          {/* {error ? <div className="ResetPasswordSmallError">{error}</div> : ''} */}
          <TextField
            variant="outlined"
            value={password}
            type="password"
            placeholder="New Password"
            required
            onChange={e => setPassword(e.target.value)}
          />

          <button
            className="ResetPasswordSavePasswordButton"
            type="submit"
          >Save
          </button>
        </form>
        <Snackbar open={errorSnackbar}  onClose={e => setErrorSnackbar(false)}>
          <Alert onClose={e => setErrorSnackbar(false)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </div>
    );
  } else if (verifiedCode && !validCode) {
    component = (
      <div className="ResetPasswordError">
        <p>Try resetting your password again.</p>
        <p className="error">The URL to reset your password has expired. Click on "Login" and then "Reset Password" to try again.</p>
      </div>
    );
  }

  return component;
}

export default ResetPassword;