import React from "react";
import CoachNav from "../CoachNav/CoachNav";

export default function CoachApp(props) {
  return (
    <div className="AthleteApp">
      <CoachNav></CoachNav>
      {/* You can put some type of footer here */}
    </div>
  );
}
