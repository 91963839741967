import { Input } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import logosmall from "../HomeNav/AULogoSmall.png";
import styles from "./BookingBoxScroller.module.scss";

export default function BookingBoxScrollerForCustomPricingPackage({
  customPricingPackage,
  coach,
}) {
  return (
    <div className="bookBoxScroller">
      <div className="bookBox">
        <h4>Book Now</h4>
        <p>
          <b style={{ fontWeight: 600 }}>{coach.firstName}</b> has created a
          custom package for you!
        </p>
        <div className="left-align">
          <Input
            disableUnderline
            inputProps={{
              className: styles["display-only-input"],
            }}
            readOnly
            type="number"
            value={customPricingPackage.numSessions}
          />
          <span>Sessions</span>
        </div>
        <div className="total">
          <div>Total</div>
          <div>${customPricingPackage.price.toFixed(2)}</div>
        </div>
        <span className="text-thin">
          You are welcome to book anywhere from 1-100 sessions, but most parents
          book 5 sessions upfront to lock-in the dates on their coaches
          calendar. Our satisfaction guarantee still applies!
        </span>
      </div>
      <img alt="" src={logosmall} />
      <h6>Satisfaction guaranteed, or your money back.</h6>
      <div>
        If you do not believe this coach was the right fit after your first
        session, you can receive a full refund. Read our{" "}
        <Link to="/site-terms" className="link">
          refund policy
        </Link>
        . .
      </div>
    </div>
  );
}
