import Button from "@material-ui/core/Button";
import {
  CalendarTodayRounded,
  Check,
  DirectionsRun,
  Favorite,
} from "@material-ui/icons";
import React from "react";
import MetaTags from "react-meta-tags";
import { useHistory } from "react-router-dom";
import { CLOUDFRONT_UPLOADS } from "../../constants/hosts";
import BottomNav from "../BottomNav/BottomNav";
import WhyThreeBoxPanel from "../WhyThreeBoxPanel/WhyThreeBoxPanel";
import ReadyToTrainFormWide from "../ReadyToTrainFormWide/ReadyToTrainFormWide";
import "./ApplyToCoach.scss";

const ApplyToCoach = () => {
  const history = useHistory();

  return (
    <div>
      <MetaTags>
        <title>Grow and Manage Your Private Coaching Business</title>
        <meta
          name="description"
          content="Athletes Untapped | Making it easy for private sports coaches to find clients and manage their training schedule. Apply to coach with us today!"
        />
        <meta
          property="og:title"
          content="Grow and Manage Your Private Coaching Business"
        />
      </MetaTags>
      <div className="applyToCoach">
        <div className="topBox topBoxWithForm">
          <h1>Scale, or start, your private coaching business</h1>
          <div className="formButtonContainer">
            <ReadyToTrainFormWide buttonText="Continue Application" />
          </div>
        </div>
        <div className="threeBoxesWrapper">
          <WhyThreeBoxPanel
            title="Why become an AU coach?"
            page="Partnerships"
            box1={{
              header: "Get Paid",
              caption:
                "Set your rate and earn what you deserve based on your qualifications and experience.",
            }}
            box2={{
              header: "Full Support",
              caption:
                "Our success is paired with yours, so we will help you along every step of the way. ",
            }}
            box3={{
              header: "Dynamic Tech",
              caption:
                "Find clients, stay organized, and use our video feedback platform to scale your business.",
            }}
          />
        </div>
        <div className="basicGrayBox">
          <h2>Built for all private coaches</h2>
          <CalendarTodayRounded className="icon" />
          <h4>Full timers</h4>
          <p>
            We offer established private coaches the necessary resources to
            build or expand their own training business.
          </p>
          <DirectionsRun className="icon" />
          <h4>Side giggers</h4>
          <p>
            AU coaches are able to stay involved with the game they love while
            also earning additional income.
          </p>
          <Favorite className="icon" />
          <h4>Life changers</h4>
          <p>
            AU coaches mentor young athletes by helping them build confidence on
            and off the court.
          </p>
        </div>
        <h4 className="promise">Our promises to you</h4>
        <div className="promiseBox">
          <div>
            <h6>Upfront pricing, no sign up fees</h6>
            Other platforms charge fees and take frontloaded cuts of your
            training sessions. We don't. It's free to sign up and we take a
            consistent 20% cut of each training session.
          </div>
          <div>
            <h6>Family treatment</h6>
            Our coaches are part of the AU family. We will work with you to help
            you maximize your trainig business and shape the next generation of
            athletes.
          </div>
        </div>
        <div className="boxWithImageAndChecks">
          <img
            alt="Former college soccer player and goalie helping a young athlete improve her skills through individual training"
            src={CLOUDFRONT_UPLOADS + "static/covered.jpg"}
          />
          <div className="content">
            <h3>We got you covered</h3>
            <ul className="checkList">
              <li>
                <Check />
                Find Athletes to Train
              </li>
              <li>
                <Check />
                Communicate with Clients
              </li>
              <li>
                <Check />
                Manage Your Schedule
              </li>
              <li>
                <Check />
                Receive Payments
              </li>
              <li>
                <Check />
                Get Insured
              </li>
            </ul>
            <br />
            <Button
              className="buttonSecondary"
              onClick={() => history.push("/our-difference")}
            >
              Learn More
            </Button>
            <br />
          </div>
        </div>
        <div className="getStartedBox">
          <h2>Ready to get started?</h2>
          <div className="buttonContainer">
            <Button
              className="buttonPrimary"
              onClick={() => history.push("/coaches")}
            >
              Find coaches
            </Button>
            <Button
              className="buttonPrimary"
              onClick={() =>
                history.push({
                  pathname: "/apply",
                  state: { forAthlete: false },
                })
              }
            >
              Apply to coach
            </Button>
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default ApplyToCoach;
