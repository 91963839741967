import React, {useEffect, useState} from 'react';
import {getCoachById} from "../../api/api";
import {useParams} from "react-router";
import AthleteMessageCoach from "../AthleteMessageCoach/AthleteMessageCoach";

const MessageCoachPage = props => {
  const {id} = useParams();
  const [coach, setCoach] = useState(null);

  useEffect(() => {
    getCoachById(id)
      .then(response => {
        console.log('coach', response);
        setCoach(response);
      })
      .catch(error => console.log(error));
  }, [id]);

  //TODO: coach not found treatment
  return (
    <div>
      {coach ?
        <AthleteMessageCoach
          coach={coach}
        /> : null}
    </div>
  );
};

export default MessageCoachPage;