import React from 'react';
import './CoachVideosClientView.css';
import CoachVideosSessionPanel from '../CoachVideosSessionPanel/CoachVideosSessionPanel';


const CoachVideosClientView = props => {
  return (
    <div className="CoachVideosClientView">
      <h3>{props.client.firstName + ' ' + props.client.lastName}</h3>
      {/*<div className="CoachVideosClientViewSwitchClient">*/}
      {/*  <Link to="/videos" onClick={e => {*/}
      {/*    props.setShowClientPage(false);*/}
      {/*    props.setClientBeingShown(null);*/}
      {/*  }}><span className="link text-large">Select a different {props.for === "coach" ? "Client" : "Coach"}</span></Link>*/}
      {/*</div>*/}
      {/*<div className="CoachVideosClientViewHeader">*/}
      {/*    <div className="CoachVideosClientViewHeaderInfo">*/}
      {/*        {props.client ? <p>{props.client.firstName + ' ' + props.client.lastName}</p>:null}*/}
      {/*        <img src={blank_profile} alt="None Provided"/>*/}
      {/*    </div>*/}
      {/*    <div className="CoachVideosClientViewHeaderGoals1">*/}
      {/*        <p>Areas of Improvement</p>*/}
      {/*    </div>*/}
      {/*    <div className="CoachVideosClientViewHeaderGoals2">*/}
      {/*        <p>Goals</p>*/}
      {/*    </div>*/}
      {/*</div> */}
      <div className="CoachVideosClientViewVideoFeedbackTitle">
        <h5>Videos</h5>
      </div>
      <div>
        {props.clientSessions.map((session, index) => (
          <CoachVideosSessionPanel
            session={session}
            addVideo={props.addVideo}
            key={index}
            for={props.for}
            client={props.client}
            clientID={props.clientID}
          />
        ))}

      </div>
    </div>
  );
};

export default CoachVideosClientView;