import React, { useEffect, useState } from "react";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import * as qs from "query-string";
import { createEmailSignup, getCoaches } from "../../api/api";
import "./CoachList.scss";
import Colors from "../../constants/colors";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import BottomNav from "../../components/BottomNav/BottomNav";
import CoachListPanel from "../../components/CoachListPanel/CoachListPanel";
import Signup from "../../components/Signup/Signup.js";
import Login from "../../components/Login/Login";
import GeocodingUtils from "../../utils/GeocodingUtils";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { sports } from "src/constants/sports";

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

function validateZip(zip) {
  const re = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return re.test(String(zip).toLowerCase());
}

const spinnerTheme = createTheme({
  palette: {
    primary: {
      main: "#006400",
    },
  },
});

const textFieldColor = Colors.secondary;
const textFieldColorNormal = Colors.secondary;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "max-content",
    marginRight: "20px",
    flexDirection: "row",
    fontFamily: 'Poppins, sans-serif',
    "& .MuiTextField-root": {
      marginRight: "20px",

      "& .MuiAutocomplete-root, & .MuiInputBase-root": {
        backgroundColor: "white",
      },

      "& label.Mui-focused": {
        color: textFieldColor,
      },
      "& label": {},
      "& .MuiInput-underline:after": {
        borderBottomColor: textFieldColor,
      },
      "& .MuiOutlinedInput-root": {
        minHeight: "3.5rem",
        "& .MuiOutlinedInput-input": {
        },
        "& fieldset": {
          borderColor: textFieldColorNormal,
          borderRadius: "5px",
          borderWidth: "1.5px",
        },
        "&.Mui-focused fieldset": {
          borderColor: textFieldColor,
          borderRadius: "5px",
          borderWidth: "1.5px",
        },      },
    },
  },

  formControl: {},
  p: {
    margin: theme.spacing(3),
    paddingTop: theme.spacing(1),
    fontFamily:
      "Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif",
  },
  label: {},
}));

const CoachList = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const history = useHistory();
  const [error, setError] = useState("");
  const [coaches, setCoaches] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page] = useState(query.page ? query.page : 0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [zip, setZip] = useState(
    qs.parse(props.location.search)["zip"]
      ? qs.parse(props.location.search)["zip"]
      : ""
  );
  const [sport, setSport] = useState(
    qs.parse(props.location.search)["sport"]
      ? qs.parse(props.location.search)["sport"]
      : ""
  );
  const [queryParams, setQueryParams] = useState(
    qs.parse(props.location.search)
  );
  const [radius, setRadius] = useState(
    queryParams["radius"] ? queryParams["radius"] : 30
  );
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [searchErrorSnackBar, setSearchErrorSnackBar] = useState(false);
  const [searchErrorSnackBarMessage, setSearchErrorSnackBarMessage] =
    useState("");
  const [emailSignup, setEmailSignup] = useState("");
  const [phoneSignup, setPhoneSignup] = useState("");
  const [firstNameSignup, setFirstNameSignup] = useState("");
  const [messageSignup, setMessageSignup] = useState("");
  const [zipSignup, setZipSignup] = useState(
    qs.parse(props.location.search)["zip"]
      ? qs.parse(props.location.search)["zip"]
      : ""
  );
  const [successMessage, setSuccessMessage] = useState(null);
  const [showHeader, setShowHeader] = useState(true);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const zipParam = searchParams.get("zip");
  const sportParam = searchParams.get("sport");
  const radiusParam = searchParams.get("radius");
  const pageParam = searchParams.get("page");

  const styleClasses = useStyles();

  useState(function () {
    if (!props.location.search["zip"]) {
      GeocodingUtils.GetZip(
        function (res) {
          setZip(res);
        },
        function (err) {
          console.log("err", err);
        }
      );
    }
  });

  const openLoginHandler = () => {
    setShowLogin(true);
  };

  const closeLoginHandler = () => {
    setShowLogin(false);
  };

  const openSignupHandler = () => {
    setShowSignup(true);
  };

  const closeSignupHandler = () => {
    setShowSignup(false);
  };

  const handleEmailForm = (e) => {
    e.preventDefault();

    if (!emailSignup) {
      setError("Please enter an email.");
      setOpenSnackBar(true);
      return;
    }

    if (!firstNameSignup) {
      setError("Please enter first name.");
      setOpenSnackBar(true);
      return;
    }

    if (!messageSignup) {
      setError("Please enter a message.");
      setOpenSnackBar(true);
      return;
    }

    if (!zipSignup) {
      setError("Please enter a zip code.");
      setOpenSnackBar(true);
      return;
    }


    createEmailSignup(
      emailSignup,
      zipSignup,
      firstNameSignup,
      messageSignup,
      phoneSignup
    )
      .then((response) => {
        setSuccessMessage("Thanks for signing up!");
        setEmailSignup("");
      })
      .catch((e) => console.log(e));
  };

  let loginDialog;
  if (showLogin) {
    loginDialog = (
      <Dialog
        onClose={closeLoginHandler}
        open={showLogin}
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogContent dividers>
          <Login
            switch={() => {
              closeLoginHandler();
              openSignupHandler();
            }}
          />
        </DialogContent>
      </Dialog>
    );
  } else {
    loginDialog = null;
  }

  let signupDialog;
  if (showSignup) {
    signupDialog = (
      <Dialog
        onClose={closeSignupHandler}
        open={showSignup}
        fullWidth="true"
        maxWidth="sm"
      >
        <DialogContent dividers>
          <Signup
            switch={() => {
              closeSignupHandler();
              openLoginHandler();
            }}
            close={closeSignupHandler}
          />
        </DialogContent>
      </Dialog>
    );
  } else {
    signupDialog = null;
  }

  useEffect(() => {
    // Required so that the useEffect logic does not try to change state if the
    // component is unmounted. See https://juliangaramendy.dev/use-promise-subscription/
    let isSubscribed = true;
    setIsLoaded(false);
    if (!zip || !radius || !sport) {
      setIsLoaded(true);
      return;
    }
    setShowHeader(false)
    getCoaches(
      zipParam,
      radiusParam,
      sportParam,
      pageParam,
      queryParams["limit"] ? queryParams["limit"] : 1000
    )
      .then((res) => {
        if (isSubscribed) {
          setCoaches(res.coaches);
          setPageCount(res.total_pages);
        }
        setIsLoaded(true);
      })
      .catch(function (error) {
        if (isSubscribed) {
          setError(JSON.stringify(error));

          setIsLoaded(true);
        }
        isSubscribed = false;
      });

    return () => (isSubscribed = false);
    // eslint-disable-next-line
  }, [zipParam, radiusParam, sportParam, pageParam]);

  const searchHandler = (e) => {
    e.preventDefault();
    setShowHeader(false);

    if (!zip || zip === "" || !validateZip(zip)) {
      setSearchErrorSnackBarMessage("Please enter a valid 5-digit zip code.");
      setSearchErrorSnackBar(true);
      return;
    }
    if (!sport || sport === "") {
      setSearchErrorSnackBarMessage("Please select a sport.");
      setSearchErrorSnackBar(true);
      return;
    }

    const query =
      "?zip=" + zip + "&sport=" + sport + "&radius=" + radius + "&page=" + page;
    setQueryParams(qs.parse(query));
    var newurl = window.location.pathname + query;
    console.log(newurl);
    history.push(newurl);
  };

  const showProfileHandler = (e, coach) => {
    e.stopPropagation();
    history.push(
      "/coach-profile/" + (coach.customUrl ? coach.customUrl : coach.id)
    );
  };

  const pageChangeHandler = (event, value) => {
    if (page === value - 1) {
      return;
    }
    const query =
      "?zip=" +
      zip +
      "&sport=" +
      sport +
      "&radius=" +
      radius +
      "&page=" +
      (value - 1);
    setQueryParams(qs.parse(query));
    var newurl = window.location.pathname + query;
    console.log(newurl);
    history.push(newurl);
  };

  let screenToDisplay = (
    <div>
      {showHeader && (
        <div className="InitialHeader">
          <h3>Enter your sport & zip code below to find coaches near you!</h3>
        </div>
      )}
      <div className={"coachListMainWrapper"}>
        <div className="CoachListSearch">
          <form className={styleClasses.root}>
            <Autocomplete
              sx={{ width: 200 }}
              selectOnFocus
              autoHighlight={true}
              blurOnSelect={true}
              options={sports}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => setSport(newValue ? newValue.value : '')}
              value={sports.find(option => option.value === sport) || null}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <span style={{ fontWeight: 100, fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}>
                    {option.label}
                  </span>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={sport}
                  name="sport"
                  type="sport"
                  label="Sport"
                  variant="outlined"
                  className="sport-autocomplete input"
                />
              )}
            />

            <TextField
              name={radius}
              variant="outlined"
              select
              label="Max. Distance"
              value={radius}
              onChange={(e) => setRadius(e.target.value)}
              SelectProps={{ style: { minWidth: "15ch", boxShadow: "none" } }}
              className="input"
            >
              <MenuItem value={5}>5 miles</MenuItem>
              <MenuItem value={10}>10 miles</MenuItem>
              <MenuItem value={20}>20 miles</MenuItem>
              <MenuItem value={30}>30 miles</MenuItem>
              <MenuItem value={50}>50 miles</MenuItem>
            </TextField>
            <TextField
              name={zip}
              variant="outlined"
              label="Zip Code"
              value={zip}
              type="zipCode"
              onChange={(e) => setZip(e.target.value)}
              inputProps={{ style: { boxShadow: "none" } }}
              className="input"
            ></TextField>
            <button
              className="CoachListButtonSearch"
              onClick={(e) => searchHandler(e)}
            >
              Search
            </button>
          </form>
        </div>
        <Snackbar open={openSnackbar} onClose={(e) => setOpenSnackBar(false)}>
          <Alert onClose={(e) => setOpenSnackBar(false)} severity="error">
            {error}
          </Alert>
        </Snackbar>
        <Snackbar
          open={successMessage}
          onClose={(e) => setSuccessMessage(null)}
        >
          <Alert onClose={(e) => setSuccessMessage(null)}>
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={searchErrorSnackBar}
          onClose={(e) => setSearchErrorSnackBar(false)}
        >
          <Alert
            onClose={(e) => setSearchErrorSnackBar(false)}
            severity="error"
          >
            {searchErrorSnackBarMessage}
          </Alert>
        </Snackbar>
        <div className="greenBoxRankingList">
          {coaches.map((item, i) => (
            <CoachListPanel
              key={i}
              coach={item}
              sport={sportParam}
              onClickViewProfile={showProfileHandler}
              onClickMessageCoach={(e, coach) => {
                e.stopPropagation();
                history.push(
                  "/message-coach/" +
                  (item.customUrl ? item.customUrl : item.id)
                );
              }}
              onClickBookSession={(e, coach) => {
                e.stopPropagation();
                history.push(
                  "/book-session/" + (item.customUrl ? item.customUrl : item.id)
                );
              }}
            />
          ))}
          {coaches.length > 0 ? (
            <Pagination
              count={pageCount + 1}
              page={page + 1}
              onChange={pageChangeHandler}
            />
          ) : null}
        </div>
        {coaches && queryParams["zip"] && coaches.length === 0 ? (
          <div className="noResultsContainer">
            <div className="left">
              <h2>No Coaches Found in Your Area</h2>
              <p>
                Don't worry! We're committed to helping you find the perfect coach.
              </p>
              <p>
                Please take a moment to share feedback about your ideal coach, and we will personally undertake a thorough recruiting process to find the most suitable match in your local area. Our platform hosts thousands of coaches and we'll be able to find the perfect fit for you in no time.
              </p>
              <p>
                To help us get started, we'd love to know more about your specific interests and needs:
              </p>
              <ul>
                <li>For what sport are you trying to find a coach?</li>
                <li>What are your goals? (learn how to play, improve skills, prepare for college sports, etc.)</li>
                <li>What's the current skill level and age?</li>
              </ul>
              <p>
                We're excited to connect you with a coach so we can UNTAP your potential!
              </p>
            </div>
            <div className="right">
              <h4>Let's Find Your Ideal Coach!</h4>
              <form onSubmit={(e) => handleEmailForm(e)} className="emailForm">
                <TextField
                  className="emailInput"
                  value={emailSignup}
                  onChange={(e) => setEmailSignup(e.target.value)}
                  name="email"
                  type="email"
                  placeholder="Email*"
                  variant="outlined"
                />
                <TextField
                  value={phoneSignup}
                  onChange={(e) => setPhoneSignup(e.target.value)}
                  name="phone"
                  type="phone"
                  placeholder="Phone Number"
                  variant="outlined"
                />
                <TextField
                  value={firstNameSignup}
                  onChange={(e) => setFirstNameSignup(e.target.value)}
                  name="firstName"
                  type="text"
                  placeholder="Name*"
                  variant="outlined"
                />
                <TextField
                  value={zipSignup}
                  onChange={(e) => setZipSignup(e.target.value)}
                  name="zip"
                  type="text"
                  placeholder="Zip Code*"
                  variant="outlined"
                />
                <TextField
                  multiline
                  rows={4}
                  value={messageSignup}
                  onChange={(e) => setMessageSignup(e.target.value)}
                  name="message"
                  type="text"
                  placeholder="Tell us about your ideal coach and athlete's goals"
                  variant="outlined"
                />
                <Button type="submit" variant="contained" color="primary">
                  Find My Coach
                </Button>
              </form>
            </div>
          </div>
        ) : null}
        {loginDialog}
        {signupDialog}
        <BottomNav />
      </div>
    </div>
  );

  if (!isLoaded) {
    return (
      <div>
        <div
          className="loadingContainer"
          style={{
            marginTop: "5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ThemeProvider theme={spinnerTheme}>
            <CircularProgress />
            <div className="loadingText text-large text-bold">Loading</div>
          </ThemeProvider>
        </div>
        <div className="optimizing">
          <div className="text-thin">
            Optimizing our list of coaches near you
          </div>
        </div>
      </div>
    );
  } else {
    return <div>{screenToDisplay}</div>;
  }
};

export default withRouter(CoachList);
